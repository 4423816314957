import axios from "axios";
import { show_scripts_with_filters } from "./ApiList";

export const sortScript = async (nameSort) => {
  if (nameSort) {
    const { data } = await axios.post(show_scripts_with_filters, {
      user_id: localStorage.getItem("userId"),
      order: "DESC",
    });
    let temp = data && data.data;
    localStorage.setItem("totalScript", JSON.stringify(temp));
  } else {
    const { data } = await axios.post(show_scripts_with_filters, {
      user_id: localStorage.getItem("userId"),
      order: "ASC",
    });
    let temp = data && data.data;
    localStorage.setItem("totalScript", JSON.stringify(temp));
  }
};

export const sortFolderScript = (nameSort, folderAllScripts, folderId) => {
  console.log(folderId, "folderId===");
  if (nameSort) {
    if (folderAllScripts) {
      return (
        folderAllScripts &&
        folderAllScripts.sort((a, b) => a.scrTitle.localeCompare(b.scrTitle))
      );
      // dispatch(shortAllFolderScripts(temp))
      console.log(folderAllScripts, "folderAllScripts920");
    }
  } else {
    if (folderAllScripts) {
      return folderAllScripts && folderAllScripts.reverse();
      console.log(folderAllScripts, "folderAllScripts");
      // dispatch(shortAllFolderScripts(folderAllScripts && folderAllScripts.reverse()))
    }
  }
};
