import axios from "axios";
import { useState, useEffect } from "react";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { get_control_values } from "../utils/ApiList";

const useFetch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchAgain = async () => {
    setLoading(true);
    if (isMobile || isIOS || isAndroid) {
      if (isIOS) {
        window.location.href = "/app_badges";
      } else {
        window.location.href = "/app_badges";
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchAgain();
  }, []);

  return { data, loading, error, fetchAgain };
};

export default useFetch;
