import React, { useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as $ from "jquery";

import AlertModal from "../../../middlaware/AlertModal";
import axios from "axios";

import { showStoryBoardController } from "../../../utils/DashboardController";
import { useDispatch, useSelector } from "react-redux";
import { showAllStoryBoards } from "../../../services/actions";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/ToastAlert";
import {
  add_story_board,
  set_story_board_orders,
} from "../../../utils/ApiList";

export default function DragNew({ stroyBoardArr, setLoader }) {
  const dispatch = useDispatch();
  const storyBoards = useSelector((state) => state.showSingleScriptStoryboard);
  let tempArr = storyBoards && storyBoards.showSingleScriptStoryboard;

  // console.log(tempArr, "tempArr");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [dragId, setDragId] = useState("");

  const convertNormalText = (item) => {
    setDescription(item && item.storyboardText);
    setDragId(item && item.draggableId);
    setTitle(item && item.storyboardName);
  };

  //update story baord
  const update = async (item) => {
    // const id = `story__content_board${editStoryData.id}`;
    // const attrData = document.getElementById(id).innerHTML;
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/edit_story_board`,
      {
        user_id: item.user_id,
        script_id: item.script_id,
        story_board_id: item.id,
        storyboardName: title,
        storyboardText: description,
        storyboardAttrText: description,
        draggableId: item && item.draggableId,
      }
    );

    dispatch(showAllStoryBoards(item.script_id));
  };

  const deleteStoryBoardController = async (data) => {
    const script_id = data && data.script_id;
    setLoader(true);
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/delete_story_board`,
      {
        user_id: localStorage.getItem("userId"),
        script_id: data.script_id,
        story_board_id: data.id,
      }
    );

    const msg = res.data.message;
    if (msg === "Story Board Deleted Successfully") {
      dispatch(showAllStoryBoards(script_id));
      setLoader(false);

      toast.success("Story Board has been deleted successfully", toastOptions);
    }
    setLoader(false);
  };

  const saveDragBoards = async (data) => {
    let x = [];
    let script_id;
    data &&
      data.forEach((i) => {
        x.push(i.id);
        script_id = i.script_id;
      });

    const res = await axios.post(set_story_board_orders, {
      user_id: localStorage.getItem("userId"),
      script_id: script_id,
      order: x.toString(),
    });
  };

  return (
    <div className="App">
      <DragDropContext
        onDragEnd={(param) => {
          const srcI = param && param.source && param.source.index;
          const desI = param && param.destination && param.destination.index;
          let temp = desI > 1 ? desI - 1 : desI;
          let temp1 = desI > 1 ? desI + 1 : desI;
          srcI % 2 === 0
            ? desI % 2 === 0
              ? tempArr &&
                tempArr.splice(desI, 0, tempArr && tempArr.splice(srcI, 1)[0])
              : desI > 1
              ? tempArr &&
                tempArr.splice(temp, 0, tempArr && tempArr.splice(srcI, 1)[0])
              : tempArr &&
                tempArr.splice(desI, 0, tempArr && tempArr.splice(srcI, 1)[0])
            : desI % 2 === 0
            ? tempArr &&
              tempArr.splice(temp1, 0, tempArr && tempArr.splice(srcI, 1)[0])
            : tempArr &&
              tempArr.splice(desI, 0, tempArr && tempArr.splice(srcI, 1)[0]);

          saveDragBoards(tempArr && tempArr);
        }}
      >
        <Droppable droppableId="droppable-1">
          {(provided, snapshot) => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <div className="row story__boards">
                  {tempArr &&
                    tempArr.map((item, index) => {
                      return (
                        <div className="col-lg-6 " key={index}>
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    userSelect: "none",

                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="story-borad mt-3">
                                    <div className="gray-story-board">
                                      <div className="d-flex">
                                        <h6
                                          className="float-left"
                                          style={{ width: "93%" }}
                                          // contentEditable={true}
                                          // suppressContentEditableWarning={true}
                                          onClick={() =>
                                            convertNormalText(item)
                                          }
                                        >
                                          <input
                                            onBlur={() => update(item)}
                                            type="text"
                                            defaultValue={
                                              item && item.storyboardName
                                            }
                                            onChange={(e) =>
                                              setTitle(e.target.value)
                                            }
                                            style={{
                                              border: "none",
                                              backgroundColor: "#e5e5e5",
                                            }}
                                          />
                                        </h6>

                                        <div className="dropdown mr-3">
                                          <img
                                            src="assets/images/dots.svg"
                                            className="img-fluid"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            alt="option"
                                          />

                                          <div
                                            className="dropdown-menu story-board-drop "
                                            aria-labelledby="dropdownMenuButton"
                                          >
                                            <AlertModal
                                              deleteScr={() => {
                                                deleteStoryBoardController(
                                                  item
                                                );
                                              }}
                                            />

                                            <div
                                              className="dropdown-item"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  item && item.storyboardText
                                                );
                                              }}
                                            >
                                              Copy
                                            </div>

                                            {/*  */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <textarea
                                      onClick={() =>
                                        convertNormalText(item && item)
                                      }
                                      onBlur={() => update(item)}
                                      className="story-board-description"
                                      placeholder="Type here..."
                                      defaultValue={item && item.storyboardText}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        </div>
                      );
                    })}
                  {provided.placeholder}
                </div>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
