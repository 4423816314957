import React from "react";

import MicIcon from "../../images/mic.png";
import "./Record.css";

function StartAudioRecordings(props) {
  const audioRecord = async () => {
    const startAudioRecording = document.getElementById("startAudioRecording");

    if (startAudioRecording === null) {
    } else {
      await startAudioRecording.click();
      props.setStartRecordIcon(true);
    }
    if (props.scrInvert === true) {
      props.startBottomToTopScroll();
    } else {
      props.startScroll();
    }
    props.setStopAudioRecording(true);

    const test = localStorage.getItem("testDemo");
    // console.log(test);

    // if (test === "startAudio") {
    //   props.setStartRecordIcon(true);
    // } else {
    //   props.setStartRecordIcon(false);
    // }
  };

  return (
    <>
      <div className="record-icon" id="hideToggleAudio">
        <img
          src={MicIcon}
          onClick={() => audioRecord()}
          disabled={props.isRecording}
          id="start__recording"
          alt="start video recording"
        />
      </div>
    </>
  );
}

export default StartAudioRecordings;
