import React from "react";

const DefaultStripeForm = () => {
  const [price, setPrice] = React.useState(0);
  React.useEffect(() => {
    let subscriptionData = localStorage.getItem("subscriptionData");
    setPrice(JSON.parse(subscriptionData).price);
  }, []);

  return (
    <div className="default-stripe-form">
      <form>
        <div className="form-row">
          <div className="form-group col-lg-4 col-md-12 col-12">
            <label for="inputAddress">Card Number</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="1234 1234 1234 1234"
            />
          </div>
          <div className="form-group col-lg-4 col-md-6 col-6">
            <label for="inputAddress">Expiration</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="MM/YY"
            />
          </div>
          <div className="form-group col-lg-4 col-md-6 col-6">
            <label for="inputAddress">CVC</label>
            <input
              disabled
              type="text"
              className="form-control"
              id="inputAddress"
              placeholder="CVC"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-12">
            <label for="inputState">Country</label>
            <select id="inputState" className="form-control" disabled>
              <option selected>Choose...</option>
              <option>...</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <button
                disabled
                className={`btn btn-primary btn-lg btn-block custom-btn border-0 btn-login create-account-checkout default-pay-btn`}
              >
                ${price} PAY NOW
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefaultStripeForm;
