import React from "react";

function LookUpTabs() {
  return (
    <div>
      <ul className="nav nav-tabs border-0" role="tablist">
        <li className="nav-item custom-nav add-sript-mod">
          <a
            className="nav-link ad-script active border-0"
            data-toggle="tab"
            href="#Dictionary"
          >
            Dictionary
          </a>
        </li>
        <li className="nav-item custom-nav add-sript-mod">
          <a
            className="nav-link ad-script border-0"
            data-toggle="tab"
            href="#Thesaurus"
          >
            Thesaurus
          </a>
        </li>
      </ul>
    </div>
  );
}

export default LookUpTabs;
