import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.js";

import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

import "./StripePayment.css";
import { pay_now } from "../../utils/ApiList.js";
import { ToastContainer, toast } from "react-toastify";
import PaymentRequestForm from "./PaymentRequestForm.js";
import { getOSController } from "../../utils/DetectOS/DetectOs.js";
import { refreshPageController } from "../../utils/RefreshPage.js";
import NewCheckoutForm from "./NewCheckoutForm";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function StripePayment() {
  let stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  const [clientSecret, setClientSecret] = useState("");
  const [loader, setLoader] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState(null);

  let user_id;
  let price;
  let currency;
  let plan_id;
  let native_application = false;
  let redirect_status;
  let product_id;
  user_id = new URLSearchParams(window.location.search).get("user_id");
  price = new URLSearchParams(window.location.search).get("price");
  currency = new URLSearchParams(window.location.search).get("currency");
  plan_id = new URLSearchParams(window.location.search).get("price_id");
  native_application = new URLSearchParams(window.location.search).get(
    "native_application"
  );
  redirect_status = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );
  product_id = new URLSearchParams(window.location.search).get("product_id");
  const getStripePromise = async () => {
    user_id = new URLSearchParams(window.location.search).get("user_id");
    price = new URLSearchParams(window.location.search).get("price");
    currency = new URLSearchParams(window.location.search).get("currency");
    plan_id = new URLSearchParams(window.location.search).get("price_id"); // plan_id is price id

    native_application = new URLSearchParams(window.location.search).get(
      "native_application"
    );
    redirect_status = new URLSearchParams(window.location.search).get(
      "redirect_status"
    );
    stripePromise = await loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
  };
  let sub = null;
  useEffect(async () => {
    setLoader(true);

    getStripePromise();
    // Create PaymentIntent as soon as the page loads
    const getPaymentIntent = async () => {
      let subscriptionData = localStorage.getItem("subscriptionData");

      let res;

      if (native_application === "true") {
        sub = {
          user_id: user_id && user_id,
          price: price && parseFloat(price),
          currency: currency && currency,
          plan_id: plan_id && plan_id,
          product_id: product_id && product_id,
          native_application: true,
        };

        localStorage.setItem("subscriptionData", JSON.stringify(sub && sub));

        res = await axios.post(pay_now, {
          user_id: user_id && user_id,
          amount: price && parseFloat(price * 100),
          currency: currency && currency,
          plan_id: plan_id && plan_id,
        });
      } else {
        sub = JSON.parse(subscriptionData);

        if (sub && sub.native_application === true) {
        } else {
          // alert("test");
          sub.user_id = localStorage.getItem("userId");
          sub.plan_id = sub.price_id;
        }

        if (sub) {
          res = await axios.post(pay_now, {
            user_id:
              sub.native_application === true
                ? sub.user_id
                : localStorage.getItem("userId"),
            amount: parseFloat(sub.price * 100),
            currency: sub.currency,
            plan_id:
              sub.native_application === true ? sub.plan_id : sub.price_id, // plan_id is a plan price id
          });
        }
      }

      if (res) {
        if (res.data.code === 200) {
          const paymentIntent =
            res.data &&
            res.data.data &&
            res.data.data.paymentIntent &&
            res.data.data.paymentIntent;

          setClientSecret(paymentIntent);

          setSubscriptionData(sub);
        } else {
          toast.error(res.data.message);
          refreshPageController();
        }
      }

      setLoader(false);

      // .then((data) => );
    };
    getPaymentIntent();
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <LoadingOverlay active={loader} spinner>
      <div className="checkout-page">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm subscriptionData={subscriptionData} />
            {/* <NewCheckoutForm
              options={options}
              subscriptionData={subscriptionData}
            /> */}
            {/* <PaymentRequestForm subscriptionData={subscriptionData} /> */}
          </Elements>
        )}

        <div className="row pb-5 mt-3">
          <div className="col-12 text-center">
            <div>
              <span>
                <VerifiedUserIcon className="shield_icon" />
              </span>
              <span>
                Secure Payments by{" "}
                <span className="text-shield-stripe">Stripe</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className="alert-msg" autoClose={3000} />
    </LoadingOverlay>
  );
}
