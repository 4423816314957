import { RecordRTCPromisesHandler } from "recordrtc";

export const startVideoRecordingController = async (
  setStream,
  setRecorder,
  setVideoStop,
  setThumbnailChecked,
  setStartRecordIcon,
  startVideo
) => {
  (await startVideo) && startVideo();

  var constraints = {
    audio: true,

    video: {
      minWidth: 1920,
      minHeight: 1080,
      video: { width: { exact: 1920 }, height: { exact: 1080 } },
      aspectRatio: {
        exact: 1920 / 1080,
      },
    },
  };

  navigator.mediaDevices.getUserMedia(constraints).then(function (mediaStream) {
    const track = mediaStream.getVideoTracks()[0];
    const constraints = track.getConstraints();
    console.log("Result constraints: " + JSON.stringify(constraints));

    setStream(mediaStream);

    const recorder = new RecordRTCPromisesHandler(mediaStream, {
      type: "video",
      mimeType: "video/webm;codecs=vp8", // default windows player supported

      minWidth: 1920,
      minHeight: 1080,
      video: { width: { exact: 1920 }, height: { exact: 1080 } },
      aspectRatio: {
        exact: 1920 / 1080,
      },
    });

    setRecorder(recorder);
    recorder.startRecording();

    setVideoStop(true);

    // setThumbnailChecked(false);
    setStartRecordIcon(true);
  });
};
