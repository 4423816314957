import React, { useEffect, useRef, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { Dragprom } from "./Dragprom";
import { Modelfootertabs } from "./Modelfootertabs";
import { IOSSlider } from "../../utils/slider";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import ModalSeekBar from "./ModalSeekBar";
import { useTimer } from "../../hooks/useTimer";
import $ from "jquery";
import { saveAs } from "file-saver";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  startAudioRecording,
  startVideoRecording,
  stopAudioRecording,
  stopVideoRecording,
} from "../../helpers/Recording";
import { toast } from "react-toastify";
import { toastOptions } from "./../../utils/ToastAlert";

import VideoRecorder from "react-video-recorder";

import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS,
} from "react-record-webcam";
import { increaseDecreaseScrollSpeed } from "../../utils/customChildWIndow/customChildWIndowScroll";
import axios from "axios";
import { get_control_values } from "../../utils/ApiList";
const OPTIONS = {
  filename: "test-filename",
  fileType: "mp4",
  width: 1920,
  height: 1080,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      className="darjytghyfg"
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableModal({ handleClose, open }) {
  const { scriptData } = useSelector((state) => state.selectedScriptData);
  const globalState = useSelector((state) => state);

  let scrTitle = localStorage.getItem("title");
  const { renderedStreamDuration, startHandler, stopHandler, counter } =
    useTimer();

  let subStatus =
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus.payment_status_code;

  let ForeverFreeAction =
    subStatus && subStatus == "2"
      ? true
      : subStatus && subStatus === "0"
      ? true
      : false;

  const ref = useRef();

  const recordWebcam = useRecordWebcam(OPTIONS);
  const [freeTrailAction, setFreeTrailAction] = useState(false);
  const [controls, setControls] = useState(null);

  const [fullWindow, setFullWindow] = useState(false);
  const [expanded, setExpanded] = React.useState(true);
  const [seekPageEnable, setSeekPageEnable] = React.useState(false);
  const [boolean, setBoolean] = useState(false);

  const [screenEnable, setScreenEnable] = useState(false);

  const [getActiveTabs, setGetActiveTabs] = useState(0);
  const [temp, setTemp] = React.useState(0);
  const [seekAction, setSeekAction] = useState(false);
  const [seekSpeed, setSeekSpeed] = useState(0.81);

  const [transparencyValue, setTransparencyValue] = React.useState(null);
  const [transparencyValues, setTransparencyValues] = useState(null);

  const [fontSize, setFontSize] = useState("25");
  const [padding, setPadding] = useState(10);

  const [textAlign, setTextAlign] = useState("left");
  // Mirror and Invert
  const [mirrorToggle, setMirrorToggle] = useState(false);
  const [invertToggle, setInvertToggle] = useState(false);
  const [xAxis, setXAxis] = useState(1);
  const [yAxis, setYAxis] = useState(1);

  // Speech recognition States

  const [speechToggle, setSpeechToggle] = useState(false);

  // AUDIO Recording
  const [isRecording, setIsRecording] = useState(false);
  const [audioToggle, setAudioToggle] = useState(false);

  const [recordState, setRecordState] = useState(null);

  // VIDEO Recording
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [videoRecordToggle, setVideoRecordToggle] = useState(false);
  const [fullVideoToggle, setFullVideoToggle] = useState(false);
  const [videoToggle, setVideoToggle] = useState(false);

  const [childVideoPosition, setChildVideoPosition] = useState(0); // 0=> top:0, left:0, 1=> top:0, right:0, 2=> bottom:0, left:0, 3=> bottom:0, right:0

  let count = 0;
  let findOpSet = null;

  const handleCloseModal = () => {
    setSeekAction(false);
    localStorage.removeItem("scrollTop");
    handleClose();
  };

  //! *************** */ FORMATTING FUNCTIONS START****************

  const handleTransparencyChange = (e) => {
    let arr = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

    let reverseArr = [1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1, 0];

    let num = e.target.value;

    if (arr.includes(num)) {
      const isLargeNumber = (element) => element > num;

      const result = arr.findIndex(isLargeNumber);

      setTransparencyValue(num);
      setTransparencyValues(reverseArr[result > 0 ? result - 1 : 9]);
    }
  };

  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };
  const handlePaddingChange = (e) => {
    setPadding(e.target.value);
  };
  const handleMirrorChange = (e) => {
    if (invertToggle) {
      setInvertToggle(false);
    }
    setMirrorToggle(e.target.checked);
    if (e.target.checked) {
      setXAxis(-1);
      setYAxis(1);
    } else {
      setXAxis(1);
      setYAxis(1);
    }
    // setPadding(e.target.value);
  };
  const handleInvertChange = (e) => {
    if (mirrorToggle) {
      setMirrorToggle(false);
    }
    setInvertToggle(e.target.checked);

    if (e.target.checked) {
      setXAxis(-1);
      setYAxis(-1);
    } else {
      setXAxis(1);
      setYAxis(1);
    }
  };

  //! ***************** MANUAL SCROLLING FUNCTIONS*****************

  let interval = null;

  const handleSeekSpeed = (e) => {
    setSeekSpeed(e.target.value);
  };

  const scrollTopToBottom = () => {
    setSeekPageEnable(true);

    let Boolean = localStorage.getItem("scrollTop");

    if (Boolean === "false") {
      localStorage.setItem("scrollTop", true);
      setSeekAction(true);

      return false;
    }
    startHandler();
    localStorage.setItem("scrollTop", true);
    setSeekAction(true);
    count = 0;
    interval = setInterval(() => {
      window.requestAnimationFrame(handleScroll);
    }, ((1 / 4) * 180) | 0);
  };

  const handleScroll = () => {
    let Boolean = localStorage.getItem("scrollTop");
    if (Boolean === "false") {
      return false;
    }
    const speed = localStorage.getItem("scrollSpeed");
    // range 0.81 to 1.8

    var MIN_PIXELS_PER_STEP = parseFloat(speed);

    var target = document.getElementById("scroll-div");
    if (!target) {
      handleStopScroll();
      count = 0;

      return;
    }

    var offsets = document
      .getElementById("default-div")
      .getBoundingClientRect();
    var top = offsets.top;

    const targetHeight = Math.abs(
      target && target.offsetHeight < 700
        ? target && target.offsetHeight - 500
        : target && target.offsetHeight * 1.65
    );

    console.log(targetHeight, "dfghd", count);

    count = count + 1;
    if (parseInt(targetHeight) === count) {
      console.log("stop scroll");
      handleStopScroll();
      localStorage.removeItem("scrollTop");

      count = 0;

      return false;
    }

    var scrollContainer = target;
    do {
      scrollContainer = scrollContainer.parentNode;

      if (!scrollContainer) return;
      scrollContainer.scrollTop += MIN_PIXELS_PER_STEP;
    } while (scrollContainer.scrollTop == 0);
    // }
  };

  //* common stop functions call => speech recognition/ video recording / audio recording / manual scrolling
  const handleStopScroll = async () => {
    stopHandler();
    clearInterval(interval);
    setSeekAction(false);
    localStorage.setItem("scrollTop", false);
    setSpeechToggle(speechToggle);
    stopHandler();

    // stop audio recording

    if (audioToggle) {
      await handleStopAudioRecording();
    }

    if (videoRecordToggle) {
      await handleStopRecording();
    }

    // Stop speech recognition
    SpeechRecognition.stopListening();
    setSeekPageEnable(false);
  };

  //! ***************VOICE RECOGNITION FUNCTIONS START ****************

  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const startSpeechRec = () => {
    SpeechRecognition.startListening({ continuous: true });
    startHandler();
    setSeekAction(true);
    // setSeekPageEnable(true);
  };

  // get speech to text
  const appendText = async (transcript) => {
    if (
      transcript !== undefined &&
      transcript.toLowerCase().trim().length > 0
      // transcript !== searchText
    ) {
      // setSearchText(transcript);

      if (window.find(transcript) === false) {
      }
      const testText = await transcript.trim().split(" ").splice(-3);

      const normalText = testText.toString();
      console.log(normalText);
      const plainNormalText = normalText.split(",").join(" ");
      const plainText = plainNormalText.split("'s").join("");

      getOffsetToScroll(plainText);
    }
  };

  // replace the match words into the random Id's
  const getOffsetToScroll = (matchText) => {
    const inputText = matchText.toLowerCase();

    const data = Date.now();
    var randomId = `imhere${data}`;

    $("#scroll-div").html(
      $("#scroll-div")
        .html()
        .replace(inputText, `<a id=${randomId}></a>` + inputText)
    );
    scriptScrollToTop(randomId, inputText);
  };

  //when script is matched then script scroll
  const scriptScrollToTop = (randomId, matchText) => {
    var offset = document.getElementById(randomId);
    findOpSet = null;
    var offsetTop = 0;
    if (offset === null) {
      return false;
    } else {
      offsetTop = offset.offsetTop; //getting offsetTop of the current random  id
      if (temp > offsetTop) {
        findOpSet = temp;
        let elem = document.getElementById(randomId);
        elem.scrollIntoView();
      } else {
        offsetTop = offset.offsetTop;

        findOpSet = offsetTop;
        setTemp(findOpSet);
        let elem = document.getElementById(randomId);
        elem.scrollIntoView();
      }
    }
  };

  React.useEffect(() => {
    appendText(transcript.toString());
  }, [transcript.toString()]);

  //! ***************VOICE RECOGNITION FUNCTIONS END ****************

  //*************** AUDIO  FUNCTIONS START ****************

  const handleAudioToggle = (e) => {
    if (videoRecordToggle || fullVideoToggle || videoToggle) {
      setFullVideoToggle(false);
      setVideoRecordToggle(false);
      setVideoToggle(false);
    }
    setAudioToggle(e.target.checked);
  };

  const handleStartAudioRecording = async () => {
    setRecordState(RecordState.START);
    startHandler();

    return;
  };

  const handleStopAudioRecording = async () => {
    // await RecordState.STOP;
    setRecordState(RecordState.STOP);

    return false;
  };

  //audioData contains blob and blobUrl
  const onStop = (audioData) => {
    if (audioData.blob) {
      let date = new Date();
      let sd = date.toISOString().substring(0, 10);
      let hh = date.getHours();
      let mm = date.getMinutes();
      let s = date.getSeconds();

      let fullDateTime = sd + " " + hh + ":" + mm + ":" + s;

      saveAs(
        audioData.blob,
        `Audio - ${scriptData && scriptData.scrTitle}-${fullDateTime}.wav`
      );
      toast.success("Audio recording saved successfully!", toastOptions);
    }
  };

  //? *************** VIDEO FUNCTIONS START ****************

  const handleTurnOnCamera = () => {
    const { turnOnCamera } = ref.current;
    turnOnCamera();
    setIsCameraOn(true);
  };
  const handleTurnOffCamera = () => {
    const { turnOffCamera } = ref.current;
    turnOffCamera();
    setIsCameraOn(false);
  };

  const handleVideoRecordToggle = (e) => {
    if (audioToggle) {
      setAudioToggle(false);
    }
    setVideoRecordToggle(e.target.checked);
  };
  const handleFullVideoToggle = (e) => {
    if (videoToggle) {
      setVideoToggle(false);
    }
    if (!isCameraOn) {
      handleTurnOnCamera();
    }
    setFullVideoToggle(e.target.checked);
    setScreenEnable(false);
  };
  const handleVideoToggle = (e) => {
    if (fullVideoToggle) {
      setFullVideoToggle(false);
    }
    if (!isCameraOn) {
      handleTurnOnCamera();
    }
    setVideoToggle(e.target.checked);
    setScreenEnable(e.target.checked);
  };

  const handleStartVideoRecording = () => {
    startVideoRecording();
    startHandler();
    // setSeekPageEnable(true);
  };

  const handleStopRecording = async () => {
    const { blob } = await stopVideoRecording();

    if (blob) {
      let date = new Date();
      let sd = date.toISOString().substring(0, 10);
      let hh = date.getHours();
      let mm = date.getMinutes();
      let s = date.getSeconds();

      let fullDateTime = sd + " " + hh + ":" + mm + ":" + s;

      saveAs(
        blob,
        `Video - ${scriptData && scriptData.scrTitle}-${fullDateTime}.webm`
      );
      toast.success("Video recording saved successfully!", toastOptions);
    }
  };

  const handleChangePosition = () => {
    if (childVideoPosition === 3) {
      setChildVideoPosition(0);
      return;
    }
    setChildVideoPosition((pre) => pre + 1);
  };

  useEffect(() => {
    if (!videoToggle && !fullVideoToggle && isCameraOn) {
      handleTurnOffCamera();
    }
  }, [fullVideoToggle, videoToggle]);

  useEffect(() => {
    localStorage.setItem("scrollSpeed", seekSpeed);
  }, [seekSpeed]);
  useEffect(() => {
    localStorage.setItem("scrollTop", boolean);
  }, [boolean]);

  // SHORT CUT KEY BOARD INCREASE/DECREASE SCROLLING SPEED
  useEffect(() => {
    if (open) {
      window.addEventListener(
        "keydown",
        (event) => {
          if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
          }

          switch (event.key) {
            case "Down": // IE/Edge specific value
            case "ArrowDown":
              let num = localStorage.getItem("scrollSpeed");
              // Do something for "down arrow" key press.

              parseFloat(num) > 0.811 &&
                increaseDecreaseScrollSpeed("Down", setSeekSpeed);

              break;
            case "Up": // IE/Edge specific value
            case "ArrowUp":
              // Do something for "up arrow" key press.
              let number = localStorage.getItem("scrollSpeed");
              console.log(parseFloat(number), "Numversds");
              parseFloat(number) < 1.811 &&
                increaseDecreaseScrollSpeed("Up", setSeekSpeed);
              break;

            default:
              return; // Quit when this doesn't handle the key event.
          }

          // Cancel the default action to avoid it being handled twice
          event.preventDefault();
        },
        true
      );
    }
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener(
        "keydown",
        (event) => {
          if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
          }

          if (event.code === "Space" || event.key === "Space") {
            setBoolean(!boolean);

            return;
          }

          // Cancel the default action to avoid it being handled twice
          event.preventDefault();
        },
        true
      );
    }
  }, [boolean]);

  useEffect(() => {
    if (open) {
      window.onkeydown = function (e) {
        return !(e.keyCode == 32 && e.target == document.body);
      };
    }
  }, []);

  useEffect(() => {
    const getControls = async () => {
      const { data } = await axios.get(get_control_values);
      console.log(data, "daaaaaa");
      setControls(data.data);
    };
    getControls();
  }, []);

  let limitNumber = controls
    ? controls && controls.audVidRecognitionUnit == "sec"
      ? parseInt(controls.audVidRecognitionValue)
      : parseInt(controls.audVidRecognitionValue) * 60
    : 0;

  useEffect(() => {
    console.log(counter, "counter");

    if (limitNumber !== 0 && counter === limitNumber && ForeverFreeAction) {
      handlePremium();
    }
  }, [counter]);

  const handlePremium = () => {
    toast.warn(
      `You reached the limit of your ${controls.audVidRecognitionValue} ${
        controls.audVidRecognitionUnit == "sec" ? `Seconds` : `Minutes`
      } free plan! Please click on your Account to upgrade to Unlimited.`
    );
    handleStopScroll();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullWindow}
        open={open}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        fullWidth={true}
        maxWidth="lg"
        PaperProps={{
          style: {
            background: `rgba(0, 0, 0,${transparencyValues || 1})`,
            boxShadow: "none",
            color: "#fff",
            minHeight: "600px",
            overflowY: "hidden",
          },
        }}
      >
        <div
          className={`video-player ${screenEnable && "mini-screen"} ${
            childVideoPosition === 0
              ? "topLeft"
              : childVideoPosition === 1
              ? "topRight"
              : childVideoPosition === 2
              ? `bottomLeft ${expanded && "footerOn"} ${
                  seekPageEnable && "footerOff"
                } ${getActiveTabs === 1 && "footerSizeChange"}`
              : `bottomRight ${expanded && "footerOn"} ${
                  seekPageEnable && "footerOff"
                } ${getActiveTabs === 4 && "footerSizeChange"}`
          }`}
          style={{
            opacity: `${
              (transparencyValues && transparencyValues) === 1
                ? 0.8
                : transparencyValues || 0.7
            }`,
            display: !videoToggle && !fullVideoToggle && "none",
          }}
          onClick={() => handleChangePosition()}
        >
          <VideoRecorder
            ref={ref}
            onRecordingComplete={(videoBlob) => {
              // Do something with the video...
              // onRecordingComplete(videoBlob);
            }}
          />
        </div>
        <DialogTitle className="head-mod">
          <div className="header_custom">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-4">
                  <div className="row">
                    <div className="col-1 pl-0">
                      {!fullWindow && (
                        <DragIndicatorIcon
                          style={{ cursor: "move" }}
                          id="draggable-dialog-title"
                        />
                      )}
                    </div>
                    <div className="col-4">
                      <p className="mb-0 pt-1 trans-text">Transparency</p>
                    </div>
                    <div className="col-7">
                      <IOSSlider
                        className=""
                        aria-label="ios slider"
                        // id="scriptZomm"
                        valueLabelDisplay="off"
                        min={0}
                        value={0 || transparencyValue}
                        max={1}
                        onChange={handleTransparencyChange}
                        step={0.01}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-4 text-center">
                  <h5 className="mb-0 pt-1">{scrTitle && scrTitle}</h5>
                </div>
                <div className="col-lg-4 col-4">
                  <div className="model-header-icons">
                    {/* <img
                      src="assets/images/minimize-icon.svg"
                      alt=""
                      id="fullscreen-btn"
                    /> */}
                    {fullWindow ? (
                      <FullscreenExitIcon
                        onClick={() => setFullWindow(!fullWindow)}
                      />
                    ) : (
                      <FullscreenIcon
                        onClick={() => setFullWindow(!fullWindow)}
                      />
                    )}
                    {/* <img src="assets/images/newwindow.svg" alt="" /> */}
                    {!fullWindow && <CloseIcon onClick={handleCloseModal} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <div className="d-none">
          <AudioReactRecorder
            state={recordState}
            onStop={(data) => onStop(data)}
          />
        </div>
        <DialogContent
          style={{
            fontSize: fontSize + "px",
            padding: `0 ${padding}px`,
            textAlign: textAlign,
            transform: `scale(${xAxis},${yAxis})`,
          }}
        >
          <div className="darg-div">
            <Dragprom />
          </div>
        </DialogContent>
        <DialogActions className="p-0">
          <div className={`footer_custom ${seekAction && "seek-enable"}`}>
            {seekAction ? (
              <ModalSeekBar
                handleStopScroll={handleStopScroll}
                renderedStreamDuration={renderedStreamDuration}
                handleSeekSpeed={handleSeekSpeed}
                seekSpeed={seekSpeed}
              />
            ) : (
              <Modelfootertabs
                handleSeekSpeed={handleSeekSpeed}
                seekSpeed={seekSpeed}
                expanded={expanded}
                setExpanded={setExpanded}
                setGetActiveTabs={setGetActiveTabs}
                getActiveTabs={getActiveTabs}
                handleFontSizeChange={handleFontSizeChange}
                fontSize={fontSize}
                handlePaddingChange={handlePaddingChange}
                padding={padding}
                handleTransparencyChange={handleTransparencyChange}
                transparencyValue={transparencyValue}
                setTextAlign={setTextAlign}
                handleInvertChange={handleInvertChange}
                handleMirrorChange={handleMirrorChange}
                scrollTopToBottom={scrollTopToBottom}
                speechToggle={speechToggle}
                setSpeechToggle={setSpeechToggle}
                startSpeechRec={startSpeechRec}
                // mirror and invert
                mirrorToggle={mirrorToggle}
                invertToggle={invertToggle}
                // audio
                handleAudioToggle={handleAudioToggle}
                audioToggle={audioToggle}
                handleStartAudioRecording={handleStartAudioRecording}
                // video
                handleVideoToggle={handleVideoToggle}
                videoToggle={videoToggle}
                handleVideoRecordToggle={handleVideoRecordToggle}
                videoRecordToggle={videoRecordToggle}
                handleFullVideoToggle={handleFullVideoToggle}
                fullVideoToggle={fullVideoToggle}
                handleStartVideoRecording={handleStartVideoRecording}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
