import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { subscriptionStatus } from "../../services/actions";

function AddNewScriptButton({ setBoardAction }) {
  let tempScript = localStorage.getItem("totalScript");
  var allScript = JSON.parse(tempScript);
  const globalState = useSelector((state) => state);
  const { settingData, userMode } = useSelector((state) => state.setting);

  const dispatch = useDispatch();

  React.useEffect(async () => {
    dispatch(subscriptionStatus());
  }, [dispatch]);

  let subStatus =
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus.payment_status_code;

  let ForeverFreeAction =
    subStatus && subStatus == "2"
      ? true
      : subStatus && subStatus === "0"
      ? true
      : false;

  const handlePremium = () => {
    if (
      userMode &&
      allScript &&
      allScript.length >= parseInt(settingData.script_count)
    ) {
      toast.warn(
        "You reached the limit of your " +
          settingData.script_count +
          " Scripts free plan! Please click on your Account to upgrade to Unlimited."
      );
      window.open(
        "/subscription",
        "_blank",
        `location=yes,height=600,width=800,scrollbars=yes,status=yes, top=100, left=250,  margin=0 auto,  frame=false,nodeIntegration=no`
      );
      return false;
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div
            data-toggle="modal"
            data-target={
              userMode &&
              allScript &&
              allScript.length >= parseInt(settingData.script_count)
                ? null
                : "#exampleModalCenter1"
            }
            onClick={() => handlePremium()}
          >
            <span className="">Add Scripts</span>{" "}
            <img
              style={{ width: "23px" }}
              src="assets/images/add.svg"
              alt="folder"
              // className="ml-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewScriptButton;
