export const toastOptions = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};

export let normalText = `<span>Hello and thank you for trying Scriptively!


Scriptively was designed by speakers who understand your struggle. It streamlines the creative process and provides all the tools that you need to write and deliver great talks!


[TAP THE ORANGE BUTTON]

To see how easy it is to use…


It is an all-in-one script editor, teleprompter, and audio & video recorder that’s ideal for ministers, teachers, coaches, online course creators, YouTubers, podcasters, vloggers, and all other talking heads :)


Scriptively can help you
</br>

• Overcome fear of public speaking</br>

• Be more confident</br>

• Appear more natural</br>

• Communicate with authority</br>

• Engage your audience</br>

• Expand your influence</br>


SCRIPT, PROMPT & SPEAK!</br>


Easily develop main ideas.</br>

Use storyboards to focus your attention and minimize writers block.</br>


Go from practice to mastery.</br>

Use the teleprompter to rehearse and master your talk.</br>


Visualize your success.</br>

Record and playback your talks to see and hear yourself the way others do.</br>


Stay synced and organized.</br>

Scriptively syncs across all of your Apple devices so your best scripts and ideas stay with you.</br>


This could be the best decision you have ever made towards becoming a better speaker!</br>


HOW TO VIDEOS</br>

For quick tutorials and tips on using Scriptively, open the left menu, then tap: Profile > How-To Videos.</br>


SUPPORT</br>

If you have questions, need help, or have suggestions for making Scriptively better, we’d love to hear from you. Open the left Profile menu and tap Support.</br> </span>`;
