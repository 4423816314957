export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
};

export const loaderConstants = {
  LOADER_START: "LOADER_START",
  LOADER_STOP: "LOADER_STOP",
};
export const userConstants = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",
};

export const updateScriptConstants = {
  USER_UPDATE_REQUEST: "USER_UPDATE_REQUEST",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_FAIL: "USER_RUPDATEFAIL",
  ENABLE_EDIT_SCRIPT: "ENABLE_EDIT_SCRIPT",
  DISABLE_EDIT_SCRIPT: "DISABLE_EDIT_SCRIPT",
  SELECTED_SCRIPT: "SELECTED_SCRIPT",
};

export const folderScriptConstants = {
  FOLDER_RELOAD_REQUEST: "FOLDER_RELOAD_REQUEST",
  FOLDER_RELOAD_SUCCESS: "FOLDER_RELOAD_SUCCESS",
  FOLDER_RELOAD_FAIL: "FOLDER_RELOAD_FAIL",
};
export const showAllScriptConstants = {
  USER_All_SCRIPT_REQUEST: "USER_All_SCRIPT_REQUEST",
  USER_ALL_SCRIPT_SUCCESS: "USER_ALL_SCRIPT_SUCCESS",
  USER_ALL_SCRIPT_FAIL: "USER_ALL_SCRIPT_FAIL",
};

export const showFoldersConstants = {
  SHOW_FOLDERS_REQUEST: "SHOW_FOLDERS_REQUEST",
  SHOW_FOLDERS_SUCCESS: "SHOW_FOLDERS_SUCCESS",
};

export const showStoryBoardsConstants = {
  SHOW_STORYBOARD_REQUEST: "SHOW_STORYBOARD_REQUEST",
  SHOW_STORYBOARD_SUCCESS: "SHOW_STORYBOARD_SUCCESS",
};

export const showNativePromptConstants = {
  SHOW_NATIVE_DATA_REQUEST: "SHOW_NATIVE_DATA_REQUEST",
  SHOW_NATIVE_DATA_SUCCESS: "SHOW_NATIVE_DATA_SUCCESS",
};

export const subscriptionConstants = {
  SUBSCRIPTION_REQUEST: "SUBSCRIPTION_REQUEST",
  SUBSCRIPTION_STATUS: "SUBSCRIPTION_STATUS",
  SUBSCRIPTION_FAIL: "SUBSCRIPTION_FAIL",
};

export const subscriptionModalConstants = {
  SUBSCRIPTION_MODAL_REQUEST: "SUBSCRIPTION_MODAL_REQUEST",
  SUBSCRIPTION_MODAL_SUCCESS: "SUBSCRIPTION_MODAL_SUCCESS",
};

export const settingConstants = {
  SETTING_DATA: "SETTING_DATA",
};

export const scrollingSpeedConstants = {
  INCREASE_DECREASE_SPEED: "INCREASE_DECREASE_SPEED",
  SCROLL_PLAY: "SCROLL_PLAY",
  SCROLL_PAUSE: "SCROLL_PAUSE",
};
