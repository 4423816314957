import * as React from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { showAllStoryBoards } from "../services/actions";
import { toastOptions } from "../utils/ToastAlert";
import { uploadDropBoxFileController } from "../utils/UploadDropBoxController";
export default function FilesBox({
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader,
  setEmailAndPrintAction,
  handleClick,
}) {
  const [scriptId, setScriptId] = React.useState(null);
  const dispatch = useDispatch();

  const fileTypeCheck = async (e) => {
    const fileData = e.target.files;
    const fileNme = fileData[0].name;
    const fileName = fileNme && fileNme.slice(0, -4);

    if (
      fileData[0].type === "application/pdf" ||
      fileData[0].type === "application/msword" ||
      fileData[0].type === "text/plain" ||
      // "application/rtf" ||
      fileData[0].type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      await uploadDropBoxFileController(
        fileData,
        googleDriveSendData,
        showAllScript,
        setToggleSelect,
        setLoader,
        fileName,
        setEmailAndPrintAction,
        setScriptId
      );
      let script_id = localStorage.getItem("file_script_id");

      dispatch(showAllStoryBoards(script_id));
      localStorage.removeItem("file_script_id");
    } else {
      toast.warn(
        "File is not supported, Please select another file?",
        toastOptions
      );
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: "none" }}
        id="contained-button-file"
        accept="application/pdf,text/plain, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        onChange={fileTypeCheck}
      />
      <label htmlFor="contained-button-file" onClick={() => handleClick()}>
        <img
          src="assets/images/Vector.svg"
          alt=""
          srcSet=""
          width="22px"
          aria-controls="simple-menu"
          aria-haspopup="true"
        />
      </label>
    </div>
  );
}
