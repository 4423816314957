export const refreshPageController = () => {
  setTimeout(() => {
    window.onunload = refreshParent;
    function refreshParent() {
      window.opener.location.reload();
    }
    // if (window.opener.progressWindow) {
    //   window.opener.progressWindow.close();
    // }
    window.close();
    window.close();
  }, 500);
};
