import axios from "axios";
import { toast } from "react-toastify";
import {
  addIntroStoryBoardController,
  addObjectiveStoryBoardController,
} from "../components/GoogleDrive/services/GoogleDriveController";
// import { addNewScriptController } from "../components/GoogleDrive/services/GoogleDriveController";
import { toastOptions } from "./ToastAlert";

export const dropBoxController = async (
  files,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader
) => {
  const fileData = files[0];
  // console.log(fileData);
  const fileName = fileData.name;
  const fileLink = fileData.link;

  if (fileName.slice(-4) === ".txt") {
    const res = await axios.get(`${fileLink}`);
    const attrText = res.data.split("\r\n").join("<br/>");
    let saveData = {
      scrTitle: fileName.slice(0, -4),
      scrText: res.data,
      attrText: attrText,
    };
    await addNewScriptDropBoxController(
      saveData,
      googleDriveSendData,
      showAllScript,
      setToggleSelect,
      setLoader
    );
    setLoader(false);
  } else if (fileName.slice(-4) === ".rtf") {
    const res = await axios.get(`${fileLink}`);
    console.log(res);
    const rtfData = await getRTFHtmlController(res);
    // var attrText = rtfData.data.data;

    const plainText = await rtfPlainTextController(rtfData);

    let saveData = {
      scrTitle: fileName.slice(0, -4),
      scrText: rtfData,
      attrText: rtfData,
    };

    await addNewScriptDropBoxController(
      saveData,
      googleDriveSendData,
      showAllScript,
      setToggleSelect,
      setLoader
    );
    setLoader(false);
  } else {
    document.getElementById("dropbox").click();
    toast.success(
      "Your drive file has been download successfully",
      toastOptions
    );
  }
};
export const testController = (setModalOpen) => {
  setModalOpen(true);
};

export const getRTFHtmlController = async (data) => {
  const rtfData = data.data;
  console.log(rtfData);

  const fileData = rtfData;
  var bodyFormData = new FormData();
  bodyFormData.append("data", fileData);

  const res = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/read_rtf`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  let attrText = res.data.data && res.data.data.split("font-size").join("");
  return attrText;
};

export const rtfPlainTextController = async (html) => {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
};

export const addNewScriptDropBoxController = async (
  saveData,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader
) => {
  setLoader(true);
  let {
    setIsActive,
    setStoryBoardHeading,
    setScriptImage,
    setShowScriptUuid,
    setEditAction,
    setScriptTitle,
    setScriptView,
    setShowScriptTitle,
    setScriptPara,
    setShowScriptAtrrText,
    setShowScriptId,
    setScrAlignment,
    setStoryBoard2,
    setSelectActive,
    allScript,
    setAllScript,

    setNewScriptId,
  } = googleDriveSendData;
  setStoryBoard2([]);
  let user_id = localStorage.getItem("userId");
  let timestamps = new Date().getTime();

  try {
    setIsActive(false);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/add_script`, {
        user_id: user_id,
        scrTitle: saveData.scrTitle,
        scrText: saveData.scrText,
        scrAttrText: saveData.attrText,
        scrEditTextSize: "20",
        scrPromptTextSize: "28",
        scrPromptSpeed: "1",
        textMargin: "0",
        marker: false,
        markerX: "140",
        uuid: `${user_id}-${timestamps}`,
        scrManualScrolling: false,
        scrCameraRecordMe: false,
        scrShowMeFullScreen: false,
        scrShowMeThumbnail: false,
        scrVoiceRecordMe: false,
        scrColor: "",
        scrAlignment: "left",
        scrMirror: "",
        scrInvert: "",
        scrFontFamily: "arial",
      })
      .then(async function (response) {
        setIsActive(false);
        const { message, data } = response.data;
        setToggleSelect(true);
        let script_id = data.id;

        try {
          addIntroStoryBoardController(
            user_id,
            script_id,
            setStoryBoard2,
            setIsActive
          );
        } catch (error) {}
        try {
          addObjectiveStoryBoardController(
            user_id,
            script_id,
            setStoryBoard2,
            setLoader,
            setIsActive
          );
        } catch (error) {
          console.log(error);
        }

        let scriptAttributedText = data.scrAttrText;
        document.getElementById("main_script").innerHTML = scriptAttributedText
          ? scriptAttributedText
          : "Type here...";
        setToggleSelect(true);

        setShowScriptId(script_id);
        setNewScriptId(script_id);

        setShowScriptAtrrText(data.scrText);
        setShowScriptTitle(data.scrTitle);

        setEditAction(true);

        if (message === "Script Successfully Added") {
          let scriptLocalData = {
            user_id: data.user_id,
            script_id: data.id,
            scrTitle: data.scrTitle,
            scrText: data.scrText,
            scrAttrText: data.scrAttrText,
            uuid: data.uuid,
            scrAlignment: data.scrAlignment,
            scrInvert: data.scrInvert,
            scrMirror: data.scrMirror,
            textMargin: data.textMargin,
            scrEditTextSize: data.scrEditTextSize,
            scrPromptTextSize: data.scrPromptTextSize,
            scrShowMeThumbnail: data.scrShowMeThumbnail,
            scrShowMeFullScreen: data.scrShowMeFullScreen,
            scrCameraRecordMe: data.scrCameraRecordMe,
            scrVoiceRecordMe: data.scrVoiceRecordMe,
            scrManualScrolling: data.scrManualScrolling,
          };

          localStorage.setItem(
            "scriptLocalData",
            JSON.stringify(scriptLocalData)
          );

          setScriptImage("assets/images/add.svg");
          setShowScriptUuid(data.uuid);
          setStoryBoardHeading(true);

          toast.success("Script has been successfully added", toastOptions);
          setEditAction(true);
          const allsc = [...allScript, data];

          setScriptTitle(data.scrTitle);
          setScriptView(data);
          setScriptPara(data.scrText);
          setAllScript(allsc);
          allScript = data;

          const attributedScriptText = data.scrText;
          let attrSampleText = attributedScriptText.split("<p ></p>").join("");
          localStorage.setItem("attrTxt", attrSampleText);

          localStorage.setItem("t", data.scrTitle);

          setScrAlignment(data.scrAlignment);
        }
        setLoader(false);

        localStorage.setItem("file_script_id", script_id);
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
    showAllScript();

    setSelectActive(true);
    setLoader(false);
  } catch (error) {
    setLoader(false);
  }
};
