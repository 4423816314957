import $ from "jquery";

export const startSpeechController = (SpeechRecognition) => {
  document.getElementById("scroll__header").style.display = "none";
  document.getElementById("stop__recording").style.marginBottom = "70px";
  document.getElementById("voiceRecoMedia").style.display = "inline";

  // document.getElementById("seek__range").style.display = "none";
  // startSpeechToText();
  SpeechRecognition.startListening({ continuous: true });
  const seekBarFooter = document.getElementById("seek__footer");
  seekBarFooter.style.display = "inline";
  const scrFooter = document.getElementById("scrolling__footer");
  scrFooter.style.display = "none";

  setInterval(function () {
    document.getElementById("voiceRecoMedia").style.display = "none";
  }, 5000);
};

// text matching
export const speachToScrollController = (text, getOffsetToScroll) => {
  let matchText = text.toLowerCase(); //lowercase

  let matchTextUpper = text.toUpperCase(); //uppercase
  let firstLetterCapitalInString =
    matchText.charAt(0).toUpperCase() + matchText.slice(1); //firstLetterCapitalInString

  // getting first, sec, third words from whole speech text string
  let firstWord = matchText.split(" ")[0];
  let secondWord = matchText.split(" ")[1];
  let thirdWord = matchText.split(" ")[2];
  var word1;
  var word2;
  var word3;
  if (
    firstWord === undefined ||
    secondWord === undefined ||
    thirdWord === undefined
  ) {
    return false;
  } else {
    word1 = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
    word2 = secondWord.charAt(0).toUpperCase() + secondWord.slice(1);
    word3 = thirdWord.charAt(0).toUpperCase() + thirdWord.slice(1);
  }

  var AllFirstLetterCapitalize = `${word1} ${word2} ${word3}`; //AllFirstLetterCapitalize
  var FirstSecondLetterCapitalize = `${word1} ${word2} ${thirdWord}`; //FirstSecondLetterCapitalize
  var FirstThirdLetterCapitalize = `${word1} ${secondWord} ${word3}`; //FirstThirdLetterCapitalize
  var SecondThirdLetterCapitalize = `${firstWord} ${word2} ${word3}`; //SecondThirdLetterCapitalize
  var ThirdLetterCapitalize = `${firstWord} ${secondWord} ${word3}`; //ThirdLetterCapitalize
  var SecondLetterCapitalize = `${firstWord} ${word2} ${thirdWord}`; //SecondLetterCapitalize

  //comma seprated
  var firstWordWithComma = `${word1}, ${word2} ${word3}`;
  var secondWordWithComma = `${word1} ${word2}, ${word3}`;
  var thirdWordWithComma = `${word1} ${word2} ${word3},`;
  var allWordsWithComma = `${word1}, ${word2}, ${word3},`;
  var firstSecWordsWithComma = `${word1}, ${word2}, ${word3}`;
  var firstThirdWordsWithComma = `${word1}, ${word2} ${word3},`;
  var secThirdWordsWithComma = `${word1} ${word2}, ${word3},`;

  // full stop seprated
  var firstWordWithFullStop = `${word1}. ${word2} ${word3}`;
  var secondWordWithFullStop = `${word1} ${word2}. ${word3}`;
  var thirdWordWithFullStop = `${word1} ${word2} ${word3}.`;
  var firstThirdWordsWithFullStop = `${word1}. ${word2} ${word3}.`;

  // seprated with exclamation mark
  var firstWordWithExclamationMark = `${word1}! ${word2} ${word3}`;
  var secondWordWithExclamationMark = `${word1} ${word2}! ${word3}`;
  var thirdWordWithExclamationMark = `${word1} ${word2} ${word3}!`;
  var firstThirdWordsWithExclamationMark = `${word1}. ${word2} ${word3}.`;
  // seprated with double exclamation mark
  var firstWordWithDoubleExclamationMark = `${word1}!! ${word2} ${word3}`;
  var secondWordWithDoubleExclamationMark = `${word1} ${word2}!! ${word3}`;
  var lastWordWithDoubleExclamationMark = `${word1} ${word2} ${word3}!!`;

  // seprated with Single Quotation mark Start

  var firstWordWithSingleQuotationMarkStart = `'${word1} ${word2} ${word3}`;
  var secondWordWithSingleQuotationMarkStart = `${word1} '${word2} ${word3}`;
  var thirdWordWithSingleQuotationMarkStart = `${word1} ${word2} '${word3}!`;
  // seprated with Single Quotation mark End

  var firstWordWithSingleQuotationMarkEnd = `${word1}' ${word2} ${word3}`;
  var secondWordWithSingleQuotationMarkEnd = `${word1} ${word2}' ${word3}`;
  var thirdWordWithSingleQuotationMarkEnd = `${word1} ${word2} '${word3}'`;

  // seprated with Doule Quotation mark Start

  var firstWordWithDouleQuotationMarkStart = `"${word1} ${word2} ${word3}`;
  var secondWordWithDouleQuotationMarkStart = `${word1} "${word2} ${word3}`;
  var thirdWordWithDouleQuotationMarkStart = `${word1} ${word2} "${word3}!`;
  // seprated with Doule Quotation mark End
  var firstWordWithDouleQuotationMarkEnd = `${word1}" ${word2} ${word3}`;
  var secondWordWithDouleQuotationMarkEnd = `${word1} ${word2}" ${word3}`;
  var thirdWordWithDouleQuotationMarkEnd = `${word1} ${word2} ${word3}"`;

  // seprated with Doule Quotation marks and comma
  var firstWordWithDouleQuotationMarkWithCommaEnd = `"${word1}", ${word2} ${word3}`;
  var secondWordWithDouleQuotationMarkWithCommaEnd = `${word1} "${word2}", ${word3}`;
  var thirdWordWithDouleQuotationMarkWithCommaEnd = `${word1} ${word2} "${word3}",`;
  var allWordWithDouleQuotationMarkEnd = `"${word1}", "${word2}", "${word3}",`;
  var commaFirstLetter = `,${word1} ${word2} ${word3}`;
  var allVisibleText = document.getElementById(
    "scroll__content_hidden"
  ).innerText;

  var str2Upper = matchTextUpper;

  if (allVisibleText.includes(matchText) === true) {
    getOffsetToScroll(matchText);
  }

  // matchTextUpper
  else if (allVisibleText.includes(str2Upper) === true) {
    getOffsetToScroll(str2Upper);
  }
  // firstLetterCapitalInString
  else if (allVisibleText.includes(firstLetterCapitalInString) === true) {
    getOffsetToScroll(firstLetterCapitalInString);
  }
  // AllFirstLetterCapitalize
  else if (allVisibleText.includes(AllFirstLetterCapitalize) === true) {
    getOffsetToScroll(AllFirstLetterCapitalize);
  } // FirstSecondLetterCapitalize
  else if (allVisibleText.includes(FirstSecondLetterCapitalize) === true) {
    getOffsetToScroll(FirstSecondLetterCapitalize);
  } // FirstThirdLetterCapitalize
  else if (allVisibleText.includes(FirstThirdLetterCapitalize) === true) {
    getOffsetToScroll(FirstThirdLetterCapitalize);
  } // SecondThirdLetterCapitalize
  else if (allVisibleText.includes(SecondThirdLetterCapitalize) === true) {
    getOffsetToScroll(SecondThirdLetterCapitalize);
  } // ThirdLetterCapitalize
  else if (allVisibleText.includes(ThirdLetterCapitalize) === true) {
    getOffsetToScroll(ThirdLetterCapitalize);
  } // SecondLetterCapitalize
  else if (allVisibleText.includes(SecondLetterCapitalize) === true) {
    getOffsetToScroll(SecondLetterCapitalize);
  }

  // ***********************************
  //comma seprated
  else if (allVisibleText.includes(firstWordWithComma) === true) {
    getOffsetToScroll(firstWordWithComma);
  } else if (allVisibleText.includes(secondWordWithComma) === true) {
    getOffsetToScroll(secondWordWithComma);
  } else if (allVisibleText.includes(thirdWordWithComma) === true) {
    getOffsetToScroll(thirdWordWithComma);
  } else if (allVisibleText.includes(allWordsWithComma) === true) {
    getOffsetToScroll(allWordsWithComma);
  } else if (allVisibleText.includes(firstSecWordsWithComma) === true) {
    getOffsetToScroll(firstSecWordsWithComma);
  } else if (allVisibleText.includes(firstThirdWordsWithComma) === true) {
    getOffsetToScroll(firstThirdWordsWithComma);
  } else if (allVisibleText.includes(secThirdWordsWithComma) === true) {
    getOffsetToScroll(secThirdWordsWithComma);
  }
  // full stop seprated
  else if (allVisibleText.includes(firstWordWithFullStop) === true) {
    getOffsetToScroll(firstWordWithFullStop);
  } else if (allVisibleText.includes(secondWordWithFullStop) === true) {
    getOffsetToScroll(secondWordWithFullStop);
  } else if (allVisibleText.includes(thirdWordWithFullStop) === true) {
    getOffsetToScroll(thirdWordWithFullStop);
  } else if (allVisibleText.includes(firstThirdWordsWithFullStop) === true) {
    getOffsetToScroll(firstThirdWordsWithFullStop);
  }

  // seprated with exclamation mark
  else if (allVisibleText.includes(firstWordWithExclamationMark) === true) {
    getOffsetToScroll(firstWordWithExclamationMark);
  } else if (allVisibleText.includes(secondWordWithExclamationMark) === true) {
    getOffsetToScroll(secondWordWithExclamationMark);
  } else if (allVisibleText.includes(thirdWordWithExclamationMark) === true) {
    getOffsetToScroll(thirdWordWithExclamationMark);
  } else if (
    allVisibleText.includes(firstThirdWordsWithExclamationMark) === true
  ) {
    getOffsetToScroll(firstThirdWordsWithExclamationMark);
  }
  // seprated with double exclamation mark
  else if (
    allVisibleText.includes(firstWordWithDoubleExclamationMark) === true
  ) {
    getOffsetToScroll(firstWordWithDoubleExclamationMark);
  } else if (
    allVisibleText.includes(secondWordWithDoubleExclamationMark) === true
  ) {
    getOffsetToScroll(secondWordWithDoubleExclamationMark);
  } else if (
    allVisibleText.includes(lastWordWithDoubleExclamationMark) === true
  ) {
    getOffsetToScroll(lastWordWithDoubleExclamationMark);
  }
  // seprated with double exclamation mark
  else if (allVisibleText.includes(thirdWordWithExclamationMark) === true) {
    getOffsetToScroll(thirdWordWithExclamationMark);
  }
  // seprated with Single Quotation mark Start
  else if (
    allVisibleText.includes(firstWordWithSingleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(firstWordWithSingleQuotationMarkStart);
  } else if (
    allVisibleText.includes(secondWordWithSingleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(secondWordWithSingleQuotationMarkStart);
  } else if (
    allVisibleText.includes(thirdWordWithSingleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(thirdWordWithSingleQuotationMarkStart);
  }
  // seprated with Single Quotation mark End
  else if (
    allVisibleText.includes(firstWordWithSingleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(firstWordWithSingleQuotationMarkEnd);
  } else if (
    allVisibleText.includes(secondWordWithSingleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(secondWordWithSingleQuotationMarkEnd);
  } else if (
    allVisibleText.includes(thirdWordWithSingleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(thirdWordWithSingleQuotationMarkEnd);
  }

  // seprated with Doule Quotation mark Start
  else if (
    allVisibleText.includes(firstWordWithDouleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(firstWordWithDouleQuotationMarkStart);
  } else if (
    allVisibleText.includes(secondWordWithDouleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(secondWordWithDouleQuotationMarkStart);
  } else if (
    allVisibleText.includes(thirdWordWithDouleQuotationMarkStart) === true
  ) {
    getOffsetToScroll(thirdWordWithDouleQuotationMarkStart);
  }
  // seprated with Doule Quotation mark End
  else if (
    allVisibleText.includes(firstWordWithDouleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(firstWordWithDouleQuotationMarkEnd);
  } else if (
    allVisibleText.includes(secondWordWithDouleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(secondWordWithDouleQuotationMarkEnd);
  } else if (
    allVisibleText.includes(thirdWordWithDouleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(thirdWordWithDouleQuotationMarkEnd);
  } else if (allVisibleText.includes(commaFirstLetter) === true) {
    getOffsetToScroll(commaFirstLetter);
  }
  // seprated with Doule Quotation marks and comma
  else if (
    allVisibleText.includes(firstWordWithDouleQuotationMarkWithCommaEnd) ===
    true
  ) {
    getOffsetToScroll(firstWordWithDouleQuotationMarkWithCommaEnd);
  } else if (
    allVisibleText.includes(secondWordWithDouleQuotationMarkWithCommaEnd) ===
    true
  ) {
    getOffsetToScroll(secondWordWithDouleQuotationMarkWithCommaEnd);
  } else if (
    allVisibleText.includes(thirdWordWithDouleQuotationMarkWithCommaEnd) ===
    true
  ) {
    getOffsetToScroll(thirdWordWithDouleQuotationMarkWithCommaEnd);
  } else if (
    allVisibleText.includes(allWordWithDouleQuotationMarkEnd) === true
  ) {
    getOffsetToScroll(allWordWithDouleQuotationMarkEnd);
  }

  // ***********************************
};

export const addCustomScriptSpaceController = (randomId, matchText) => {
  let firstWord = matchText.split(" ")[0];
  let secondWord = matchText.split(" ")[1];
  let thirdWord = matchText.split(" ")[2];
  var word1;
  var word2;
  var word3;
  if (
    firstWord === undefined ||
    secondWord === undefined ||
    thirdWord === undefined
  ) {
    return false;
  } else {
    word1 = firstWord.charAt(0) + firstWord.slice(1);
    word2 = secondWord.charAt(0) + secondWord.slice(1);
    word3 = thirdWord.charAt(0) + thirdWord.slice(1);
  }

  var whiteSpace = `${word1}  ${word2}  ${word3}`;

  $("#scroll__content_hidden").html(
    $("#scroll__content_hidden")
      .html()
      .replace(matchText, `<a id=${randomId}></a>` + whiteSpace)
  );
};

export const removeAddRandomIdController = () => {
  $("#scroll__content_hidden").html(
    $("#scroll__content_hidden").html().split("imhere").join("")
  );
};
