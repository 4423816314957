import axios from "axios";
import { gapi } from "gapi-script";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/ToastAlert";

let scriptName;
//import files from google drive
export const downloadFilesController = (
  data,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setOpen,
  setLoader,
  setLoaderTest
) => {
  if (data.docs) {
    setLoaderTest(true);
    return data.docs.map((item) => {
      setLoader(true);

      scriptName = item.name.slice(0, -4);

      if (item.mimeType === "text/plain") {
        setLoader(true);
        getTxtDriveDataController(
          item.id,
          googleDriveSendData,
          showAllScript,
          setToggleSelect,
          setLoader,
          setLoaderTest
        );
      } else if (
        item.mimeType === "application/msword" &&
        item.name.slice(-4) === ".rtf"
      ) {
        setLoader(true);
        getRTFDriveDataController(
          item.id,
          googleDriveSendData,
          showAllScript,
          setToggleSelect,
          setLoader,
          setLoaderTest
        );
        // setLoaderTest(false);
      } else if (item.name.slice(-4) === ".doc") {
        setLoader(true);
        getMSWordDriveDataController(
          item.id,
          setOpen,
          setLoader,
          setLoaderTest
        );
        // setLoaderTest(false);
      } else if (item.mimeType === "application/pdf") {
        setLoader(true);
        getPdfDriveDataController(item.id, setOpen, setLoader, setLoaderTest);
        // setLoaderTest(false);
      } else if (
        item.mimeType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setLoader(true);

        getofficedocumentDriveDataController(
          item.id,
          setOpen,
          setLoader,
          setLoaderTest
        );
        // setLoaderTest(false);
      } else {
        setLoader(false);
        setLoaderTest(false);
      }
    });
  }
  setLoaderTest(false);
};

// plain/text format controller
export const getTxtDriveDataController = async (
  id,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader
  // setLoaderTest
) => {
  try {
    // setLoaderTest(true);
    setLoader(true);
    gapi.load("client", function () {
      gapi.client.load("drive", "v3", function () {
        gapi.client.drive.files
          .get({
            fileId: id,
            alt: "media",
            responseType: "stream",
            true: true,
          })
          .then(async (res) => {
            const attrText = res.body.split("\r\n").join("<br/>");

            let saveData = {
              scrTitle: scriptName,
              scrText: res.body,
              attrText: attrText,
            };
            // googleDriveSendData.setShowScriptAtrrText(res.body);
            await addNewScriptController(
              saveData,
              googleDriveSendData,
              showAllScript,
              setToggleSelect,
              setLoader
              // setLoaderTest
            );
            // setLoaderTest(false);
            setLoader(false);
            // a.click();
          })
          .catch((err) => {
            setLoader(false);
            // setLoaderTest(false);
            console.log(err);
          });
      });
    });
  } catch (error) {
    setLoader(false);
    // setLoaderTest(false);
  }
};

// rtf to plainText format controller
export const getRTFDriveDataController = (
  id,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader,
  setLoaderTest
) => {
  try {
    gapi.load("client", async function () {
      gapi.client.load("drive", "v3", function () {
        gapi.client.drive.files
          .get({
            fileId: id,
            alt: "media",
            responseType: "stream",
            true: true,
          })
          .then(async (res) => {
            // console.log(res.body);
            try {
              var attrText = await getRTFHtmlController(res);
              // const plainText = await rtfPlainTextController(attrText);

              let saveData = {
                scrTitle: scriptName,
                scrText: attrText,
                attrText: attrText,
              };

              await addNewScriptController(
                saveData,
                googleDriveSendData,
                showAllScript,
                setToggleSelect,
                setLoader,
                setLoaderTest
              );
              setLoader(false);
            } catch (error) {
              setLoader(false);
            }

            console.log(attrText);
            // const searchRegExp = /:font-size:/g;
            // var attrText = rtfData.data.data.replace(searchRegExp, "");
          })

          .catch((err) => {
            setLoader(false);
            setLoaderTest(false);

            console.log(err);
          });
      });
    });
  } catch (error) {
    setLoader(false);
  }
};

// ms word

export const getMSWordDriveDataController = async (
  id,
  setOpen,
  setLoader,
  setLoaderTest
) => {
  try {
    setLoaderTest(true);
    setLoader(true);

    await gapi.load("client", function () {
      gapi.client.load("drive", "v3", function () {
        gapi.client.drive.files
          .get({
            fileId: id,
            alt: "media",
            responseType: "stream",
            true: true,
          })
          .then(async (res) => {
            const filename = `${scriptName}.doc`;
            const data = res.body;
            const len = data.length;

            const ar = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              ar[i] = data.charCodeAt(i);
            }

            const blob = new Blob([ar], { type: "text/plain" });

            // // Save the file.
            const a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;

            a.download = filename;
            a.click();
            setOpen(true);
            setLoaderTest(false);
            setLoader(false);
          })
          .catch((err) => {
            setLoaderTest(false);
            setLoader(false);
            console.log(err);
          });
      });
    });
  } catch (error) {
    setLoaderTest(false);
    setLoader(false);
  }
};

// pdf format controller

export const getPdfDriveDataController = async (
  id,
  setOpen,
  setLoader,
  setLoaderTest
) => {
  setLoaderTest(true);
  setLoader(true);

  await gapi.load("client", function () {
    gapi.client.load("drive", "v3", function () {
      gapi.client.drive.files
        .get({
          fileId: id,
          alt: "media",
          responseType: "stream",
          true: true,
        })
        .then((res) => {
          const filename = `${scriptName}.pdf`;
          const data = res.body;
          const len = data.length;

          const ar = new Uint8Array(len);
          for (let i = 0; i < len; i++) {
            ar[i] = data.charCodeAt(i);
          }

          const blob = new Blob([ar], { type: "text/plain" });

          // // Save the file.
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(blob);
          a.href = url;

          a.download = filename;
          a.click();
          setOpen(true);
          setLoaderTest(false);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setLoaderTest(false);
          console.log(err);
        });
    });
  });
};

//application/vnd.openxmlformats-officedocument.wordprocessingml.document
export const getofficedocumentDriveDataController = async (
  id,
  setOpen,
  setLoader,
  setLoaderTest
) => {
  try {
    setLoaderTest(true);
    setLoader(true);

    await gapi.load("client", function () {
      gapi.client.load("drive", "v3", function () {
        gapi.client.drive.files
          .get({
            fileId: id,
            alt: "media",
            responseType: "stream",
            true: true,
          })
          .then(async (res) => {
            const filename = `${scriptName}.docx`;
            const data = res.body;
            const len = data.length;

            const ar = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              ar[i] = data.charCodeAt(i);
            }

            const blob = new Blob([ar], { type: "text/plain" });

            // // Save the file.
            const a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;

            a.download = filename;
            a.click();
            setOpen(true);
            setLoaderTest(false);
            setLoader(false);
          })
          .catch((err) => {
            setLoaderTest(false);
            setLoader(false);

            console.log(err);
          });
      });
    });
  } catch (error) {
    setLoaderTest(false);
    setLoader(false);
  }
};

// add inside the scripts

export const addNewScriptController = async (
  saveData,
  googleDriveSendData,
  showAllScript,
  setToggleSelect,
  setLoader
  // setLoaderTest
) => {
  // setStoryBoard2([]);
  setLoader(true);
  let {
    setIsActive,
    setStoryBoardHeading,
    setScriptImage,
    setShowScriptUuid,
    setEditAction,
    setScriptTitle,
    setScriptView,
    setShowScriptTitle,
    setScriptPara,
    setShowScriptAtrrText,
    setShowScriptId,
    setScrAlignment,
    setStoryBoard2,
    setSelectActive,
    allScript,
    setAllScript,

    setNewScriptId,
  } = googleDriveSendData;
  setStoryBoard2([]);
  let user_id = localStorage.getItem("userId");
  let timestamps = new Date().getTime();

  try {
    setIsActive(false);
    await axios
      .post(`${process.env.REACT_APP_API_URL}/add_script`, {
        user_id: user_id,
        scrTitle: saveData.scrTitle,
        scrText: saveData.scrText,
        scrAttrText: saveData.attrText,
        scrEditTextSize: "20",
        scrPromptTextSize: "28",
        scrPromptSpeed: "1",
        textMargin: "0",
        marker: false,
        markerX: "140",
        uuid: `${user_id}-${timestamps}`,
        scrManualScrolling: false,
        scrCameraRecordMe: false,
        scrShowMeFullScreen: false,
        scrShowMeThumbnail: false,
        scrVoiceRecordMe: false,
        scrColor: "",
        scrAlignment: "left",
        scrMirror: "",
        scrInvert: "",
        scrFontFamily: "arial",
      })
      .then(async function (response) {
        // setStoryBoard2([]);
        console.log(response);
        setIsActive(false);
        const { message, data } = response.data;
        setToggleSelect(true);
        let script_id = data.id;
        try {
          addIntroStoryBoardController(
            user_id,
            script_id,
            setStoryBoard2,
            setIsActive
          );
        } catch (error) {
          console.log(error);
        }
        try {
          addObjectiveStoryBoardController(
            user_id,
            script_id,
            setStoryBoard2,
            setLoader,
            setIsActive
          );
        } catch (error) {
          console.log(error);
        }

        let scriptAttributedText = data.scrAttrText;
        document.getElementById("main_script").innerHTML = scriptAttributedText
          ? scriptAttributedText
          : "Type here...";
        setToggleSelect(true);

        setShowScriptId(script_id);
        setNewScriptId(script_id);

        setShowScriptAtrrText(data.scrText);
        setShowScriptTitle(data.scrTitle);

        setEditAction(true);

        if (message === "Script Successfully Added") {
          let scriptLocalData = {
            user_id: data.user_id,
            script_id: data.id,
            scrTitle: data.scrTitle,
            scrText: data.scrText,
            scrAttrText: data.scrAttrText,
            uuid: data.uuid,
            scrAlignment: data.scrAlignment,
            scrInvert: data.scrInvert,
            scrMirror: data.scrMirror,
            textMargin: data.textMargin,
            scrEditTextSize: data.scrEditTextSize,
            scrPromptTextSize: data.scrPromptTextSize,
            scrShowMeThumbnail: data.scrShowMeThumbnail,
            scrShowMeFullScreen: data.scrShowMeFullScreen,
            scrCameraRecordMe: data.scrCameraRecordMe,
            scrVoiceRecordMe: data.scrVoiceRecordMe,
            scrManualScrolling: data.scrManualScrolling,
          };

          localStorage.setItem(
            "scriptLocalData",
            JSON.stringify(scriptLocalData)
          );

          setScriptImage("assets/images/add.svg");
          setStoryBoardHeading(true);
          setShowScriptUuid(data.uuid);

          toast.success("Script has been successfully added", toastOptions);
          setEditAction(true);
          const allsc = [...allScript, data];

          setScriptTitle(data.scrTitle);
          setScriptView(data);
          setScriptPara(data.scrText);
          setAllScript(allsc);
          allScript = data;

          const attributedScriptText = data.scrText;
          let attrSampleText = attributedScriptText.split("<p ></p>").join("");
          localStorage.setItem("attrTxt", attrSampleText);

          localStorage.setItem("t", data.scrTitle);

          setScrAlignment(data.scrAlignment);
        }
        setLoader(false);
        // setLoaderTest(false);
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
    showAllScript();

    setSelectActive(true);
    setLoader(false);
  } catch (error) {
    setLoader(false);
  }
};
// setLoader(false);
// };

export const addIntroStoryBoardController = async (
  user_id,
  script_id,
  setStoryBoard2,
  setIsActive
) => {
  setIsActive(false);

  await axios
    .post(`${process.env.REACT_APP_API_URL}/add_story_board`, {
      user_id,
      script_id,
      storyboardName: "Introduction",
      storyboardText: "",
      storyboardAttrText: "",
      draggableId: "",
    })
    .then(function (response) {
      setIsActive(false);
      const data = response.data.data;
      setStoryBoard2((prevArray) => [...prevArray, data]);
    });
};

export const addObjectiveStoryBoardController = async (
  user_id,
  script_id,
  setStoryBoard2,
  setLoader,
  setIsActive
) => {
  setLoader(true);
  setIsActive(false);

  await axios
    .post(`${process.env.REACT_APP_API_URL}/add_story_board`, {
      user_id,
      script_id,
      storyboardName: "Objective",
      storyboardText: "",
      storyboardAttrText: "",
      draggableId: "",
    })
    .then(function (response) {
      setIsActive(false);

      const data = response.data.data;
      setStoryBoard2((prevArray) => [...prevArray, data]);
      setLoader(false);
    });
};

export const getRTFHtmlController = async (data) => {
  const rtfData = data.body;

  const fileData = rtfData;
  var bodyFormData = new FormData();
  bodyFormData.append("data", fileData);

  const res = await axios({
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/read_rtf`,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  var attrText = res.data.data && res.data.data.split("font-size").join("");

  return attrText;
};

export const rtfPlainTextController = async (html) => {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
};
