import { settingConstants } from "../actions/constants";

const initialState = {
  settingData: null,
  userMode: null,
};

export const settingReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingConstants.SETTING_DATA:
      return {
        ...state,
        settingData: action.payload.data.data,
        userMode: action.payload.userMode,
      };

    default:
      return state;
  }
};
