import React, { useState, useEffect } from "react";
import AOS from "aos";
import styled from "styled-components";

import ScrollingFooter from "./ScrollingFooter";
import Seek, { testRange } from "../Range/Seek";
import { useWindowScroll } from "react-use";
import UseRecorder from "../AudioRecord/UseRecorder";
import { saveAs } from "file-saver";
import LoadingOverlay from "react-loading-overlay";

import {
  scrollSpeedAction,
  scrollSpeedUpDownKeyAction,
  showAllFolderScripts,
  showAllScripts,
} from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./scroll.css";
import "aos/dist/aos.css";
import toBuffer from "blob-to-buffer";
import useSpeechToText from "react-hook-speech-to-text";
import $ from "jquery";
import {
  changeThumbnailPosition,
  updatePromptScriptDataController,
  disableEditController,
  contentHeightController,
  fullVideoPlayController,
  stopScrollController,
  startVideoController,
  scrollingFooterController,
  hideVoiceErrorController,
  promptScrColorController,
  seekBarFooterController,
} from "../../utils/ScrollController";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router";
import { toastOptions } from "../../utils/ToastAlert";
import {
  startManualPageScrollController,
  stopManualPageScrollController,
} from "../../utils/ManuallyPageScrollController/manuallyPageScrollController";
import queryString from "query-string";

import { nativeWindowDataFetchController } from "../../utils/NativeAppDataController";
import { disableCameraGreenLightController } from "../../utils/VideoGreenLightDisable/VideoGreenLightDisable";
import { settingAction } from "../../services/actions/setting.actions";
import axios from "axios";
import { get_control_values } from "../../utils/ApiList";

var Url = require("url-parse");

// ..
AOS.init();
function PromptPageComponent() {
  let promptData = JSON.parse(localStorage.getItem("scriptLocalData"));
  const { scrollValue } = useSelector((state) => state.scrollValue);

  const [nativeTitleAction, setNativeTitleAction] = useState(false);
  const [controls, setControls] = useState(null);

  const [title, setTitle] = useState("");
  const [attrText, setAttributedText] = useState(
    localStorage.getItem("attrTxt")
  );
  // let title;
  // let attrText;
  var url = new Url(window.location.href);

  const substringData = url && url.hash.substring(5);
  const query = queryString.parse(substringData && substringData);
  let user_id = query && query.response;
  let script_id = query && query.script_id;
  let nativeWindow = query && query.nativeApp && query.nativeApp.slice(0, -1);

  useEffect(async () => {
    setLoader(true);

    if (script_id === undefined || script_id === null) {
      setTitle(localStorage.getItem("title"));
      setAttributedText(localStorage.getItem("attrTxt"));

      // title = localStorage.getItem("title");
      // attrText = localStorage.getItem("attrTxt");
      setLoader(false);
    } else {
      const nativeData = await nativeWindowDataFetchController(
        user_id,
        script_id,
        setLoader
      );

      promptData = nativeData && nativeData;
      setPromptTextSize(nativeData && nativeData.scrPromptTextSize);
      setTextMargin(nativeData && nativeData.textMargin);
      // setPromptData(data);
      // setAttrTxtHidden(nativeData && nativeData.hiddenData);
      setTitle(nativeData && nativeData.scrTitle);
      setAttributedText(
        nativeData &&
          nativeData.scrAttrText &&
          nativeData.scrAttrText.split("font-size").join("")
      );
      setNativeTitleAction(true);
      setLoader(false);
    }
  }, []);

  if (promptData && promptData === null) {
    return <Redirect to="/error" />;
  }

  let attrTextTxt = attrText && attrText.split("<p></p>").join("");
  let scrFullScreen;
  let thumbnailCheckeded;
  let videoRecordMe;
  let voiceRecordMe;
  let scrManualScroll;
  let scrollLoop = [];
  var verticalYOffset;

  const [scrColor, setScrColor] = useState(promptData && promptData.scrColor);

  useEffect(() => {
    let scrData = {
      scrColor,
      setScrColor,
    };

    promptScrColorController(scrData);
  }, []);

  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const [footerPenalAction, setFooterPenalAction] = useState(false);

  const [scrMirror, setScrMirror] = useState(false);
  const [scrInvert, setScrInvert] = useState(false);
  const [textMargin, setTextMargin] = useState(
    promptData && promptData.textMargin
  );
  const [manuallyScrollAction, setManuallyScrollAction] = useState(false);

  const [promptTextSize, setPromptTextSize] = useState(
    promptData && promptData.scrPromptTextSize
  );
  const [topToBottomAction, setTopToBottomAction] = useState(false);

  const a1 = attrTextTxt && attrTextTxt.split("p.p1").join("");

  let a2 = a1 && a1.split("p.p2").join(" ");
  let a3 = a2 && a2.split("span.s1").join("");
  let a4 = a3 && a3.split("span.s2").join("");
  let a5 = a4 && a4.split("• ").join("");

  let a8 = a5 && a5.split(":)").join("");
  const a81 = a8 && a8.split("font-size").join("");
  const a91 = a81 && a81.split("255, 255, 255").join("");

  const attrTxt = a91 && a91.split("#fff").join("#000");

  let a9 = a8 && a8.split("[").join("");
  let a10 = a9 && a9.split("]").join("");
  let a11 = a10 && a10.split("'s").join("");
  let a12 = a11 && a11.split("'").join("");
  let a13 = a12 && a12.split(`"`).join("");
  let a14 = a13 && a13.split("!").join("");
  let a15 = a14 && a14.split(",").join("");
  let a16 = a15 && a15.split("!!").join("");
  let a17 = a16 && a16.split("&").join("and");

  let a18 = a17 && a17.toLocaleLowerCase();

  const attrTxtHidden = a18 && a18.toLocaleLowerCase();
  let [timers, setTimers] = useState([]);

  //audio recording
  let [isRecording, startRecording, stopRecording] = UseRecorder();
  const [recordStartIcon, setRecordIcon] = useState(false);
  const [audioRecordChecked, setAudioRecordChecked] = useState(false);

  //video
  const [recorder, setRecorder] = useState(null);
  const [startVideoRecordIcon, setStartVideoRecordIcon] = useState(true); // vice-versa for audio & video record icon
  const [stream, setStream] = useState(null);

  //video modes
  const [fullMode, setFullMode] = useState(false);
  const [thumbModes, setThumbModes] = useState(false);

  const [playAction, setPlayAction] = useState(false);
  const [stopAudioRecording, setStopAudioRecording] = useState(false);
  const [recordChecked, setRecordChecked] = useState(false);
  const [videoStop, setVideoStop] = useState(false);
  const [startVoiceRecognition, setStartVoiceRecognition] = useState(false);

  const [startRecordIcon, setStartRecordIcon] = useState(false);
  const { y } = useWindowScroll();

  const [thumbnailChecked, setThumbnailChecked] = useState(false);
  const [fullScreenChecked, setFullScreenChecked] = useState(false);

  const [stopCamera, setStopCamera] = useState(null);
  const [txtViewContentHeight, setTxtViewContentHeight] = useState(0);

  let stopScrollData = {
    stopAudioRecording,
    setPlayAction,
    timers,
  };

  var startVideoControllerPassData = {
    setRecordIcon,
    setFullMode,
    setThumbModes,
    setStopCamera,
  };

  useEffect(() => {
    const heightDiv =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setTxtViewContentHeight(heightDiv);
  }, [y]);

  useEffect(() => {
    contentHeightController();
  }, []);

  // update scripts
  const updatePromptScript = async () => {
    changeThumbnailPosition();

    const scrMirrorSetting = scrMirror ? "1" : "";
    const scrInvertSetting = scrInvert ? "1" : "";

    let promptSettingData = {
      textMargin,
      videoRecordMe,
      scrFullScreen,
      thumbnailCheckeded,
      voiceRecordMe,
      scrMirrorSetting,
      scrInvertSetting,
      promptTextSize,
      setPromptTextSize,
      setLoader,
      startVoiceRecognition,
      audioRecordChecked,
      scrColor: promptData.scrColor,
    };

    try {
      await updatePromptScriptDataController(promptData, promptSettingData);
      dispatch(showAllScripts());
      dispatch(showAllFolderScripts());
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  // view start video:
  const startVideo = () => {
    setRecordIcon(false);
    setFullMode(true);
    setThumbModes(true);
    navigator.getUserMedia(
      {
        video: true,
        audio: true,
        mimeType: "video/mp4;codecs:h264",
      },

      (stream) => {
        let video = document.getElementsByClassName("app__videoFeed")[0];
        let video1 = document.getElementsByClassName("app__videoFeed2")[0];
        let video2 = document.getElementsByClassName("app__videoFeed3")[0];

        setStopCamera(stream);
        if (video) {
          video.srcObject = stream;
        }
        if (video1) {
          video1.srcObject = stream;
        }
        if (video2) {
          video2.srcObject = stream;
        }
      },
      (err) => {
        toast.error("Media Device is not found", toastOptions);
      }
    );
  };
  //stop green light and video stream object
  const stopVideo = () => {
    if (videoStop === true && fullMode === true) {
      console.log("stop video 300");
      // setThumbnailChecked(false);
      // setFullScreenChecked(false);
      if (stopCamera === null) {
        return false;
      } else {
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "#000";
      fullvideo.style.display = "none";

      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
    } else if (videoStop === true && thumbModes === true) {
      console.log("stop video 317");

      setThumbnailChecked(false);
      setFullScreenChecked(false);

      if (stopCamera === null) {
        return false;
      } else {
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
      }
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "#000";
      fullvideo.style.display = "none";

      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
    } else {
      if (fullMode === true) {
        console.log("stop video 337");

        // setThumbnailChecked(false);
        // setFullScreenChecked(false);
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });

        if (stopCamera === null) {
          return false;
        } else {
        }
        // const fullvideo = document.getElementById("full__video");
        // fullvideo.style.display = "#000";
        // fullvideo.style.display = "none";

        // const thumb = document.getElementById("thumbnail__video");
        // thumb.style.display = "none";
      } else if (thumbModes === true) {
        console.log("stop video 356");

        setThumbnailChecked(false);
        setFullScreenChecked(false);
        if (stopCamera === null) {
          return false;
        } else {
          stopCamera.getTracks().forEach((track) => {
            track.stop();
          });
        }
        // const fullvideo = document.getElementById("full__video");
        // fullvideo.style.display = "#000";
        // fullvideo.style.display = "none";

        // const thumb = document.getElementById("thumbnail__video");
        // thumb.style.display = "none";
      }
    }
  };

  const handleChangeFullScreen = async (e = false) => {
    if (!e) {
      scrFullScreen =
        promptData && promptData.scrShowMeFullScreen.toLowerCase() === "false"
          ? false
          : true;
    } else {
      scrFullScreen = e.target.checked;
    }
    setRecordIcon(false);
    setFullScreenChecked(scrFullScreen);
    let VideoPlayControllerData = {
      scrFullScreen: scrFullScreen,
      recordChecked,
      setRecordChecked,
      setThumbnailChecked,
      videoStop: videoStop,
      thumbModes,
    };
    await stopVideo();

    await fullVideoPlayController(
      VideoPlayControllerData,
      startVideo,

      stopVideo
    );
  };

  // thumbnail video
  const handleChangeThumb = async (e = false) => {
    setRecordIcon(false);

    if (!e) {
      thumbnailCheckeded =
        promptData &&
        promptData.scrShowMeThumbnail &&
        promptData.scrShowMeThumbnail.toLowerCase() === "false"
          ? false
          : true;
    } else {
      thumbnailCheckeded = e.target.checked;
    }

    setThumbnailChecked(thumbnailCheckeded);

    if (recordChecked === true) {
      await stopVideo();

      if (thumbnailCheckeded === true) {
        await stopVideo();

        console.log("thumbnail stop 418");

        startVideoController(startVideoControllerPassData);

        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "none";
        fullvideo.style.backgroundColor = "#000";
        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "contents";

        document.getElementById("contentDiv").style.backgroundColor = "#000";
        document.getElementById("contentDiv").style.opacity = "1";
        setFullScreenChecked(false);
      } else {
        if (
          // recordChecked === false &&
          thumbnailCheckeded === false &&
          videoStop === false
        ) {
          console.log("thumbnail stop 436");
          await stopVideo();

          const thumb = document.getElementById("thumbnail__video");
          thumb.style.display = "none";
          const fullvideo = document.getElementById("full__video");
          fullvideo.style.display = "inline";
        }
      }
    } else if (thumbnailCheckeded === true) {
      console.log("thumbnail stop 443");

      // startVideo();
      await stopVideo();

      startVideoController(startVideoControllerPassData);
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "none";
      fullvideo.style.backgroundColor = "#000";
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "contents";

      document.getElementById("contentDiv").style.backgroundColor = "#000";
      document.getElementById("contentDiv").style.opacity = "1";
      setFullScreenChecked(false);
    } else {
      await stopVideo();
      setTimeout(async () => {
        await stopVideo();
      }, 300);
      const thumb = document.getElementById("thumbnail__video");
      thumb.style.display = "none";
      const fullvideo = document.getElementById("full__video");
      fullvideo.style.display = "inline";
    }
  };

  useEffect(() => {
    scrollingFooterController();
  }, []);

  //timer

  const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
  const [inerv, setInerv] = useState(null);

  var updateMs = time.ms;
  var updateS = time.s;
  var updateM = time.m;
  var updateH = time.h;

  const getControls = async () => {
    const { data } = await axios.get(get_control_values);
    setControls(data.data);
  };
  const start = async () => {
    await getControls();
    run();
    setInerv(setInterval(run, 10));
  };

  const reset = () => {
    clearInterval(inerv);
    setTime({ ms: 0, s: 0, m: 0, h: 0 });
  };

  const run = () => {
    if (updateM === 60) {
      updateH++;
      updateM = 0;
    }
    if (updateS === 60) {
      updateM++;
      updateS = 0;
    }
    if (updateMs === 100) {
      updateS++;
      updateMs = 0;
    }
    updateMs++;
    return setTime({ ms: updateMs, s: updateS, m: updateM, h: updateH });
  };

  //stop Audio recording
  const stopAudioRecordingFun = async () => {
    await stopRecording();
    stopScrollController(stopScrollData);
    setStartRecordIcon(false);
    setStopAudioRecording(false);
    setPlayAction(false);
    setRecordIcon(false);
  };

  //stop video recording

  const stopVideoRecording = async () => {
    if (videoStop === true) {
      if (recorder === null) {
      } else {
        await recorder.stopRecording();

        const blob = await recorder.getBlob();
        setStream(null);
        setRecorder(null);
        stream.stop();
        // await stopVideo();
        if (thumbnailChecked === false && fullScreenChecked === false) {
          if (stopCamera === null) {
            return false;
          } else {
            stopCamera &&
              stopCamera.getTracks().forEach((track) => {
                track.stop();
              });
          }
        }
        if (blob) {
          let date = new Date();
          let sd = date.toISOString().substring(0, 10);
          let hh = date.getHours();
          let mm = date.getMinutes();
          let s = date.getSeconds();

          let fullDateTime = sd + " " + hh + "_" + mm + "_" + s;

          if (navigator.appVersion.indexOf("Win") != -1) {
            saveAs(blob, `Video - ${title}-${fullDateTime}.mp4`);
            toast.success("Video recording saved successfully!", toastOptions);
          } else {
            saveAs(blob, `Video - ${title}-${fullDateTime}`);
            toast.success("Video recording saved successfully!", toastOptions);
          }
        }
      }
    }
  };

  // enable video recording icon
  const videoToggle = async (e = false) => {
    if (!e) {
      videoRecordMe =
        promptData && promptData.scrCameraRecordMe.toLowerCase() === "true"
          ? true
          : false;
    } else {
      videoRecordMe = e.target.checked;
    }
    if (videoRecordMe === true) {
      setRecordChecked(true);
      setRecordIcon(false); //voiceRecordMe record icon
      setAudioRecordChecked(false); //voiceRecordMe toggle icon
      setStartVideoRecordIcon(false); // we set default video record icon is true for audio record issue
    } else {
      setRecordChecked(false);
      setStartVideoRecordIcon(true); //hide video record icon
      stopVideoRecording();

      disableCameraGreenLightController(
        thumbnailChecked,
        fullScreenChecked,
        videoRecordMe,
        stopCamera
      );
    }
    // console.log(promptData.scrCameraRecordMe, "db");
  };

  // voice recordMe Function

  const micHandleChange = async (e = false) => {
    if (!e) {
      voiceRecordMe =
        promptData && promptData.scrVoiceRecordMe.toLowerCase() === "false"
          ? false
          : true;
    } else {
      voiceRecordMe = e.target.checked;
    }
    setRecordIcon(false);
    setAudioRecordChecked(voiceRecordMe);
    if (voiceRecordMe === true) {
      setRecordChecked(false);
      setStartVideoRecordIcon(true); //hide video record icon
      if (videoStop === true) {
        stopVideoRecording();
      } else if (fullMode === true) {
        stopVideo();
        setRecordIcon(true);

        const thumb = document.getElementById("thumbnail__video");
        thumb.style.display = "none";
        const fullvideo = document.getElementById("full__video");
        fullvideo.style.display = "none";
        document.getElementById("scroll__content").style.backgroundColor =
          "#000";
        document.getElementById("contentDiv").style.backgroundColor = "#000";
        document.getElementById("contentDiv").style.opacity = "1";
        // setThumbnailChecked(false);
      } else {
        setRecordIcon(true);
        // await startRecording();
        // document.getElementById("hideToggleAudio").style.display =
        //   "inline-block";
        // document.getElementById("showToggleAudio").style.display = "none";
      }
    } else {
      setRecordIcon(false);
      // stopAudioRecordingFun();
    }
  };

  //Mirror Script Function
  const mirror = async (e = false) => {
    let checked;
    if (!e) {
      checked = promptData && promptData.scrMirror === "1" ? true : false;
    } else {
      checked = e.target.checked;
    }

    if (checked === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, 1)";
      setScrMirror(true);
    } else if (scrInvert === true && checked === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, -1)";
      setScrMirror(true);

      // console.log(scrMirror);
    } else {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, 1)";
      setScrMirror(false);
    }
  };

  // Invert Script Function
  const invert = async (e = false) => {
    let check;
    if (!e) {
      check = promptData && promptData.scrInvert === "1" ? true : false;
    } else {
      check = e.target.checked;
    }
    setScrInvert(check);
    if (check === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, -1)";
      let scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      window.scrollTo(0, scrollHeight - 15);
      // window.scrollTo(0, document.body.scrollHeight);
      setTopToBottomAction(true);
      //
    } else if (check === true && scrMirror === true) {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(-1, -1)";
      setTopToBottomAction(true);
      //
    } else {
      const mirrorData = document.getElementById("contentDiv");
      mirrorData.style.transform = "scale(1, 1)";
      window.scroll({ top: 0, left: 0 });
      setTopToBottomAction(false);
      //
    }
  };

  //voice recognition setting
  const micRecognitionHandleChange = async (e = false) => {
    if (!e) {
      scrManualScroll =
        promptData && promptData.scrManualScrolling.toLowerCase() === "false"
          ? false
          : true;
    } else {
      scrManualScroll = e.target.checked;
    }

    setStartVoiceRecognition(scrManualScroll, setRecorder);
  };

  //auto Load  setting functions

  useEffect(() => {
    if (nativeWindow != "true") {
      handleChangeFullScreen();
      handleChangeThumb();
      videoToggle();
      micHandleChange();
      micRecognitionHandleChange();
      mirror();
      invert();
    }
  }, []);

  // voice recogination

  const { error, results, startSpeechToText, stopSpeechToText, interimResult } =
    useSpeechToText({
      continuous: true,
      timeout: false,
      speechRecognitionProperties: { interimResults: true },
      crossBrowser: true,
      googleApiKey: process.env.REACT_APP_API_KEY,
      googleCloudRecognitionConfig: {
        languageCode: "en-IN",
      },
      useOnlyGoogleCloud: true,
      // useLegacyResults: false,
    });

  const [val, setVal] = useState(3);

  useEffect(() => {
    $("#movable").click(function () {
      // $("#scroll__para").css("width", "calc(100% - 150px)");
      if ($(this).attr("trigger") === "0") {
        var x = window.matchMedia("(max-width: 1024px)");

        if (x.matches) {
          $(this).animate({ left: 80 + "%" }, 1400);
          setVal("1");
        } else {
          $(this).animate({ left: 88 + "%" }, 1400);
          setVal("1");
        }
      } else if ($(this).attr("trigger") === "1") {
        $(this).animate({ top: 75 + "vh" }, 700);

        setVal("2");
      } else if ($(this).attr("trigger") === "2") {
        $(this).animate({ top: 75 + "vh", left: "0px" }, 1400);

        setVal("3");
      } else if ($(this).attr("trigger") === "3") {
        $(this).animate({ top: "0px", left: "0px" }, 700);

        setVal("0");
      }
    });
  }, []);

  useEffect(() => {
    const move = document.getElementById("movable");
    move.style.right = "10px";
    move.style.bottom = "35vh";
    document.getElementById("voiceRecoMedia").style.display = "none";
  }, []);

  // bottom to top page scroll
  const startBottomToTopScroll = () => {
    setManuallyScrollAction(true);
    document.getElementById("scroll__header").style.display = "none";

    var textViewPromptHeight = localStorage.getItem("contentHeight") * 1000;

    seekBarFooterController();
    setPlayAction(true);
    localStorage.setItem("speedRange", 1);
    for (let i = 0; i < textViewPromptHeight; i++) {
      if (i === textViewPromptHeight) {
        scrollLoop.push(false);
      } else {
        scrollLoop.push(true);
      }
    }
    // call function iside fun
    scrollFun(function repeat(scrollLoop) {
      verticalYOffset = window.pageYOffset;

      var currentScrollPoint = Math.floor(verticalYOffset);
      if (
        currentScrollPoint === txtViewContentHeight ||
        currentScrollPoint === txtViewContentHeight + 1
      ) {
        while (timers.length > 0) {
          clearTimeout(timers.pop());
        }
      } else if (currentScrollPoint === 0 || currentScrollPoint === 15) {
        while (timers.length > 0) {
          clearTimeout(timers.pop());
        }
      } else {
        if (scrollLoop.winner) {
          scrollFun(repeat);
          var scrSpeedRatio = localStorage.getItem("speedRange");

          // window.scrollBy(0, -scrSpeedRatio)
          let x = testRange.toString();
          window.scrollBy(0, -x);

          const scrScroll = document.getElementById("contentDiv");
          scrScroll.style.scrollBehavior = "smooth";
        }
      }
    });
    // call back function
    function scrollFun(callback) {
      var scrSpeedRatio = parseInt(localStorage.getItem("speedRange"));
      const data = setTimeout(function () {
        callback({ winner: scrollLoop.shift() });
      }, ((scrSpeedRatio / 4) * 100) | 0);
      setTimers((prevArray) => [...prevArray, data]);
    }

    start(); //timer
  };

  useEffect(() => {
    $("html,body").animate({ scrollTop: 3 }, "slow");
  }, []);

  // SHORT CUT KEY BOARD INCREASE/DECREASE SCROLLING SPEED
  useEffect(() => {
    window.addEventListener(
      "keydown",
      (event) => {
        if (event.defaultPrevented) {
          return; // Do nothing if the event was already processed
        }

        switch (event.key) {
          case "Down": // IE/Edge specific value
          case "ArrowDown":
            // Do something for "down arrow" key press.

            dispatch(scrollSpeedUpDownKeyAction("Down"));

            break;
          case "Up": // IE/Edge specific value
          case "ArrowUp":
            // Do something for "up arrow" key press.

            dispatch(scrollSpeedUpDownKeyAction("Up"));
            break;
          case "Space":
            // Do something for "up arrow" key press.
            console.log("Space");
            break;

          default:
            return; // Quit when this doesn't handle the key event.
        }

        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
      },
      true
    );
  }, []);

  let toggle = localStorage.getItem("playPause");
  const funToggle = () => {
    if (toggle == true) {
      localStorage.setItem("playPause", false);
    } else {
      localStorage.setItem("playPause", true);
    }
  };

  const [boolean, setBoolean] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "keydown",
      (event) => {
        if (event.defaultPrevented) {
          return; // Do nothing if the event was already processed
        }

        if (event.code === "Space") {
          setBoolean(!boolean);

          return;
        }

        // Cancel the default action to avoid it being handled twice
        event.preventDefault();
      },
      true
    );
  }, [boolean]);

  useEffect(() => {
    localStorage.setItem("playPause", boolean);
  }, [boolean]);

  useEffect(() => {
    window.onkeydown = function (e) {
      return !(e.keyCode == 32 && e.target == document.body);
    };
  }, []);

  // templates starts

  return (
    <div id="prompt_page">
      <LoadingOverlay active={loader} spinner>
        <div
          id="scroll__para"
          className="text-white"
          // style={{ backgroundColor: "#000" }}
        >
          <div id="thumbnail__video">
            <div className="thumbnail_video">
              <div id="movable" trigger={val}>
                <video
                  height="150px"
                  width="150px"
                  muted
                  autoPlay
                  className="app__videoFeed3"
                ></video>
              </div>
            </div>
          </div>

          {/* Recording icons show and hide */}

          {startRecordIcon ? (
            <div className="blobs-container">
              <div className="blob red">
                <span className="record-text">Rec.</span>
              </div>
            </div>
          ) : null}

          <BackgroundVideo id="full__video">
            <div className="premeeting-screen">
              <div id="preview">
                <video
                  id="video"
                  muted
                  autoPlay
                  className="app__videoFeed "
                ></video>
              </div>
            </div>
          </BackgroundVideo>

          <ScriptScrollBtn>
            <div className="" id="scrolling__footer">
              <ScrollingFooter
                setFooterPenalAction={setFooterPenalAction}
                setManuallyScrollAction={setManuallyScrollAction}
                startBottomToTopScroll={startBottomToTopScroll}
                updatePromptScript={updatePromptScript}
                startVoiceRecognition={startVoiceRecognition}
                setStartVoiceRecognition={setStartVoiceRecognition}
                setStartRecordIcon={setStartRecordIcon}
                txtViewContentHeight={txtViewContentHeight}
                stopVideo={stopVideo}
                setFullScreenChecked={setFullScreenChecked}
                setThumbnailChecked={setThumbnailChecked}
                stopCamera={stopCamera}
                handleChangeThumb={handleChangeThumb}
                handleChangeFullScreen={handleChangeFullScreen}
                fullScreenChecked={fullScreenChecked}
                thumbnailChecked={thumbnailChecked}
                setPlayAction={setPlayAction}
                playAction={playAction}
                setStopAudioRecording={setStopAudioRecording}
                stopAudioRecording={stopAudioRecording}
                start={start}
                recordChecked={recordChecked}
                setRecordChecked={setRecordChecked}
                setVideoStop={setVideoStop}
                videoStop={videoStop}
                setRecordIcon={setRecordIcon}
                stopAudioRecordingFun={stopAudioRecordingFun}
                recordStartIcon={recordStartIcon}
                isRecording={isRecording}
                startRecording={startRecording}
                // setStopVideoRecordingIcon={setStopVideoRecordingIcon}
                setStream={setStream}
                setRecorder={setRecorder}
                startVideoRecordIcon={startVideoRecordIcon}
                setStartVideoRecordIcon={setStartVideoRecordIcon}
                stopVideoRecording={stopVideoRecording}
                setFullMode={setFullMode}
                fullMode={fullMode}
                audioRecordChecked={audioRecordChecked}
                setAudioRecordChecked={setAudioRecordChecked}
                setTimers={setTimers}
                timers={timers}
                scrInvert={scrInvert}
                setScrInvert={setScrInvert}
                scrMirror={scrMirror}
                setScrMirror={setScrMirror}
                topToBottomAction={topToBottomAction}
                setTopToBottomAction={setTopToBottomAction}
                textMargin={textMargin}
                setTextMargin={setTextMargin}
                setPromptTextSize={setPromptTextSize}
                promptTextSize={promptTextSize}
                videoToggle={videoToggle}
                micHandleChange={micHandleChange}
                //voice recogination passing props
                results={results}
                startSpeechToText={startSpeechToText}
                stopSpeechToText={stopSpeechToText}
                mirror={mirror}
                invert={invert}
                micRecognitionHandleChange={micRecognitionHandleChange}
                error={error}
                startVideo={startVideo}
                setLoader={setLoader}
              />
            </div>

            <div className="" id="seek__footer">
              <Seek
                settings={controls}
                setFooterPenalAction={setFooterPenalAction}
                nativeTitleAction={nativeTitleAction}
                title={title}
                stopScrollData={stopScrollData}
                stopTimer={reset}
                time={time}
                isRecording={isRecording}
                stopAudioRecordingFun={stopAudioRecordingFun}
                stopVideoRecording={stopVideoRecording}
                videoStop={videoStop}
                setVideoStop={setVideoStop}
                audioRecordChecked={audioRecordChecked}
                setAudioRecordChecked={setAudioRecordChecked}
                stopSpeechToText={stopSpeechToText}
                setStartVoiceRecognition={setStartVoiceRecognition}
                startVoiceRecognition={startVoiceRecognition}
                voiceRecoError={error}
                setStartRecordIcon={setStartRecordIcon}
                // setVoiceRecoError={setVoiceRecoError}
              />
            </div>
          </ScriptScrollBtn>
          <div id="voiceRecoMedia">
            {error ? (
              <div id="voiceRecoMedia1">
                <Alert severity="error" data-aos="fade-left">
                  <p className="text-white">
                    Media Device is not found!{" "}
                    <span
                      className="mediaDevicesErrorIcon"
                      onClick={() => hideVoiceErrorController()}
                    >
                      <i class="fa fa-times"></i>
                    </span>{" "}
                  </p>
                </Alert>
              </div>
            ) : null}
          </div>
          <div className="script-scroll-heading bg-dark" id="scroll__header">
            <h5>{title}</h5>
          </div>
          <div
            onClick={() =>
              footerPenalAction
                ? manuallyScrollAction
                  ? stopManualPageScrollController(
                      setTopToBottomAction,
                      timers,
                      setPlayAction,
                      setManuallyScrollAction
                      // reset
                    )
                  : topToBottomAction
                  ? startBottomToTopScroll()
                  : startManualPageScrollController(
                      setTopToBottomAction,
                      scrollLoop,
                      verticalYOffset,
                      timers,
                      testRange,
                      setTimers,
                      setPlayAction,
                      txtViewContentHeight,
                      setManuallyScrollAction
                      // start
                    )
                : stopManualPageScrollController(
                    setTopToBottomAction,
                    timers,
                    setPlayAction,
                    setManuallyScrollAction
                    // reset
                  )
            }
            className="p-5 main-prompt-page-outer"
            id="contentDiv"
            style={{
              minHeight: "100vh",
              maxWidth: "100vw",
              transform: scrMirror === true ? "scale(-1, 1)" : "scale(1, 1)",

              fontSize: promptTextSize + "px",
              overflowX: "auto",
            }}
          >
            <p
              style={{
                padding: textMargin + "%",
                // color: scrColor,
              }}
              id="scroll__content"
              onFocus={() => disableEditController()}
              dangerouslySetInnerHTML={{
                __html: attrTxt && attrTxt.split("#000000").join("#fff"),
              }}
            ></p>
          </div>
          <div
            className="p-5 main-prompt-page-hidden"
            id="contentDiv"
            style={{
              minHeight: "100vh",
              maxWidth: "100vw",
              transform: scrMirror === true ? "scale(-1, 1)" : "scale(1, 1)",

              fontSize: promptTextSize + "px",
              overflowX: "auto",
            }}
          >
            <p
              style={{
                padding: textMargin + "%",
                // color: scrColor,
              }}
              id="scroll__content_hidden"
              onFocus={() => disableEditController()}
              dangerouslySetInnerHTML={{
                __html:
                  attrTxtHidden && attrTxtHidden.split("#000000").join("#fff"),
              }}
            ></p>
          </div>
        </div>
        <ToastContainer />
      </LoadingOverlay>
    </div>
  );
}

const ScriptScrollBtn = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  width: 100%;
`;

const BackgroundVideo = styled.div`
  position: fixed;
  z-index: -1;
  height: 100%;
  width: 100vw;
  background-color: #333;
`;

export default PromptPageComponent;
