import React from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
export const Dragprom = () => {
  let scrAttrText = localStorage.getItem("attrTxt");
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="center-content-model mt-3 mb-3">
              <p
                // id="scroll-div"
                style={{ paddingBottom: "200px", position: "absolute" }}
                dangerouslySetInnerHTML={{
                  __html:
                    scrAttrText && scrAttrText.split("font-size").join(" "),
                }}
              ></p>
              <p
                id="scroll-div"
                style={{
                  paddingBottom: "200px",
                  position: "absolute",
                  color: "transparent",
                  zIndex: "-999",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    scrAttrText &&
                    scrAttrText.length > 0 &&
                    scrAttrText.toLowerCase().split("font-size").join(" "),
                }}
              ></p>
              <div id="default-div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
