import { folderScriptConstants } from "../actions/constants";
const initialState = {
  showSingleFolderScripts: null,
  msg: null,
  loading: false,
};

export default (state = initialState, action) => {
  // console.log(action);
  // console.log()
  switch (action.type) {
    case folderScriptConstants.FOLDER_RELOAD_REQUEST:
      state = {
        ...state,
        authenticating: true,
      };
      break;
    case folderScriptConstants.FOLDER_RELOAD_SUCCESS:
      state = {
        ...state,
        showSingleFolderScripts: action.payload.folderScriptData,
        msg: action.payload.msg,
        loading: action.payload.loading,
      };
      break;

    default:
  }

  return state;
};
