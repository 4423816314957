import React, { useState } from "react";

import VideoRecording from "../VideoRecord/VideoRecording";
import "./scroll.css";

import StartVideoRecordIcon from "../../images/startRecording.png";

import StartAudioRecordings from "../AudioRecord/StartAudioRecordings";
import {
  startManualPageScrollController,
  stopManualPageScrollController,
} from "../../utils/ManuallyPageScrollController/manuallyPageScrollController";

// import Dictaphone from "./Dictaphone";
import VoiceRecoginationComponent from "./VoiceRecogination/voiceRecognition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFont,
  faTachometerAlt,
  faCamera,
  faMicrophone,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import { testRange } from "../Range/Seek";

import "react-toastify/dist/ReactToastify.css";
import "video-react/dist/video-react.css";
import { startVideoRecordingController } from "../VideoRecord/Video&AudioRecordingController/StartVideoRecordingController";
import { stopGreenLightController } from "../../utils/VideoGreenLightDisable/VideoGreenLightDisable";
import { scrollSpeedAction } from "../../services/actions";
import { useDispatch, useSelector } from "react-redux";
import { IOSSlider } from "../../utils/slider";
function ScrollingFooter(props) {
  //props data
  const {
    videoToggle,
    setRecordIcon,
    // stopAudioRecordingFun,
    recordStartIcon,
    setStartRecordIcon,
    isRecording,
    startRecording,
    setStream,
    setRecorder,
    startVideoRecordIcon,
    setStartVideoRecordIcon,
    stopVideoRecording,
    audioRecordChecked,
    setAudioRecordChecked,
    startVoiceRecognition,
    setStartVoiceRecognition,
    setTimers,
    timers,
    scrInvert,
    scrMirror,
    setTopToBottomAction,
    topToBottomAction,
    setTextMargin,
    setPromptTextSize,
    updatePromptScript,
    handleChangeThumb,
    micHandleChange,
    results,
    startSpeechToText,
    stopSpeechToText,
    promptTextSize,
    textMargin,
    mirror,
    invert,
    micRecognitionHandleChange,
    stopAudioRecordingFun,
    recordChecked,
    stopCamera,
    setThumbnailChecked,
    setChecked,
    stopVideo,
    setRecordChecked,
    setStopAudioRecording,
    handleChangeFullScreen,

    setPlayAction,
    setManuallyScrollAction,
    start,
    txtViewContentHeight,
    setFooterPenalAction,
    startBottomToTopScroll,
    startVideo,
    setVideoStop,
    setLoader,
  } = props;

  const { scrollValue } = useSelector((state) => state.scrollValue);
  const dispatch = useDispatch();

  const [fontTab, setFontTab] = useState(false);
  const [voiceRecotab, setVoiceRecotab] = useState(false);
  const [videoTab, setVideoTab] = useState(false);
  const [audioTab, setAudioTab] = useState(false);
  const [isClass, setIsClass] = useState(false);

  let scrollLoop = [];
  var verticalYOffset;

  const startScroll = () => {
    localStorage.setItem("playPause", false);

    startManualPageScrollController(
      setTopToBottomAction,
      scrollLoop,
      verticalYOffset,
      timers,
      testRange,
      setTimers,
      setPlayAction,
      txtViewContentHeight,
      setManuallyScrollAction
    );
    props.start();
    setFooterPenalAction(true);
  };

  //start video recording
  const startVideoRecording = async (e) => {
    setLoader(true);
    await stopGreenLightController(stopCamera);
    props.start();
    await startVideoRecordingController(
      setStream,
      setRecorder,
      setVideoStop,
      setThumbnailChecked,
      setStartRecordIcon,
      startVideo
    );

    if (scrInvert === true) {
      startBottomToTopScroll();
    } else {
      startManualPageScrollController(
        setTopToBottomAction,

        scrollLoop,
        verticalYOffset,
        timers,
        testRange,
        setTimers,
        setPlayAction,
        txtViewContentHeight,
        setManuallyScrollAction
      );
    }
    setTimeout(() => {
      setLoader(false);
    }, 600);
  };

  //content right

  const changeScriptRight = () => {
    document.getElementById("contentDiv").style.textAlign = "right";
    // document.getElementById("contentDiv").style.marginLeft = "15%";
  };
  //content center

  const changeScriptCenter = () => {
    document.getElementById("contentDiv").style.textAlign = "center";
  };
  //content left

  const changeScriptLeft = () => {
    document.getElementById("contentDiv").style.textAlign = "left";
  };

  return (
    <>
      <div className="bg-dark scroll">
        <div>
          <div className="tab_container mt-3">
            <div className="row bg-dark">
              <div className="col-lg-6 text-left">
                <div
                  className="justify-content-start d-flex text-white pl-3"
                  onClick={() => {
                    stopManualPageScrollController(
                      setTopToBottomAction,
                      timers,
                      setPlayAction,
                      setManuallyScrollAction
                    );
                  }}
                ></div>
              </div>

              <div className="col-lg-6 text-right">
                <div className="float-right text-white ">
                  <input
                    className="input-custom"
                    id="toggle"
                    type="radio"
                    name="tabs"
                  />
                  <label
                    htmlFor="toggle"
                    className="mr-5"
                    onClick={() => {
                      updatePromptScript();

                      setFontTab(false);
                      setVoiceRecotab(false);
                      setVideoTab(false);
                      setAudioTab(false);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                      className="toggle-footer-bottom"
                    />

                    <span></span>
                  </label>
                </div>
              </div>
            </div>
            <input
              className="input-custom"
              id="tab1"
              type="radio"
              name="tabs"
            />
            <label
              htmlFor="tab1"
              className={
                isClass == "faFont"
                  ? "edit-tab scroll-tab-page tab_active"
                  : "edit-tab scroll-tab-page"
              }
              onClick={() => {
                setFontTab(true);
                setVoiceRecotab(false);
                setVideoTab(false);
                setAudioTab(false);
                setIsClass("faFont");
              }}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faFont} /> +
              <span></span>
            </label>
            <input
              className="input-custom"
              id="tab2"
              type="radio"
              name="tabs"
            />
            <label
              htmlFor="tab2"
              className={
                isClass == "faTachometerAlt"
                  ? "edit-tab scroll-tab-page tab_active"
                  : "edit-tab scroll-tab-page"
              }
              onClick={() => {
                setFontTab(false);
                setVoiceRecotab(true);
                setVideoTab(false);
                setAudioTab(false);
                setIsClass("faTachometerAlt");
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faTachometerAlt}
                className={isClass == "faTachometerAlt" ? "tab_active" : ""}
              />
              <span></span>
            </label>

            {startVoiceRecognition ? (
              <VoiceRecoginationComponent
                startVoiceRecognition={startVoiceRecognition}
                setStartVoiceRecognition={setStartVoiceRecognition}
                results={results}
                startSpeechToText={startSpeechToText}
                stopSpeechToText={stopSpeechToText}
                recordChecked={recordChecked}
                setStream={setStream}
                setRecorder={setRecorder}
                setVideoStop={setVideoStop}
                setThumbnailChecked={setThumbnailChecked}
                setStartRecordIcon={setStartRecordIcon}
                startVideo={startVideo}
                audioRecordChecked={audioRecordChecked}
                startTimer={props.start}
              />
            ) : (
              <>
                {startVideoRecordIcon ? (
                  props.playAction ? (
                    <>
                      <input
                        className="input-custom"
                        id="pause"
                        type="radio"
                        name="pause"
                      />

                      <label
                        htmlFor="pause"
                        className="edit-tab scroll-tab-page record-icon "
                      >
                        {recordStartIcon ? (
                          <>
                            <StartAudioRecordings
                              className="start-audio-recording"
                              startScroll={startScroll}
                              setStartRecordIcon={setStartRecordIcon}
                              setStopAudioRecording={setStopAudioRecording}
                              startRecording={startRecording}
                              isRecording={isRecording}
                              setRecordChecked={setRecordChecked}
                              setRecordIcon={setRecordIcon}
                              onClick={() => stopAudioRecordingFun()}
                              scrInvert={scrInvert}
                              startBottomToTopScroll={startBottomToTopScroll}
                            />
                          </>
                        ) : (
                          <div className="record-icon">
                            {/* startBottomToTopScroll */}

                            {scrInvert === true ? (
                              <>
                                {audioRecordChecked === true ? (
                                  <StartAudioRecordings
                                    className="start-audio-recording"
                                    startScroll={startScroll}
                                    setStartRecordIcon={setStartRecordIcon}
                                    setStopAudioRecording={
                                      setStopAudioRecording
                                    }
                                    startRecording={startRecording}
                                    isRecording={isRecording}
                                    setRecordChecked={setRecordChecked}
                                    setRecordIcon={setRecordIcon}
                                    onClick={() => stopAudioRecordingFun()}
                                    scrInvert={scrInvert}
                                    startBottomToTopScroll={
                                      startBottomToTopScroll
                                    }
                                  />
                                ) : (
                                  <div>
                                    <img
                                      src="assets/images/play.svg"
                                      className="play-icon"
                                      onClick={() => {
                                        stopManualPageScrollController(
                                          setTopToBottomAction,
                                          timers,
                                          setPlayAction,
                                          setManuallyScrollAction
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {audioRecordChecked === true ? (
                                  <StartAudioRecordings
                                    className="start-audio-recording"
                                    startScroll={startScroll}
                                    setStartRecordIcon={setStartRecordIcon}
                                    setStopAudioRecording={
                                      setStopAudioRecording
                                    }
                                    startRecording={startRecording}
                                    isRecording={isRecording}
                                    setRecordChecked={setRecordChecked}
                                    setRecordIcon={setRecordIcon}
                                    onClick={() => stopAudioRecordingFun()}
                                    scrInvert={scrInvert}
                                    startBottomToTopScroll={
                                      startBottomToTopScroll
                                    }
                                  />
                                ) : (
                                  <img
                                    src="assets/images/play.svg"
                                    className="play-icon"
                                    onClick={() => {
                                      clearTimeout();
                                      startManualPageScrollController(
                                        setTopToBottomAction,

                                        scrollLoop,
                                        verticalYOffset,
                                        timers,
                                        testRange,
                                        setTimers,
                                        setPlayAction,
                                        txtViewContentHeight,
                                        setManuallyScrollAction
                                      );
                                      start();
                                      setFooterPenalAction(true);
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}

                        <span></span>
                      </label>
                    </>
                  ) : (
                    <>
                      {recordStartIcon ? (
                        <>
                          <input
                            className="input-custom"
                            id="play"
                            type="radio"
                            name="play"
                          />
                          {props.stopAudioRecording ? null : (
                            <>
                              {" "}
                              <label
                                htmlFor="play"
                                className="edit-tab scroll-tab-page"
                              >
                                <div>
                                  <StartAudioRecordings
                                    className="start-audio-recording"
                                    startScroll={startScroll}
                                    setStartRecordIcon={setStartRecordIcon}
                                    setStopAudioRecording={
                                      props.setStopAudioRecording
                                    }
                                    startRecording={startRecording}
                                    isRecording={isRecording}
                                    scrInvert={scrInvert}
                                    startBottomToTopScroll={
                                      startBottomToTopScroll
                                    }
                                  />
                                </div>

                                <span></span>
                              </label>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {topToBottomAction ? (
                            <>
                              <input
                                className="input-custom"
                                id="play"
                                type="radio"
                                name="play"
                              />
                              <label
                                htmlFor="play"
                                className="edit-tab scroll-tab-page"
                              >
                                <div className="record-icon">
                                  {audioRecordChecked === true ? (
                                    <StartAudioRecordings
                                      className="start-audio-recording"
                                      startScroll={startScroll}
                                      setStartRecordIcon={setStartRecordIcon}
                                      setStopAudioRecording={
                                        setStopAudioRecording
                                      }
                                      startRecording={startRecording}
                                      isRecording={isRecording}
                                      setRecordChecked={setRecordChecked}
                                      setRecordIcon={setRecordIcon}
                                      onClick={() => stopAudioRecordingFun()}
                                      scrInvert={scrInvert}
                                      startBottomToTopScroll={
                                        startBottomToTopScroll
                                      }
                                    />
                                  ) : (
                                    <div>
                                      <img
                                        src="assets/images/play.svg"
                                        className="play-icon"
                                        onClick={() => {
                                          clearTimeout();
                                          startBottomToTopScroll();
                                          setFooterPenalAction(true);
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>

                                <span></span>
                              </label>
                            </>
                          ) : (
                            <>
                              <input
                                className="input-custom"
                                id="play"
                                type="radio"
                                name="play"
                              />
                              <label
                                htmlFor="play"
                                className="edit-tab scroll-tab-page"
                              >
                                <div className="record-icon">
                                  {scrInvert === true ? (
                                    <>
                                      <img
                                        src="assets/images/play.svg"
                                        className="play-icon"
                                        onClick={() => {
                                          // stopManualPageScrollController(
                                          //   setTopToBottomAction,
                                          //   timers,
                                          //   setPlayAction,
                                          //   setManuallyScrollAction
                                          // );
                                          clearTimeout();
                                          startBottomToTopScroll();
                                          setFooterPenalAction(true);
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {audioRecordChecked === true ? (
                                        <StartAudioRecordings
                                          className="start-audio-recording"
                                          startScroll={startScroll}
                                          setStartRecordIcon={
                                            setStartRecordIcon
                                          }
                                          setStopAudioRecording={
                                            setStopAudioRecording
                                          }
                                          startRecording={startRecording}
                                          isRecording={isRecording}
                                          setRecordChecked={setRecordChecked}
                                          setRecordIcon={setRecordIcon}
                                          onClick={() =>
                                            stopAudioRecordingFun()
                                          }
                                          scrInvert={scrInvert}
                                          startBottomToTopScroll={
                                            startBottomToTopScroll
                                          }
                                        />
                                      ) : (
                                        <div>
                                          <img
                                            src="assets/images/play.svg"
                                            className="play-icon"
                                            onClick={() => {
                                              clearTimeout();
                                              startManualPageScrollController(
                                                setTopToBottomAction,

                                                scrollLoop,
                                                verticalYOffset,
                                                timers,
                                                testRange,
                                                setTimers,
                                                setPlayAction,
                                                txtViewContentHeight,
                                                setManuallyScrollAction
                                              );
                                              start();
                                              setFooterPenalAction(true);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}{" "}
                                </div>

                                <span></span>
                              </label>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )
                ) : (
                  <>
                    <div>
                      {" "}
                      <input
                        className="input-custom"
                        id="play"
                        type="radio"
                        name="play"
                      />
                      <label
                        htmlFor="play"
                        // className="edit-tab scroll-tab-page start-video"
                        className="edit-tab scroll-tab-page"
                        onClick={() => startVideoRecording()}
                      >
                        <div className="record-icon">
                          <img
                            className="video-record-icon"
                            src={StartVideoRecordIcon}
                            alt="start video recording"
                          />
                        </div>
                        <span></span>
                      </label>{" "}
                    </div>
                  </>
                )}
              </>
            )}

            {/* video Recording  */}
            <input
              className="input-custom"
              id="tab4"
              type="radio"
              name="tabs"
            />
            <label
              htmlFor="tab4"
              className={
                isClass == "faCamera"
                  ? "edit-tab scroll-tab-page tab_active"
                  : "edit-tab scroll-tab-page"
              }
              onClick={() => {
                setFontTab(false);
                setVoiceRecotab(false);
                setVideoTab(true);
                setAudioTab(false);
                setIsClass("faCamera");
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faCamera}
                className={isClass == "faCamera" ? "tab_active" : ""}
              />
              <span></span>
            </label>
            <input
              className="input-custom"
              id="tab5"
              type="radio"
              name="tabs"
            />
            <label
              htmlFor="tab5"
              className={
                isClass == "faMicrophone"
                  ? "edit-tab scroll-tab-page tab_active"
                  : "edit-tab scroll-tab-page"
              }
              onClick={() => {
                setFontTab(false);
                setVoiceRecotab(false);
                setVideoTab(false);
                setIsClass("faMicrophone");

                setAudioTab(true);
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faMicrophone}
                className={isClass == "faMicrophone" ? "tab_active" : ""}
              />
              <span></span>
            </label>
            {fontTab ? (
              <section id="content1" className="tab-content custom-tab-content">
                <div className="row">
                  <div className="text-option">
                    <p className="m-0">Text</p>
                  </div>
                </div>
                <hr className="line-text-bottom"></hr>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4 text-left">
                    <button
                      className="edit-btn"
                      id="align-left"
                      title="Left"
                      onClick={changeScriptLeft}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faAlignLeft}
                      />
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4 text-center">
                    <button
                      className="edit-btn"
                      id="align-center"
                      title="Center"
                      onClick={changeScriptCenter}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faAlignCenter}
                      />
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4 text-right">
                    <button
                      className="edit-btn"
                      id="align-right"
                      title="Right"
                      onClick={changeScriptRight}
                    >
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faAlignRight}
                      />
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="font-range inc-font w-100">
                    <div className="col-12 d-flex">
                      <span className="min-font">A</span>
                      <span className="input-range-center">
                        {/* <input
                        className="range-input"
                        type="range"
                        id="autoFont"
                        min="20"
                        max="60"
                        step="any"
                        value={promptTextSize}
                        onChange={(e) => setPromptTextSize(e.target.value)}
                      /> */}

                        <IOSSlider
                          className="range-input"
                          aria-label="ios slider"
                          id="autoFont"
                          value={promptTextSize}
                          onChange={(e) => setPromptTextSize(e.target.value)}
                          min={20}
                          max={60}
                          step={0.01}
                        />
                      </span>
                      <span className="hight-font">A</span>
                    </div>
                  </div>
                </div>

                {/* new  */}
                <div className="row">
                  <div className="font-range inc-font w-100">
                    {/* <div className="col-1"></div> */}
                    <div className="col-12 d-flex">
                      <span className="margin_custom">Margin</span>
                      <IOSSlider
                        style={{ marginTop: "12px" }}
                        className="range-input"
                        aria-label="ios slider"
                        id="script__margin"
                        value={textMargin}
                        onChange={(e) => setTextMargin(e.target.value)}
                        min={0}
                        max={30}
                        step={0.01}
                      />
                    </div>
                    {/* <div className="col-1">hhhhh</div> */}
                  </div>
                </div>
                {/* <ToolBarCheckbox /> */}
                <div className="row pb-3">
                  <div className="col-lg-6 p-0">
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">Mirror</span>
                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch"
                          checked={scrMirror}
                          onChange={mirror}
                        />
                        <label className="stch" htmlFor="switch"></label>
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 p-0">
                    {/* <button title="Center" onClick={reserve}> */}
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">Invert</span>

                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch1"
                          checked={scrInvert}
                          onChange={invert}
                          //
                        />
                        <label
                          name="switch1"
                          className="stch"
                          htmlFor="switch1"

                          // onClick={reserve}
                        ></label>
                      </span>
                    </div>
                    {/* </button> */}
                  </div>
                </div>
              </section>
            ) : null}
            {voiceRecotab ? (
              <section id="content2" className="tab-content custom-tab-content">
                <div className="row">
                  <div className="text-option">
                    <p>Scrolling</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">
                        Speech Recognition
                      </span>
                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch8"
                          checked={startVoiceRecognition}
                          // onClick={mic}
                          onChange={micRecognitionHandleChange}
                        />
                        <label className="stch" htmlFor="switch8"></label>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="font-range inc-font w-100">
                    <div className="col-12 d-flex">
                      <span className="running_left">
                        <img
                          src="assets/images/running.svg"
                          alt=""
                          srcset=""
                          className="running_icon"
                        />
                      </span>
                      <span className="running-slider">
                        <IOSSlider
                          className="range-margin speed"
                          aria-label="ios slider"
                          id="script__margin"
                          // valueLabelDisplay="on"
                          value={scrollValue}
                          min={1}
                          max={3}
                          onChange={(e) =>
                            dispatch(scrollSpeedAction(e.target.value))
                          }
                          step={0.01}
                        />
                      </span>
                      <span className="running_right">
                        <img
                          src="assets/images/running_fast.svg"
                          alt=""
                          srcset=""
                          className="running_icon_fast"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            <section
              id="content3"
              className="tab-content custom-tab-content"
            ></section>

            {videoTab ? (
              <section id="content4" className="tab-content custom-tab-content">
                <VideoRecording
                  stopVideo={stopVideo}
                  setChecked={setChecked}
                  setThumbnailChecked={setThumbnailChecked}
                  stopCamera={stopCamera}
                  setStartVideoRecordIcon={setStartVideoRecordIcon}
                  recordChecked={recordChecked}
                  stopVideoRecording={stopVideoRecording}
                  audioRecordChecked={audioRecordChecked}
                  setAudioRecordChecked={setAudioRecordChecked}
                  setRecordIcon={setRecordIcon}
                  videoToggle={videoToggle}
                />
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">
                        Show me full screen
                      </span>
                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch5"
                          checked={props.fullScreenChecked}
                          onChange={handleChangeFullScreen}
                        />
                        <label className="stch" htmlFor="switch5"></label>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">
                        Show me thumbnail
                      </span>
                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch6"
                          checked={props.thumbnailChecked}
                          onChange={handleChangeThumb}
                          // onClick={props.startVideo()}
                        />
                        <label className="stch" htmlFor="switch6"></label>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
            {audioTab ? (
              <section id="content5" className="tab-content custom-tab-content">
                <div className="row">
                  <div className="text-option">
                    <p>Audio</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="switch-cl inc-font">
                      <span className="Mirror text-left">Record me</span>
                      <span className="toggle-btn text-right">
                        <input
                          type="checkbox"
                          id="switch7"
                          checked={audioRecordChecked}
                          // onClick={mic}
                          onChange={micHandleChange}
                        />
                        <label className="stch" htmlFor="switch7"></label>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </div>
        </div>
        <ToastContainer autoClose={5000} />
      </div>
    </>
  );
}

export default ScrollingFooter;
