export const removeUnwantedTextController = async (text) => {
  let a1 = text && text.split("p.p1").join("");
  let a2 = a1 && a1.split("p.p2").join(" ");
  let a3 = a2 && a2.split("span.s1").join("");
  let a4 = a3 && a3.split("span.s2").join("");
  let a5 = a4 && a4.split("• ").join("");

  let a8 = a5 && a5.split(":)").join("");

  let a9 = a8 && a8.split("[").join("");
  let a10 = a9 && a9.split("]").join("");
  let a11 = a10 && a10.split("'s").join("");
  let a12 = a11 && a11.split("'").join("");
  let a13 = a12 && a12.split(`"`).join("");
  let a14 = a13 && a13.split("!").join("");
  let a15 = a14 && a14.split(",").join("");
  let a16 = a15 && a15.split("!!").join("");
  let a17 = a16 && a16.split("&").join("and");

  let a18 = a17.toLocaleLowerCase();
  return a18;
};

export const removeUnwantedAttributedTextController = (text) => {
  let a1 = text && text.split("p.p1").join("");
  let a2 = a1 && a1.split("p.p2").join(" ");
  let a3 = a2 && a2.split("span.s1").join("");
  let a4 = a3 && a3.split("span.s2").join("");
  let a5 = a4 && a4.split("• ").join("");
  let a6 = a5 && a6.split("rgb(255, 255, 255)").join("#000");
  let a7 = a6 && a7.split("rgb(250, 250, 250)").join("#000");
  let a8 = a7 && a8.split(":)").join("");

  let attrTxt = a8 && a8;

  return attrTxt;
};
