import React, { useState } from "react";
import LookUpTabs from "./LookUpTabs";
import Definations from "./Definations";
import "./Dictionary.css";
import axios from "axios";
import Synonym from "./Synonym";

import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { getDefinationsAndSynonymController } from "../../utils/LookupController";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  color: "#000000";
  css: "";
  size: 10;
`;

function LookUp({ setDictToggle }) {
  const [definitions, setDefinitions] = useState([]);
  const [synonym, setSynonym] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [dicNotFound, setDicNotFound] = useState(false);

  let [loading, setLoading] = useState(false);

  let lookUpController = {
    searchInput,
    setSynonym,
    setDefinitions,
    setLoading,

    setDicNotFound,
  };

  const getDefinations = async () => {
    const searchInVal = searchInput.toLowerCase();
    setLoading(true);

    await axios
      .get(
        `https://api.wordnik.com/v4/word.json/${searchInVal}/definitions?limit=200&includeRelated=false&useCanonical=false&includeTags=false&api_key=iu3u43eaowjq42uwdd19e1eygpcp71s8pbhc3kf513mccwna2`
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setDefinitions(data);
          setLoading(false);
          setDicNotFound(false);

          getSynonym();
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setDicNotFound(true);
        setDefinitions([]);
        setSynonym([]);
        setLoading(false);
      });
    setLoading(false);
  };

  const getSynonym = async () => {
    const searchInVal = searchInput.toLowerCase();
    setLoading(true);

    await axios
      .get(
        `https://api.wordnik.com/v4/word.json/${searchInVal}/relatedWords?useCanonical=false&relationshipTypes=synonym&limitPerRelationshipType=10&api_key=iu3u43eaowjq42uwdd19e1eygpcp71s8pbhc3kf513mccwna2`
      )
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setSynonym(data);
          setLoading(false);
          setDicNotFound(false);
        } else {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        setDicNotFound(true);

        setSynonym([]);
        setLoading(false);
      });
    setLoading(false);
  };

  const inputChange = (e) => {
    var txt = e.target.value;
    setSearchInput(txt);
    if (txt === "") {
      setDefinitions([]);
      setSynonym([]);
      setDicNotFound(false);
    }
  };
  return (
    <div className="look-up">
      <div className="inner-lookup p-3">
        <div className="close-lookup" onClick={() => setDictToggle(false)}>
          <FontAwesomeIcon icon={faTimes} className="" />
        </div>
        <div className="text-option d-flex">
          <p>Look Up</p>
        </div>
        <div className="row ">
          <div className="col-lg-12 mt-1">
            <LookUpTabs />
          </div>
        </div>

        <div className="row ">
          <div className="col-lg-12">
            <div className="tab-content">
              <div id="Dictionary" className=" tab-pane active">
                <div id="custom-search-input">
                  <div className="input-group col-md-12 p-0">
                    <BeatLoader loading={loading} css={override} size={10} />
                    <input
                      type="text"
                      className="form-control input-lg custom-search-tab"
                      placeholder="Search..."
                      onChange={(e) => inputChange(e)}
                      onKeyPress={(e) =>
                        e.key === "Enter" ? getDefinations() : null
                      }
                      value={searchInput}
                    />

                    <p
                      className="search-cancel"
                      onClick={() => setDefinitions([])}
                    >
                      Cancel
                    </p>
                    <span
                      className="sb-icon-search-1 mt-3"
                      onClick={() =>
                        getDefinationsAndSynonymController(lookUpController)
                      }
                    >
                      <img
                        src="assets/images/serach-icon.png"
                        alt=""
                        width="18px"
                      />
                    </span>
                  </div>
                </div>
                <Definations
                  definitions={definitions}
                  dicNotFound={dicNotFound}
                />
              </div>
              <div id="Thesaurus" className=" tab-pane">
                <div className="input-group col-md-12 p-0">
                  <BeatLoader loading={loading} css={override} size={10} />

                  <input
                    type="text"
                    className="form-control input-lg custom-search-tab"
                    placeholder="Search..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" ? getDefinations() : null
                    }
                  />
                  <p className="search-cancel" onClick={() => setSynonym([])}>
                    Cancel
                  </p>
                  <span
                    className="sb-icon-search-1 mt-3"
                    onClick={() =>
                      getDefinationsAndSynonymController(lookUpController)
                    }
                  >
                    <img
                      src="assets/images/serach-icon.png"
                      alt=""
                      width="18px"
                    />
                  </span>
                </div>
                <Synonym synonym={synonym} dicNotFound={dicNotFound} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LookUp;
