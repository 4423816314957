// get definations and synonym

import axios from "axios";

export const getDefinationsAndSynonymController = async (lookUpController) => {
  const { searchInput, setDefinitions, setLoading, setDicNotFound } =
    lookUpController;
  const searchInVal = searchInput.toLowerCase();
  setLoading(true);
  await axios
    .get(
      `https://api.wordnik.com/v4/word.json/${searchInVal}/definitions?limit=200&includeRelated=false&useCanonical=false&includeTags=false&api_key=iu3u43eaowjq42uwdd19e1eygpcp71s8pbhc3kf513mccwna2`
    )
    .then((res) => {
      if (res.status === 200) {
        const data = res.data;
        setDefinitions(data);
        setLoading(false);
        getSynonymController(lookUpController);
        setDicNotFound(false);
      } else {
        setLoading(false);
      }
      setLoading(false);
    })
    .catch((err) => {
      setDefinitions([]);
      setDicNotFound(true);

      setLoading(false);
    });
};

export const getSynonymController = async (lookUpController) => {
  const { searchInput, setSynonym, setLoading, setDicNotFound } =
    lookUpController;

  const searchInVal = searchInput.toLowerCase();

  await axios
    .get(
      `https://api.wordnik.com/v4/word.json/${searchInVal}/relatedWords?useCanonical=false&relationshipTypes=synonym&limitPerRelationshipType=10&api_key=iu3u43eaowjq42uwdd19e1eygpcp71s8pbhc3kf513mccwna2`
    )
    .then((res) => {
      if (res.status === 200) {
        const data = res.data;

        setSynonym(data);
        setLoading(false);
        setDicNotFound(false);
      } else {
        setLoading(false);
      }
    })
    .catch((err) => {
      setSynonym([]);
      setDicNotFound(true);
      setLoading(false);
    });
};
