import React from "react";
import Dialog from "@mui/material/Dialog";
import SubscriptionComponent from "../SubscriptionComponent";
import "./SubscriptionModal.css";

const SubscriptionModal = ({ foreverFreeAction }) => {
  return (
    <Dialog
      open={foreverFreeAction && foreverFreeAction}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <SubscriptionComponent />
    </Dialog>
  );
};

export default SubscriptionModal;
