import { showStoryBoardsConstants } from "./constants";

import axiosInstance from "../../helpers/axios";
import { story_board_orders } from "../../utils/ApiList";
import { toast } from "react-toastify";

export const showAllStoryBoards = (data) => {
  let user_id = localStorage.getItem("userId");
  return async (dispatch) => {
    await axiosInstance
      .post(story_board_orders, {
        user_id: user_id,
        script_id: data && data,
      })
      .then((res) => {
        let storyboards = res.data.data;

        dispatch({
          type: showStoryBoardsConstants.SHOW_STORYBOARD_SUCCESS,
          payload: {
            storyboards: storyboards,
          },
        });
      })
      .catch((error) => {
        let { response } = error;

        const { message, code } = response.data;

        if (code == 401) {
          toast.warn(message);
          setTimeout(() => {
            window.location.href = "/";
            localStorage.clear();
            //console.clear();
          }, 1000);
        } else {
          toast.error(message);
        }
      });
  };
};
