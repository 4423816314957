import React from "react";
import { IOSSlider } from "../../utils/slider";

const PlayIcon = ({ handleStopScroll }) => {
  return (
    <img
      src="assets/images/stop.png"
      onClick={() => handleStopScroll()}
      className="stop-seek-icon"
    />
  );
};

const ModalSeekBar = ({
  handleStopScroll,
  renderedStreamDuration,
  seekSpeed,
  handleSeekSpeed,
}) => {
  return (
    <div className="seek-bar">
      <div className="row">
        <div className="col-12 seek-modal d-flex justify-content-center ">
          <PlayIcon handleStopScroll={handleStopScroll} />

          <p className="footer-timer">{renderedStreamDuration}</p>

          <IOSSlider
            className=""
            aria-label="ios slider"
            // id="scriptZomm"
            valueLabelDisplay="on"
            min={0.81}
            value={seekSpeed}
            max={1.81}
            onChange={handleSeekSpeed}
            step={0.001}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalSeekBar;
