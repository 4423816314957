import React from "react";

import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ component: Component, ...rest }) {
  const globalState = useSelector((state) => state);

  return (
    <Route
      {...rest}
      render={(props) =>
        globalState.auth.authenticate ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signup" />
        )
      }
    />
  );
}

export default PrivateRoute;
