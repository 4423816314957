import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Link, useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import MetaTags from "react-meta-tags";
import ReactPixel from "react-facebook-pixel";

import {
  login,
  isUserLoggedIn,
  signup,
  subscriptionStatus,
  loaderAction,
} from "../services/actions";

import { ToastContainer, toast } from "react-toastify";
import AppleAuth from "../AppleAuth/AppleAuth";
import Recaptcha from "react-google-invisible-recaptcha";
import useFetch from "../hooks/useFetch";

const Login = () => {
  const { loader } = useSelector((state) => state.requestLoader);

  const refRecaptcha = useRef();
  const { data } = useFetch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const type = "Normal";
  const search = useLocation().search;

  useEffect(() => {
    //  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, options);

    ReactPixel.pageView(); // For tracking page view
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle("PixelId", event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom("PixelId", event, data);
  }, []);

  const planType = new URLSearchParams(search).get("plan");

  let planArr = ["monthly", "6months", "yearly"];
  let index = planArr.indexOf(planType);

  // const [error, setError] = useState("error");
  let unique_id = "";

  const auth = useSelector((state) => state.auth);
  // console.log(auth)

  const user = useSelector((state) => state.user);

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
      dispatch(subscriptionStatus());
    }
  }, []);

  //login
  const userLogin = async (e) => {
    e.preventDefault();
    dispatch(loaderAction(true));

    const user = { email, password, type, unique_id, device_type: "WEB" };

    if (!email || !password) {
      toast.error("Validation failed! Input cannot be empty.");
      refRecaptcha.current.reset();
    } else {
      refRecaptcha.current.execute();

      if (index >= 0) {
        await dispatch(login(user, planType));
      } else {
        await dispatch(login(user));
      }

      await dispatch(subscriptionStatus());
      dispatch(loaderAction(false));
    }

    dispatch(loaderAction(false));
  };

  if (auth.authenticate) {
    return <Redirect to="/" />;
  }

  const onResolved = (value) => {
    console.log(value);
  };

  return (
    <div className="login_container">
      <LoadingOverlay active={loader} spinner>
        <MetaTags>
          <title>Scriptively</title>
          <meta name="description" content="Scriptively" />
          <meta name="keywords" content="Scriptively" />
        </MetaTags>
        <div className="container-fluid login-container ">
          <div className="row">
            <div className="col-md-6 br-right">
              <div className="login-side">
                <h5>Script, Prompt, and Speak!</h5>
                <img
                  src="assets/images/scriptively-speaker.png"
                  alt="profile_img"
                  // className="img-fluid"
                />
                <h6>Become a better speaker, teacher, and leader.</h6>
              </div>
            </div>

            {/* login form */}

            <div className="col-md-6 pl-0">
              <div className="login-section">
                <div className="login-inner">
                  <div className="profile-img">
                    <img
                      src="assets/images/scriptively-logo.png"
                      alt="profile_img"
                      className="ml-4"
                    />
                  </div>
                  <div className="container login-form-content">
                    <h6>Sign in</h6>
                    <p className="text-signup">
                      Don’t have a Scriptively account?
                      {index >= 0 ? (
                        <Link
                          to={"/signup?plan=" + planType}
                          className="link_color pl-1"
                        >
                          Get Started for Free
                        </Link>
                      ) : (
                        <Link to="/signup" className="link_color pl-1">
                          Get Started for Free
                        </Link>
                      )}
                    </p>
                    <div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control custom-input"
                          name="email"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control custom-input"
                          name="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <Recaptcha
                        ref={refRecaptcha}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_API}
                        onResolved={(value) => onResolved(value)}
                      />
                      <div className="form-group">
                        <button
                          type="submit"
                          onClick={(e) => userLogin(e)}
                          className="btn btn-primary btn-lg btn-block custom-btn border-0 btn-login"
                        >
                          Sign In
                        </button>
                      </div>
                      <div className="form-group forget-password">
                        <Link to="/forgot">Forget Password</Link>
                      </div>
                    </div>
                    <div className="row">
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                      <FacebookLogin />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <GoogleLogin />
                    </div> */}
                      <div className="col-12">
                        <p>
                          Or, if you originally signed up with your Apple ID,
                          you may
                        </p>
                        <AppleAuth />
                        <p className="pt-4">
                          By clicking ‘Sign In’ or ‘Sign in with Apple’ you
                          agree to Scriptively’s{" "}
                          <a
                            href="https://scriptively.com/tou/"
                            target="_blank"
                            className="link_color terms"
                          >
                            Terms of Service
                          </a>{" "}
                          and
                          <a
                            href="https://scriptively.com/privacy/"
                            target="_blank"
                            className="link_color terms pl-1"
                          >
                            Privacy Policy.
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
        <ToastContainer className="alert-msg" autoClose={5000} />
        {/* </div> */}
      </LoadingOverlay>
    </div>
  );
};

export default Login;

function ProgressPage({ user }) {
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(login(user));

    if (auth.authenticate) {
      return <Redirect to="/" />;
    }
  }, []);

  return <div>Please wait...</div>;
}
