export const disableCameraGreenLightController = async (
  thumbnailChecked,
  fullScreenChecked,
  videoRecordMe,
  stopCamera
) => {
  if (
    thumbnailChecked === true &&
    fullScreenChecked === false &&
    videoRecordMe === true
  ) {
    if (stopCamera === null) {
      return false;
    } else {
      stopCamera &&
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
    }
  } else if (
    thumbnailChecked === false &&
    fullScreenChecked === false &&
    videoRecordMe === true
  ) {
    if (stopCamera === null) {
      return false;
    } else {
      stopCamera &&
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
    }
  } else if (
    thumbnailChecked === false &&
    fullScreenChecked === false &&
    videoRecordMe === false
  ) {
    if (stopCamera === null) {
      return false;
    } else {
      stopCamera &&
        stopCamera.getTracks().forEach((track) => {
          track.stop();
        });
    }
  }
};

export const stopGreenLightController = (stopCamera) => {
  if (stopCamera === null) {
    return false;
  } else {
    stopCamera &&
      stopCamera.getTracks().forEach((track) => {
        track.stop();
      });
  }
};
