import axios from "axios";
import { toast } from "react-toastify";
import { toastOptions } from "./ToastAlert";
import {
  add_story_board,
  delete_story_board,
  show_folders,
  show_story_board,
  add_script_under_folder,
  add_recent_script,
  show_recent_scripts,
  delete_recent_scripts,
  show_scripts,
  set_story_board_orders,
} from "./ApiList";
// show all folders
export const showAllFolderController = async (setShowFolders) => {
  try {
    return await axios
      .post(`${show_folders}`, {
        user_id: localStorage.getItem("userId"),
      })
      .then(function (response) {
        const arr = response.data.data;

        setShowFolders(arr);
      })
      .catch(function (error) {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
};

// current select script
export const selectScriptController = async () => {};

// current selected script story boards
export const showStoryBoardController = async (storyData) => {
  let { script_tab, setStb, setStoryBoard2, setLoader } = storyData;
  try {
    await axios
      .post(`${show_story_board}`, {
        user_id: localStorage.getItem("userId"),
        script_id: script_tab,
      })
      .then((res) => {
        // console.log(res, "storay board");

        setStb(res.data.data);
        setStoryBoard2(res.data.data);
        // setLoader(false);
      });
  } catch (error) {
    setLoader(false);

    let { response } = error;

    const { message, code } = response.data;

    if (code == 401) {
      // toast.warn(message);
      setTimeout(() => {
        window.location.href = "/";
        localStorage.clear();
        //console.clear();
      }, 1000);
    } else {
      // toast.error(message);
    }
  }
};

// add recent scripts

// save selected script into the localStorage

export const saveScriptLocalStorageController = async (data) => {
  const scrInvert = data.scrInvert === null ? "" : data.scrInvert;
  const scrMirror = data.scrMirror === null ? "" : data.scrMirror;
  const scrAlignment =
    data.scr_alignment === null
      ? "left"
      : data.scr_alignment || data.scrAlignment === null
      ? "left"
      : data.scrAlignment === "";

  let scriptLocalData = {
    user_id: localStorage.getItem("userId"),
    script_id: data.id || data.scriptId,
    scrTitle: data.t || data.scrTitle,
    scrText: data.txt || data.scrText,
    scrAttrText: data.attr_text || data.scrAttrText,
    uuid: data.scr_uuid || data.uuid,
    scrAlignment: scrAlignment,
    scrInvert: scrInvert,
    scrMirror: scrMirror,
    textMargin: data.scr_text_margin || data.textMargin,
    scrEditTextSize: data.scr_text_size || data.scrEditTextSize,
    scrPromptTextSize: data.scr_prompt_size || data.scrPromptTextSize,
    scrShowMeThumbnail: data.scr_show_me_thumbnail || data.scrShowMeThumbnail,
    scrShowMeFullScreen:
      data.scr_show_me_full_screen || data.scrShowMeFullScreen,
    scrCameraRecordMe: data.scr_camera_record || data.scrCameraRecordMe,
    scrVoiceRecordMe: data.scr_voice_record_me || data.scrVoiceRecordMe,
    scrManualScrolling: data.scr_manual_scroll || data.scrManualScrolling,
    scrColor: data.scr_color || data.scrColor,
  };
  localStorage.setItem("scriptLocalData", JSON.stringify(scriptLocalData));

  // console.log(JSON.parse(localStorage.getItem("scriptLocalData", "asdfb")));
  // localStorage.setItem("t", data.t || data.scrTitle);
  // localStorage.setItem("uuid", data.scr_uuid || data.uuid);
  // localStorage.setItem("scriptId", data.id || data.scriptId);
  // localStorage.setItem("title", data.t || data.scrTitle);
  // localStorage.setItem("attrTxt", data.attr_text || data.scrAttrText);
  // localStorage.setItem("scriptText", data.txt);
};

// add new story board
export const addNewStoryBoardController = async (boardData, storyboardFun) => {
  const {
    currentScriptId,
    story_board_name,
    story_board_text,
    storyBoardArr,
    setLoader,
  } = boardData;
  setLoader(true);

  if (story_board_name === "") {
    toast.warn("Please enter the storyboard name.");
    setLoader(false);
  } else {
    try {
      return await axios
        .post(`${add_story_board}`, {
          user_id: localStorage.getItem("userId"),
          script_id: currentScriptId,
          storyboardName: story_board_name,
          storyboardText: story_board_text,
          storyboardAttrText: story_board_text,
          draggableId: "",
        })
        .then(async (response) => {
          let arr = storyBoardArr && storyBoardArr;

          arr && arr.push(response.data.data);

          let x = [];

          arr &&
            arr.forEach((i) => {
              x.push(i.id);
            });

          await axios.post(set_story_board_orders, {
            user_id: localStorage.getItem("userId"),
            script_id: currentScriptId,
            order: x.toString(),
          });

          const msg = response.data.message;

          if (msg === "storyboard Successfully Added") {
            storyboardFun(currentScriptId);

            document.getElementById("desTitle").value = "";
            document.getElementById("boardDescription").value = "";
          }
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);

      let { response } = error;

      const { message, code } = response.data;

      if (code == 401) {
        toast.warn(message);
        setTimeout(() => {
          window.location.href = "/";
          localStorage.clear();
          //console.clear();
        }, 1000);
      } else {
        toast.error(message);
      }
    }
  }
};

// add selected script story board
export const addCurrentNewStoryBoardController = async (
  boardData,
  storyboardFun
) => {
  const {
    newScriptId,
    story_board_name,
    story_board_text,
    setStoryBoard2,
    storyBoardArr,
    setLoader,
  } = boardData;
  setLoader(true);

  try {
    return await axios
      .post(`${add_story_board}`, {
        user_id: localStorage.getItem("userId"),
        script_id: newScriptId,
        storyboardName: story_board_name,
        storyboardText: story_board_text,
        storyboardAttrText: story_board_text,
        draggableId: "",
      })
      .then(async (response) => {
        let arr = storyBoardArr && storyBoardArr;

        arr.push(response.data.data);

        let x = [];

        arr &&
          arr.forEach((i) => {
            x.push(i.id);
          });

        await axios.post(set_story_board_orders, {
          user_id: localStorage.getItem("userId"),
          script_id: newScriptId,
          order: x.toString(),
        });

        const msg = response.data.message;

        if (msg === "storyboard Successfully Added") {
          document.getElementById("desTitle").value = "";
          document.getElementById("boardDescription").value = "";
          storyboardFun(newScriptId);
          // setStoryBoard2((prevArray) => [...prevArray, data]);
        }
        setLoader(false);
      });
  } catch (error) {
    setLoader(false);

    let { response } = error;

    const { message, code } = response.data;

    if (code == 401) {
      toast.warn(message);
      setTimeout(() => {
        window.location.href = "/";
        localStorage.clear();
        //console.clear();
      }, 1000);
    } else {
      toast.error(message);
    }
  }
};

// delete story board

export const deleteStoryBoardController = async (
  data,
  setStb,
  setStoryBoard2,
  setLoader,
  stb
) => {
  setLoader(true);

  const res = await axios.post(`${delete_story_board}`, {
    user_id: localStorage.getItem("userId"),
    script_id: data.script_id,
    story_board_id: data.id,
  });

  const msg = res.data.message;
  if (msg === "Story Board Deleted Successfully") {
    // const myboard = stb && stb.filter((item) => item.id !== data.id);
    console.log(stb, "sd");
    // setStb(myboard);
    // setStoryBoard2(myboard);

    toast.success("Story Board has been deleted successfully", toastOptions);
  }
  setLoader(false);
};

export const checkController = (checked = true) => {
  const cbs = document.querySelectorAll('input[name="scriptInput"]');
  cbs.forEach((cb) => {
    cb.checked = checked;
  });
};

export const getSelectedCheckboxValues = () => {
  const checkboxes = document.querySelectorAll(
    `input[name="scriptInput"]:checked`
  );
  let values = [];
  checkboxes.forEach((checkbox) => {
    values.push(checkbox.value);
  });
  return values;
};

export const addScriptUnderFolderController = async (scrInnerScriptData) => {
  let { script_id, folder_id } = scrInnerScriptData;
  return axios
    .post(`${add_script_under_folder}`, {
      user_id: localStorage.getItem("userId"),
      script_id,
      folder_id,
    })
    .then(async (res) => {
      // console.log(res, "inside folder");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addRecentScriptController = async (
  storyData,
  setRecentScrList
) => {
  let { script_tab } = storyData;

  return await axios
    .post(`${add_recent_script}`, {
      user_id: localStorage.getItem("userId"),
      script_id: script_tab,
    })
    .then((res) => {
      setRecentScrList(res.data.data);
    })
    .catch((err) => console.log(err));
};

export const showUpdateRecentScriptsController = async (
  setRecentScrList,
  googleDriveSendData
) => {
  if (googleDriveSendData) {
    return await axios
      .post(`${show_recent_scripts}`, {
        user_id: localStorage.getItem("userId"),
      })
      .then((res) => {
        const data = res.data.data;
        // lastVisitScriptController(data, googleDriveSendData);
        setRecentScrList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    return await axios
      .post(`${show_recent_scripts}`, {
        user_id: localStorage.getItem("userId"),
      })
      .then((res) => {
        setRecentScrList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const showRecentScriptsController = async (
  setRecentScrList,
  googleDriveSendData,
  storyboardFun
) => {
  return await axios
    .post(`${show_recent_scripts}`, {
      user_id: localStorage.getItem("userId"),
    })
    .then(async (res) => {
      const data = res.data.data;
      await lastVisitScriptController(data, googleDriveSendData, storyboardFun);
      setRecentScrList(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteRecentScriptController = async (
  script_id,
  setToggleSelect
) => {
  hideSelectButtonController(setToggleSelect);
  return axios.post(`${delete_recent_scripts}`, {
    user_id: localStorage.getItem("userId"),
    script_id,
  });
};

export const hideSelectButtonController = async (setToggleSelect) => {
  const res = await axios.post(`${show_scripts}`, {
    user_id: localStorage.getItem("userId"),
  });

  const resMsg = res.data.message;

  if (resMsg === "No Added Script for this user") {
    setToggleSelect(false);
  }
};

export const lastVisitScriptController = async (
  data,
  googleDriveSendData,
  storyboardFun
) => {
  // console.log(data);
  const recentData = data.length && data[0];

  const {
    setEditAction,
    setScriptImage,
    setStb,
    setShowScriptAtrrText,
    setShowScriptTitle,

    setScrTab,
    setStoryBoardHeading,
    setScriptTitle,
    setScriptPara,
    setShowScriptId,
    setCurrentScriptId,
    setEmailAndPrintAction,
    setShowScriptUuid,
    setStoryBoard2,
  } = googleDriveSendData && googleDriveSendData;

  setEditAction(true);

  if (recentData === 0) {
    setStb([]);
    setStoryBoard2([]);
    document.getElementById("main_script").innerHTML = "";
    setEditAction(false);
    setScriptImage("");
    setEmailAndPrintAction(false);
    setStoryBoardHeading(false);
    setScriptTitle("");
    setScriptPara("");
    setShowScriptUuid("");
    setCurrentScriptId("");
    setShowScriptId("");
  } else {
    if (
      recentData.scrAttrText !== undefined ||
      recentData.scrAttrText !== null ||
      recentData.scrAttrText !== ""
    ) {
      const el = document.getElementById("main_script");

      if (el) {
        el.innerHTML = recentData.scrAttrText ? recentData.scrAttrText : null;
      }
    }
    // console.log(recentData, "recentData");
    await saveScriptLocalStorageController(recentData && recentData);
    setShowScriptAtrrText(recentData.scrAttrText);
    setShowScriptTitle(recentData.scrTitle);
    localStorage.setItem("uuid", recentData.uuid);
    localStorage.setItem("scriptId", recentData.id);
    localStorage.setItem("title", recentData.scrTitle);
    localStorage.setItem("attrTxt", recentData.scrAttrText);
    localStorage.setItem("scriptText", recentData.scrText);

    if (recentData.scrTitle !== undefined) {
      setScriptImage("assets/images/add.svg");

      setEmailAndPrintAction(true);
      setStoryBoardHeading(true);
    }
    setScrTab(recentData);
    setScriptTitle(recentData.scrTitle);
    setScriptPara(recentData.scrText);
    setShowScriptUuid(recentData.uuid);
    setCurrentScriptId(recentData.id);
    setShowScriptId(recentData.id);
    storyboardFun(recentData.id);
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/show_story_board`, {
          user_id: recentData.user_id,
          script_id: recentData.id,
        })
        .then((res) => {
          setStb(res.data.data);
        });
    } catch (error) {
      console.log(error);
    }
  }
};
