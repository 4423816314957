import React, { useState } from "react";
import axios from "axios";
import * as $ from "jquery";
import AlertModal from "../../middlaware/AlertModal";
import "./StoryBoard.css";
import { useDispatch, useSelector } from "react-redux";
import { editScriptAction } from "../../services/actions";

function StoryBoard({ story, deleteBoard, key }) {
  const [storyName, setStoryName] = useState("");
  // console.log(story);
  // const [storyText, setStoryText] = useState(story && story.storyboardText);
  const [storyBoardDescription, setStoryBoardDescription] = useState(
    story && story.storyboardAttrText
  );
  const { enableEdit } = useSelector((state) => state.enableEdit);
  const [editStoryData, setEditStoryData] = useState([]);

  const dispatch = useDispatch();

  const edit = (data) => {
    setEditStoryData(data);

    setStoryName(story.storyboardName);
    setStoryBoardDescription(story.storyboardAttrText);

    //convert copy text into plan text

    $("[contenteditable]").on("paste", function (e) {
      e.preventDefault();
      var text = "";
      if (e.clipboardData || e.originalEvent.clipboardData) {
        text = (e.originalEvent || e).clipboardData.getData("text/plain");
      } else if (window.clipboardData) {
        text = window.clipboardData.getData("Text");
      }
      if (document.queryCommandSupported("insertText")) {
        document.execCommand("insertText", false, text);
      } else {
        document.execCommand("paste", false, text);
      }
    });
  };

  //update story baord
  const update = async () => {
    story.storyboardName = storyName;
    // story.storyboardText = storyText;
    // const id = `story__content_board${editStoryData.id}`;
    // const attrData = document.getElementById(id).innerHTML;

    await axios.post(`${process.env.REACT_APP_API_URL}/edit_story_board`, {
      user_id: editStoryData.user_id,
      script_id: editStoryData.script_id,
      story_board_id: editStoryData.id,
      storyboardName: storyName,
      storyboardText: storyBoardDescription,
      storyboardAttrText: storyBoardDescription,
      draggableId: "",
    });
    // document.getElementById(id).innerHTML = "";
  };

  const copyClipboard = (data) => {
    var copyText = document.getElementById(data.id);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (enableEdit) {
        update();
      }
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [storyName, storyBoardDescription]);

  return (
    <div>
      <>
        <div className="story-borad mt-3">
          <div className="gray-story-board">
            <div className="d-flex">
              <h6
                className="float-left"
                style={{ width: "93%" }}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onClick={() => {
                  edit(story);
                  dispatch(editScriptAction(true));
                }}
                onBlur={update}
                onInput={(e) => setStoryName(e.currentTarget.textContent)}
              >
                {story && story.storyboardName}
              </h6>

              <div className="dropdown mr-3">
                <img
                  src="assets/images/dots.svg"
                  className="img-fluid"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  alt="option"
                />

                <div
                  className="dropdown-menu story-board-drop "
                  aria-labelledby="dropdownMenuButton"
                >
                  <AlertModal deleteScr={deleteBoard} />

                  <div
                    className="dropdown-item"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        story && story.storyboardText
                      );
                    }}
                  >
                    Copy
                  </div>

                  {/*  */}
                </div>
              </div>
            </div>
          </div>
          <textarea
            onClick={() => {
              edit(story && story);
              dispatch(editScriptAction(true));
            }}
            onBlur={update}
            className="story-board-description"
            placeholder="Type here..."
            defaultValue={story && story.storyboardText}
            onChange={(e) => setStoryBoardDescription(e.target.value)}
          ></textarea>
        </div>
      </>
    </div>
  );
}

export default StoryBoard;
