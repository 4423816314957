import React from "react";
function HeaderContent() {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-0">
        <div className="container-fluid">
          <button
            type="button"
            id="sidebarCollapse"
            className="navbar-btn custom-toggle"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </nav>
      {/* <div className="dropdown-custom">
        <span>
          <img
            src="assets/images/O-NOPHOTO.svg"
            className="pro-img"
            alt="pro"
          />
        </span>
        <div className="dropdown-content-custom">
          <Link to="/profile">Profile</Link>
          <hr />
          <Link to="/login" onClick={signOut}>
            logout
          </Link>
        </div>
      </div> */}
    </div>
  );
}

export default HeaderContent;
