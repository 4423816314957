import axios from "axios";
import { check_payment_status, get_control_values } from "../../utils/ApiList";
import { settingConstants } from "./constants";
import { loaderAction } from "./loader.action";

export const settingAction = () => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const { data } = await axios.get(get_control_values);
    dispatch(checkPaymentStatus(data));
    dispatch(loaderAction(false));
  } catch (error) {
    let res = error && error.response;
    if (!res) {
      alert(error.message);
      return false;
    }
    const { data } = error.response;

    alert(data.message);
    dispatch(loaderAction(false));
  }
};

export const checkPaymentStatus = (settingData) => async (dispatch) => {
  try {
    dispatch(loaderAction(true));
    const { data } = await axios.post(check_payment_status, {
      user_id: localStorage.getItem("userId"),
    });

    // Expired, Free Limited

    if (data.code == 200) {
      const planStatus =
        data.data.payment_status_code && data.data.payment_status_code === "1"
          ? false
          : true;

      dispatch({
        type: settingConstants.SETTING_DATA,
        payload: {
          data: settingData,
          userMode: planStatus,
        },
      });
    }

    // dispatch({ type: settingConstants.SETTING_DATA, payload: data });
    dispatch(loaderAction(false));
  } catch (error) {
    let res = error && error.response;
    if (!res) {
      alert(error.message);
      return false;
    }
    const { data } = res;

    alert(data.message);
    dispatch(loaderAction(false));
  }
};
