import { authConstants } from "../actions/constants";
const initialState = {
  userId: null,
  data: { user_id: "", email: "", Password: "", first_name: "", last_name: "" },
  authenticate: false,
  authenticating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = {
        ...state,
        authenticating: true,
      };
      break;
    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        userId: action.payload.userId,
        authenticate: true,
        authenticating: false,
      };
      break;

    case authConstants.LOGOUT_REQUEST:
      state = {
        ...initialState,
      };
      break;

    default:
  }

  return state;
};
