import React, { useState, useEffect } from "react";
import {
  editScriptAction,
  shortAllFolderScripts,
  showAllScripts,
  showAllStoryBoards,
  subscriptionStatus,
} from "../services/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";
import * as $ from "jquery";
import StoryBoard from "../components/StoryBoard/StoryBoard";
import SideBar from "../components/Sidebar/SideBar";
import LoadingOverlay from "react-loading-overlay";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

import NewToolBar from "../components/NewToolBar";
import Dictionary from "../components/Dictionary/LookUp";
import HeaderContent from "../components/HeaderContent";
import AllScriptContainer from "../components/AllScriptContainer";
import AddNewScript from "../components/AddNewScript";
import UserTab from "../components/UserTab";
import { showAllFolderScripts } from "../services/actions/showFolderScript.action";
import {
  addCurrentNewStoryBoardController,
  addNewStoryBoardController,
  addRecentScriptController,
  // checkController,
  deleteStoryBoardController,
  saveScriptLocalStorageController,
  showRecentScriptsController,
  showStoryBoardController,
  hideSelectButtonController,
  deleteRecentScriptController,
  showUpdateRecentScriptsController,
  // unAllCheckSelectedScriptController,
} from "../utils/DashboardController";
import ImportDropDownMenuComponent from "../components/DropDownMenu/ImportDropDownMenuComponent";
import DragNew from "../components/StoryBoard/DragFile/DragNew";
import { covertHtmlToPlainText } from "../utils/convertHtmlToPlainText";
import SubscriptionModal from "../components/SubscriptionComponent/SubscriptionModal/SubscriptionModal";
import useFetch from "../hooks/useFetch";
import { sortFolderScript, sortScript } from "../utils/sortScript";
import { show_scripts_with_filters } from "./../utils/ApiList";
import ReactPixel from "react-facebook-pixel";
import { updateScriptConstants } from "../services/actions/constants";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

let attributedText;

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state);
  const { settingData, userMode } = useSelector((state) => state.setting);
  const { enableEdit } = useSelector((state) => state.enableEdit);

  const { data } = useFetch();
  const [nameSort, setNameSort] = useState(false);

  let userIdChecking = localStorage.getItem("userId");

  if (!userIdChecking) {
    return <Redirect to="/login" />;
  }

  let stroyBoardArr = globalState && globalState.showSingleScriptStoryboard;
  let recentData = JSON.parse(localStorage.getItem("recentData"));

  // Subscription Status

  let subStatus =
    globalState.subscriptionStatus.subscriptionStatus &&
    globalState.subscriptionStatus.subscriptionStatus.payment_status_code;

  let ForeverFreeAction =
    subStatus && subStatus == "2"
      ? true
      : subStatus && subStatus === "0"
      ? true
      : false;

  useEffect(() => {
    dispatch(editScriptAction(false));
    //  const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_META_PIXEL_ID, options);

    ReactPixel.pageView(); // For tracking page view
  }, []);

  const [subScriptionModal, setSubScriptionModal] = useState(false);

  const [toggleContextMenu, setToggleContextMenu] = useState(false);
  const [toggleContextScriptMenu, setToggleContextScriptMenu] = useState(false);
  const [checked, setChecked] = useState(false);
  const [toggleSelect, setToggleSelect] = useState(true);
  const [searchScript, setSearchScript] = useState("");
  const [isActive, setIsActive] = useState(true);

  const [selectActive, setSelectActive] = useState(true);
  const [allScr, setAllScr] = useState([]);
  const [scriptSelection, setScriptSelection] = useState(false); //Add folder
  const [folder_name, setFolderName] = useState("");
  const [show_folders, setShowFolders] = useState([]);

  const user_id = localStorage.getItem("userId");
  // Show Folder Script
  const [folderId, setFolderId] = useState(null);
  const [folderScript, setFolderScript] = useState("All Scripts");

  const [folderAction, setFolderAction] = useState(true);
  //Add New Script
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const timestamps = new Date().getTime();
  // loader
  const [loader, setLoader] = useState(false);
  const [dictToggle, setDictToggle] = useState(false);

  const [editAction, setEditAction] = useState(false);

  const [scriptView, setScriptView] = useState([]);
  const [newScriptId, setNewScriptId] = useState("");
  const [goToPromtPageAction, setGoToPromtPageAction] = useState(true);
  const [colorAction, setColorAction] = React.useState(false);

  const [currentScriptId, setCurrentScriptId] = useState(null);

  let folderScr = globalState.showSingleFolderScripts;
  let emptyError;
  let folderAllScripts;

  folderAllScripts = folderScr.showSingleFolderScripts;

  emptyError = folderScr.msg;
  const [boardAction, setBoardAction] = useState(false);

  const [scrAlignment, setScrAlignment] = useState("left");
  useEffect(() => {
    // if script is empty then select button is hidden
    hideSelectButtonController(setToggleSelect);
  }, []);
  //Show Scripts
  const [scr_tab, setScrTab] = useState("");

  //Edit Script
  const [scriptTitle, setScriptTitle] = useState("");

  const [scriptPara, setScriptPara] = useState("");

  const [scrEditTextSize, setScrEditTextSize] = useState("Font size");
  const [folderMsgError, setFolderMsgError] = useState("");
  const [successFolderMsg, setSuccessFolderMsg] = useState(false);

  //set show script data
  const [show_script_id, setShowScriptId] = useState("");
  const [show_script_title, setShowScriptTitle] = useState("");

  const [show_scirpt_attr_text, setShowScriptAtrrText] = useState("");

  const [show_scirpt_uuid, setShowScriptUuid] = useState("");
  //Add Story Board
  const [story_board_name, setStoryBoardName] = useState("");
  const [story_board_text, setStoryBoardText] = useState("");

  const [story_board2, setStoryBoard2] = useState([]);

  // show story board
  const [stb, setStb] = useState([]);
  const [scriptImage, setScriptImage] = useState("");
  const [storyBoardHeading, setStoryBoardHeading] = useState(false);
  const [allFData, setAllFData] = useState([]);
  const [recentSelectedScript, setRecentSelectedScript] = useState([]);

  const [selectedFolder, setSelectedFolder] = useState([]);
  const [fNameError, setFNameError] = useState("");
  //add script under folder

  const [underFolderAction, setUnderFolderAction] = useState(null);
  const [checkAllScrAction, setCheckAllScrAction] = useState(true);

  const [recentScrList, setRecentScrList] = useState([]);

  const [emailAndPrintAction, setEmailAndPrintAction] = useState(false);
  const [scrColor, setScrColor] = useState("#000000");
  const [filterAction, setFilterAction] = useState(true);

  const showAllFolderController = async () => {
    setLoader(true);
    try {
      return await axios
        .post(`${process.env.REACT_APP_API_URL}/show_folders`, {
          user_id,
        })
        .then(function (response) {
          const arr = response.data.data;
          setShowFolders(arr);
          setLoader(false);
          //console.clear();
        })
        .catch(function (error) {
          setLoader(false);

          // console.log(error);
        });
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
      // console.log(error);
    }
  };
  const s = async () => {
    setLoader(true);
    await dispatch(showAllScripts());
    setLoader(false);
  };
  useEffect(async () => {
    setFolderScript("All Scripts");
    dispatch(showAllScripts());

    dispatch(subscriptionStatus());
  }, []);

  // let tempScript = localStorage.getItem("totalScript");
  let tempScript = localStorage.getItem("totalScript");

  let allScript = JSON.parse(tempScript);

  useEffect(() => {
    showAllFolderController();
    setScrEditTextSize("Font-size");
  }, []);
  //create  new folder
  const clearFolderData = () => {
    setFolderError(false);
    setSuccessFolderMsg(false);
    setFolderName("");
  };

  let storyBoardArr =
    globalState.showSingleScriptStoryboard &&
    globalState.showSingleScriptStoryboard.showSingleScriptStoryboard;

  let boardData = {
    currentScriptId,
    story_board_name,
    story_board_text,
    stb,
    story_board2,
    setStb,
    setStoryBoard2,
    setLoader,
    newScriptId,
    storyBoardArr,
  };

  // const [selectCheckAction, setSelectCheckAction] = useState(true);

  const changeSearchText = (e) => {
    setSearchScript(e);
    let a;
    if (filterAction) {
      a = allScript.filter((i) => {
        if (
          // i.txt.toLowerCase().includes(e.toLowerCase()) ||
          i.scrText.toLowerCase().includes(e.toLowerCase()) ||
          i.scrTitle.toLowerCase().includes(e.toLowerCase())
          // i.t.toLowerCase().includes(e.toLowerCase())
        ) {
          i.searchNotfound = false;
        } else {
          i.searchNotfound = true;
        }
        return i;
      });
      localStorage.setItem("totalScript", JSON.stringify(a));
    } else {
      if (folderAllScripts === null) {
      } else {
        a = folderAllScripts.filter((i) => {
          if (i.scrTitle.toLowerCase().includes(e.toLowerCase())) {
            i.searchNotfound = false;
          } else {
            i.searchNotfound = true;
          }
          return i;
        });

        folderAllScripts = a;
      }

      // localStorage.setItem("totalScript", JSON.stringify(folderScrFilters));
    }
  };

  //  props new script pass manually data in google drive
  let googleDriveSendData = {
    setEditAction,
    setIsActive,
    setLoader,
    setScriptImage,
    setShowScriptUuid,
    setScriptTitle,
    setScriptView,
    setShowScriptTitle,
    setScriptPara,
    setShowScriptAtrrText,
    setNewScriptId,
    setShowScriptId,
    setTitle,
    setScrAlignment,
    setStoryBoard2,
    setSelectActive,
    allScript,
    attributedText,
    setAllScript: setAllScr,
    setBoardAction,
    setStb,
    setStoryBoardHeading,
    setScrTab,
    setCurrentScriptId,
    setEmailAndPrintAction,
  };

  //Add New script
  const addScript = {
    user_id,
    scrTitle: title,
    scrEditTextSize: "14",
    scrPromptTextSize: "28",
    scrPromptSpeed: "1",
    textMargin: "0",
    marker: false,
    markerX: "140",
    uuid: `${user_id}-${timestamps}`,
    scrManualScrolling: true,
    scrCameraRecordMe: false,
    scrShowMeFullScreen: false,
    scrShowMeThumbnail: false,
    scrVoiceRecordMe: false,
    scrColor: "",
    scrAlignment: "left",
  };

  // Add New Script
  const [folderError, setFolderError] = useState(false);
  const onFocus = () => {
    setFolderError(false);
    setSuccessFolderMsg(false);
  };

  //show script

  const scrptContainer = document.getElementById("scrptContainer");
  const showAllScript = async () => {
    setLoader(true);
    setFilterAction(true);
    setFolderId(null);
    setSearchScript("");
    setEmailAndPrintAction(false);
    setShowScriptAtrrText("");
    setScriptImage("");
    setStoryBoardHeading(false);

    // setShowScriptTitle("");
    setStoryBoard2([]);
    setStb([]);

    setScriptTitle("");
    setEditAction(false);
    document.getElementById("main_script").innerHTML = "";
    try {
      await s();
      setFolderScript("All Scripts");
      setUnderFolderAction(null);
      setIsActive(true);
      setSelectActive(true);
      setLoader(false);
      setFolderAction(true);
    } catch (error) {}
  };

  //delete multiple scripts
  const deleteScript = async () => {
    setLoader(true);
    // await deleteFolder();
    if (recentSelectedScript.length !== 0) {
      try {
        for (let id of recentSelectedScript) {
          await axios
            .post(`${process.env.REACT_APP_API_URL}/delete_script`, {
              user_id,
              script_id: id,
            })
            .then(async (res) => {
              setShowScriptTitle("");
              setShowScriptAtrrText("");

              document.getElementById("main_script").innerHTML = "";

              setStoryBoard2([]);
              setStb([]);
              setScriptImage("");
              setStoryBoardHeading(false);
              setEditAction(false);
              setScriptTitle("");
              setScriptPara("");
              setShowScriptId("");
              hideSelectButtonController(setToggleSelect);
              let script_id = id;
              deleteRecentScriptController(script_id, setToggleSelect);
              const result = await showRecentScriptsController(
                setRecentScrList,
                googleDriveSendData,
                storyboardFun
              );
              console.log(result, "result: ");
              dispatch(showAllScripts());
            });
          // setLoader(false);
        }
        setToggleSelect(true);
        setSelectActive(true);
        setScriptSelection(false);
        // setLoader(false);
      } catch (error) {
        // console.log(error);
        toast.error(error.message);
        setLoader(false);
      }
    } else {
      setLoader(false);
      setToggleSelect(true);
      setSelectActive(true);
      setScriptSelection(false);
      setChecked(false);
    }
    setToggleSelect(true);
    setToggleSelect(true);
    setSelectActive(true);
    setScriptSelection(false);
    setLoader(false);
  };

  // select single script data
  const scriptHandleSelect = async (data) => {
    dispatch(editScriptAction(false));

    setStb([]);
    setStoryBoard2([]);
    setLoader(true);
    // console.log(data, "Data of single script");

    setBoardAction(false);
    // save script in localStorage
    await saveScriptLocalStorageController(data);
    let storyData = {
      script_tab: data.id || data.scriptId,
      setStb,
      setStoryBoard2,
      setLoader,
      setRecentScrList,
      recentScrList,
    };

    let testing = data.attr_text || data.scrAttrText;

    document.getElementById("main_script").innerHTML = testing
      ? testing && testing
      : "Type here...";

    let script_id = data.id || data.scriptId;
    dispatch(showAllStoryBoards(script_id));
    await showStoryBoardController(storyData); // show story boards

    setEmailAndPrintAction(true);

    setScriptImage("assets/images/add.svg");
    setStoryBoardHeading(true);

    setScriptTitle(data.t || data.scrTitle);
    setScrEditTextSize(data.scr_text_size || data.scrEditTextSize);
    setShowScriptId(data.id || data.scriptId);
    setCurrentScriptId(data.id || data.scriptId);
    setShowScriptTitle(data.t || data.scrTitle);

    setShowScriptUuid(data.scr_uuid || data.uuid);
    setScriptPara(data.txt || data.scrText);
    setEditAction(true);
    setScrAlignment(data.scrAlignment || data.scr_alignment);
    setScrColor(data.scr_color || data.scrColor);

    localStorage.setItem("uuid", data.scr_uuid || data.uuid);
    localStorage.setItem("scriptId", data.id || data.scriptId);
    localStorage.setItem("title", data.t || data.scrTitle);
    localStorage.setItem("attrTxt", data.attr_text || data.scrAttrText);
    localStorage.setItem("scriptText", data.txt);

    await addRecentScriptController(storyData, setRecentScrList); //add recent scripts
    setLoader(false);
    setStb([]);
    setStoryBoard2([]);
    // let script_id = data.id || data.scriptId;
    // dispatch(showAllStoryBoards(script_id));
    // showStoryBoardController(storyData); // show story boards
    dispatch({
      type: updateScriptConstants.SELECTED_SCRIPT,
      payload: { script: data },
    });
    dispatch(showAllStoryBoards(script_id));
  };

  const storyboardFun = (script_id) => {
    dispatch(showAllStoryBoards(script_id));
  };

  useEffect(() => {
    showRecentScriptsController(
      setRecentScrList,
      googleDriveSendData,
      storyboardFun
    );
  }, []);

  const selectionScript = () => {
    setScriptSelection(true);
    setToggleSelect(false);
    setCheckAllScrAction(true);
  };
  const cancle = () => {
    setRecentSelectedScript([]);
    setSelectedFolder([]);
    setToggleSelect(true);
    setSelectActive(true);
    setScriptSelection(false);
  };

  // select Scripts
  const mscript = (id) => {
    const sid = id;

    if (recentSelectedScript.includes(sid)) {
      var i = recentSelectedScript.indexOf(sid);
      recentSelectedScript.splice(i, 1);
    } else {
      const sdata = [...recentSelectedScript, sid];
      setRecentSelectedScript(sdata);
    }
  };
  const mfolder = (folder) => {
    // console.log(folder);
    const data = [...allFData, folder];
    setAllFData(data);
    const fid = folder;
    if (selectedFolder.includes(fid)) {
      var i = selectedFolder.indexOf(fid);
      selectedFolder.splice(i, 1);
    } else {
      const fdata = [...selectedFolder, fid];
      setSelectedFolder(fdata);
    }
  };

  // move scripts under folders
  const moveScript = async () => {
    setLoader(true);

    setChecked(false);
    // if (checkAllScr === null) {

    if (selectedFolder.length === 0) {
      toast.warn("Please select the folders to move the scripts", {
        hideProgressBar: true,
      });

      setLoader(false);
    } else {
      for (let folder of selectedFolder) {
        let folder_id = folder.id;
        let scriptIdToBeAdded = recentSelectedScript;
        if (scriptIdToBeAdded.length !== 0) {
          for (let script of scriptIdToBeAdded) {
            let script_id = script;

            //geting single folder data
            try {
              await axios
                .post(
                  `${process.env.REACT_APP_API_URL}/show_single_folder_data`,
                  {
                    user_id: user_id,
                    folder_id,
                  }
                )
                .then((res) => {
                  let data = res.data.data;

                  let arr = [];
                  if (data.length === 0) {
                  } else {
                    data.map((item) => arr.push(item.id));
                  }

                  // const scrSingle =
                  //   data.length === 0
                  //     ? null
                  //     : data.map((item) => arr.push(item.id));

                  var isScriptExist = false;
                  let scripts = arr;
                  for (let script of scripts) {
                    // console.log(script)
                    if (script === script_id) {
                      isScriptExist = true;
                      setToggleSelect(true);
                      setSelectActive(true);
                      setScriptSelection(false);
                    }
                  }
                  if (isScriptExist === true) {
                    setToggleSelect(true);
                    setSelectActive(true);
                    setScriptSelection(false);
                  } else {
                    try {
                      axios
                        .post(
                          `${process.env.REACT_APP_API_URL}/add_script_under_folder`,
                          {
                            user_id,
                            script_id,
                            folder_id,
                          }
                        )
                        .then((res) => {
                          // console.log(res);

                          isScriptExist = false;
                          setToggleSelect(true);
                          setSelectActive(true);
                          setScriptSelection(false);
                          setLoader(false);
                        });
                    } catch (error) {
                      setLoader(false);
                    }
                  }
                  setSelectedFolder([]);
                  setRecentSelectedScript([]);
                });
            } catch (error) {
              setLoader(false);
            }
          }
        } else {
          toast.warn("Please select the scripts to move the folders", {
            hideProgressBar: true,
          });
        }
      }
      setLoader(false);
    }
  }; // Show all Scripts under Single Folder
  const [singleFolderId, setSingleFolderId] = useState(null);
  const showScriptFolder = async (id) => {
    setFilterAction(false);
    setShowScriptAtrrText("");
    setScriptImage("");
    setStoryBoardHeading(false);
    setShowScriptTitle("");
    setSingleFolderId(id);
    setFolderAction(false);
    setStoryBoard2([]);
    setScriptTitle("");
    setUnderFolderAction(id);
    // setFolderSyncAction(true);
    setFolderId(id);
    setLoader(true);
    setIsActive(false);
    setSelectActive(false);
    setSearchScript("");
    setEmailAndPrintAction(false);
    document.getElementById("main_script").innerHTML = "";
    localStorage.setItem("folderId", id);
    dispatch({
      type: updateScriptConstants.SELECTED_SCRIPT,
      payload: { script: null },
    });

    const folderName =
      show_folders.length === 0
        ? null
        : show_folders.map((folder) => (folder.id === id ? folder.name : null));
    setFolderScript(folderName);
    try {
      await dispatch(showAllFolderScripts(id));
      setLoader(folderScr.loading);
    } catch (error) {
      setLoader(false);
    }
  };
  // window.addEventListener("focus");

  useEffect(() => {
    dispatch(showAllFolderScripts(singleFolderId));
    s();
    // showAllScript();
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      $("li").click(function () {
        $("li.list-group-item.active").removeClass("active");
        $(this).addClass("active");
      });
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $(".folder-list").click(function () {
        $(".folder-list.active").removeClass("active");
        $(this).addClass("active");
        $(".get-all-scripts.active").removeClass("active");
      });
      $(".get-all-scripts").click(function () {
        $(".get-all-scripts.active").removeClass("active");
        $(".folder-list.active").removeClass("active");
        $(this).addClass("active");
      });
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $(".show-all-scripts").click(function () {
        $(".folder-list.active").removeClass("active");
      });
    });
  });

  // +++++++++ Edit Board++++++++
  const updateScript = async () => {
    const attrData = document.getElementById("main_script").innerHTML;
    const scrAttrText = attrData.split("true").join("false");
    localStorage.setItem("title", scriptTitle);
    localStorage.setItem("attrTxt", scrAttrText);
    const scrText = covertHtmlToPlainText(scrAttrText);

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/edit_script`, {
          user_id: user_id,
          script_id: show_script_id,
          scrTitle: scriptTitle,
          scrText: scrText,
          scrAttrText: scrAttrText,
          scrEditTextSize: scrEditTextSize,
          scrPromptTextSize: 28,
          scrPromptSpeed: 1,
          textMargin: 0,
          marker: false,
          markerX: 140,
          uuid: show_scirpt_uuid ? show_scirpt_uuid : "",
          scrManualScrolling: false,
          scrCameraRecordMe: false,
          scrShowMeFullScreen: false,
          scrShowMeThumbnail: false,
          scrVoiceRecordMe: false,
          scrColor,
          scrAlignment: scrAlignment,
          scrMirror: null,
          scrInvert: null,
          scrFontFamily: "arial",
        })

        .then(function (res) {
          const msg = res.data.message;
          if (msg === "Script Successfully Updated") {
            const data = res.data.data;

            localStorage.setItem("uuid", data.scr_uuid || data.uuid);
            localStorage.setItem("scriptId", data.id || data.scriptId);
            localStorage.setItem("title", data.t || data.scrTitle);
            localStorage.setItem("attrTxt", data.attr_text || data.scrAttrText);
            localStorage.setItem("scriptText", data.txt);
            saveScriptLocalStorageController(data);
            dispatch(showAllScripts());

            setShowScriptTitle(data.scrTitle && data.scrTitle);

            localStorage.setItem("attrTxt", res.data.data.scrAttrText);
            let attrSampleText = res.data.data.scrAttrText
              .split('<p id="scrptContainer" contenteditable="true" ></p>')
              .join("");
            attributedText = attrSampleText;
            if (folderId !== null) {
              dispatch(showAllFolderScripts(folderId));
            }
            showUpdateRecentScriptsController(
              setRecentScrList,
              googleDriveSendData
            );

            setLoader(false);
          } else {
            // Error in Update Script

            setLoader(false);
          }
        });
    } catch (error) {
      setGoToPromtPageAction(true);

      setLoader(false);

      let { response } = error;

      const { message, code } = response.data;

      if (code == 401) {
        toast.warn(message);
        setTimeout(() => {
          window.location.href = "/";
          localStorage.clear();
          //console.clear();
        }, 1000);
      } else {
        toast.error(message);
      }
    }
  };

  // Update script every one seconds
  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (enableEdit) {
        updateScript();
      }
      // Send Axios request here
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [scriptTitle, scriptPara]);

  // const [scrollActive, setScrollActive] = useState(false);
  const scrollTab = () => {
    // setScrollActive(true);
  };

  const [toggleSearch, setToggleSearch] = useState(true);
  const toggleSearchShow = () => {
    setToggleSearch(false);
    const search = document.getElementById("sb-search");
    search.style.width = "100%";
    search.style.borderBottom = "1px solid #d4d4d4";
  };
  const toggleSearchHide = () => {
    setToggleSearch(true);

    const search = document.getElementById("sb-search");
    search.style.width = "0%";
    search.style.borderBottom = "none";
  };

  const hideScriptHeading = () => {
    const heading = document.getElementById("script-heading-toggle");
    heading.style.display = "none";
    if (show_script_id) {
      setEditAction(true);
    } else {
      setEditAction(false);
    }
  };
  const showScriptHeading = () => {
    const heading = document.getElementById("script-heading-toggle");
    heading.style.display = "inline";

    setEditAction(false);
  };

  useEffect(() => {
    hideScriptHeading();
    setEditAction(false);
  }, []);

  const checkController = (checked = true) => {
    const cbs = document.querySelectorAll('input[name="scriptInput"]');
    cbs.forEach((cb) => {
      cb.checked = checked;
    });
    getSelectedCheckboxValues();
  };

  const getSelectedCheckboxValues = () => {
    const checkboxes = document.querySelectorAll(
      `input[name="scriptInput"]:checked`
    );
    let values = [];
    checkboxes.forEach((checkbox) => {
      values.push(checkbox.value);
    });

    setRecentSelectedScript(values);
    setCheckAllScrAction(false);
    return values;
  };

  const handleKeyDown = (event) => {
    event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "s") {
      alert("CTRL+S Pressed");
    }
  };

  // reload

  const reloadScripts = async () => {
    setLoader(true);
    dispatch(showAllScripts());
    await showAllFolderController();
    showRecentScriptsController(
      setRecentScrList,
      googleDriveSendData,
      storyboardFun
    );

    setLoader(false);
  };

  useEffect(() => {
    setInterval(checkWindowFocused, 1000);
    // ✅ Check if window focused EVERY seconds
  }, []);

  function checkWindowFocused() {
    if (document.hasFocus()) {
      const profile = localStorage.getItem("profilePage");

      if (profile === "true") {
        localStorage.setItem("profilePage", false);
        dispatch(subscriptionStatus());
      }
    }
  }

  const handlePremium = () => {
    setStoryBoardName("");
    setStoryBoardText("");
    if (userMode && settingData.script_count < allScript && allScript.length) {
      toast.warn(
        "You reached the limit of your " +
          settingData.script_count +
          " Scripts free plan! Please click on your Account to upgrade to Unlimited."
      );
      window.open(
        "/subscription",
        "_blank",
        `location=yes,height=600,width=800,scrollbars=yes,status=yes, top=100, left=250,  margin=0 auto,  frame=false,nodeIntegration=no`
      );
      return false;
    }
  };

  const handleSort = async () => {
    setLoader(true);
    setNameSort(!nameSort);
    if (folderAction) {
      await sortScript(nameSort);
    } else {
      await sortFolderScript(
        nameSort,
        folderScr.showSingleFolderScripts,
        folderId
      );
    }
    setLoader(false);
  };

  const sortFolderScript = async (nameSort, folderAllScripts, folderId) => {
    if (nameSort) {
      if (folderAllScripts) {
        const { data } = await axios.post(show_scripts_with_filters, {
          user_id: localStorage.getItem("userId"),
          order: "DESC",
          folder_id: folderId,
        });
        dispatch(shortAllFolderScripts(data));
      }
    } else {
      if (folderAllScripts) {
        const { data } = await axios.post(show_scripts_with_filters, {
          user_id: localStorage.getItem("userId"),
          order: "ASC",
          folder_id: folderId,
        });
        dispatch(shortAllFolderScripts(data));
      }
    }
  };

  // **************************************************************************

  // ********************* Content Start ********************************

  return (
    <div className={classes.root}>
      <LoadingOverlay active={loader} spinner>
        <div
          className="test"
          onClick={() => {
            setToggleContextScriptMenu(false);
          }}
        >
          <div className="wrapper">
            <nav id="sidebar">
              <SideBar
                setIsActive={setIsActive}
                setScrAlignment={setScrAlignment}
                checkAllScrAction={checkAllScrAction}
                setCheckAllScrAction={setCheckAllScrAction}
                setEditAction={setEditAction}
                setScrTab={setScrTab}
                setScriptImage={setScriptImage}
                setStoryBoardHeading={setStoryBoardHeading}
                setStb={setStb}
                setLoader={setLoader}
                toggleContextMenu={toggleContextMenu}
                setToggleContextMenu={setToggleContextMenu}
                showAllScript={showAllScript}
                show_folders={show_folders}
                showScriptFolder={showScriptFolder}
                mfolder={mfolder}
                clearFolderData={clearFolderData}
                scriptSelection={scriptSelection}
                isActive={isActive}
                toast={toast}
                setShowFolders={setShowFolders}
                recentData={recentData}
                setFolderName={setFolderName}
                setFolderScript={setFolderScript}
                fNameError={fNameError}
                onFocus={onFocus}
                folder_name={folder_name}
                setFNameError={setFNameError}
                folderMsgError={folderMsgError}
                user_id={user_id}
                setSuccessFolderMsg={setSuccessFolderMsg}
                setFolderMsgError={setFolderMsgError}
                setFolderError={setFolderError}
                folderError={folderError}
                successFolderMsg={successFolderMsg}
                Alert={Alert}
                setShowScriptAtrrText={setShowScriptAtrrText}
                setShowScriptTitle={setShowScriptTitle}
                setScriptTitle={setScriptTitle}
                setScriptPara={setScriptPara}
                setShowScriptId={setShowScriptId}
                setStoryBoard2={setStoryBoard2}
                recentScrList={recentScrList}
                showAllFolderController={showAllFolderController}
                setCurrentScriptId={setCurrentScriptId}
                setEmailAndPrintAction={setEmailAndPrintAction}
                setBoardAction={setBoardAction}
                currentFolderId={folderId}
              />
            </nav>
            <div id="content">
              {/* Header content */}
              <HeaderContent />

              <div className="container-fluid">
                <NewToolBar
                  colorAction={colorAction}
                  setColorAction={setColorAction}
                  googleDriveSendData={googleDriveSendData}
                  showAllScript={s}
                  setScrColor={setScrColor}
                  updateScript={updateScript}
                  setToggleSelect={setToggleSelect}
                  setLoader={setLoader}
                  setEmailAndPrintAction={setEmailAndPrintAction}
                  scrColor={scrColor}
                  setScrAlignment={setScrAlignment}
                  emailAndPrintAction={emailAndPrintAction}
                  setScrEditTextSize={setScrEditTextSize}
                  scrEditTextSize={scrEditTextSize}
                  searchScript={searchScript}
                  changeSearchText={changeSearchText}
                  setBoardAction={setBoardAction}
                  dictToggle={dictToggle}
                  setDictToggle={setDictToggle}
                />

                <div className="row">
                  <div className="col-lg-5 p-0 ">
                    <div className="container gray-section">
                      {/* left header content */}

                      <div className="row ">
                        <div className="col-lg-10 col-10">
                          <div className="d-flex">
                            <div className="heading-title d-flex pt-4">
                              <h5 className="main-heading-title">
                                {folderScript}
                              </h5>
                              <div
                                className="reload"
                                onClick={() => {
                                  reloadScripts();
                                }}
                              >
                                <img
                                  src="assets/images/Group.svg"
                                  alt=""
                                  srcSet=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-2 "
                          style={{
                            top: "20px",
                            position: "relative",
                            right: "40px",
                          }}
                        >
                          {toggleSelect
                            ? selectActive && (
                                <p
                                  id="show"
                                  className="select-script"
                                  value="hide"
                                  onClick={() => selectionScript()}
                                >
                                  Select
                                </p>
                              )
                            : null}
                          {scriptSelection && (
                            <div className="d-flex">
                              <p
                                id="hide"
                                className="select-script"
                                onClick={() => cancle()}
                              >
                                Cancel
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/*Select Scripts */}

                      <div>
                        {scriptSelection && (
                          <div className="d-flex">
                            <p
                              id="hide"
                              className="select-script move"
                              onClick={() => moveScript()}
                            >
                              Move
                            </p>

                            {checkAllScrAction ? (
                              <p
                                id="hide-check"
                                className="select-script move"
                                onClick={() => checkController()}
                              >
                                Select All
                                {/* select all */}
                              </p>
                            ) : null}

                            <span
                              onClick={deleteScript}
                              className="ml-3 select-script"
                            >
                              <i className="fa fa-trash"></i>
                            </span>
                          </div>
                        )}
                        {/* script title bar */}
                        <div className="row title_boarder">
                          <div className="col-lg-7 col-6 title">
                            Title{" "}
                            {nameSort ? (
                              <ArrowUpwardIcon
                                className="arrow_icon"
                                onClick={() => handleSort()}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                className="arrow_icon"
                                onClick={() => handleSort()}
                              />
                            )}
                          </div>
                          <div className="col-lg-3 col-3 text-center size">
                            Size
                          </div>
                          <div className="col-lg-2 col-3 text-center date">
                            Date
                          </div>
                        </div>

                        <div
                          className="show-all-script"
                          // onClick={() => s()}
                        >
                          <nav>
                            <AllScriptContainer
                              googleDriveSendData={googleDriveSendData}
                              storyboardFun={storyboardFun}
                              currentScriptId={currentScriptId}
                              folderId={folderId}
                              setLoader={setLoader}
                              allScr={allScr}
                              toggleContextScriptMenu={toggleContextScriptMenu}
                              setToggleContextScriptMenu={
                                setToggleContextScriptMenu
                              }
                              searchScript={searchScript}
                              emptyError={emptyError}
                              selectActive={selectActive}
                              // allScript={sortArr}
                              allScript={allScript}
                              scriptHandleSelect={scriptHandleSelect}
                              scriptView={scriptView}
                              scriptSelection={scriptSelection}
                              mscript={mscript}
                              checked={checked}
                              showAllScript={s}
                              recentData={recentData}
                              folderAction={folderAction}
                              folderAllScripts={folderAllScripts}
                              setChecked={setChecked}
                              setScriptSelection={setScriptSelection}
                              setRecentScrList={setRecentScrList}
                              show_script_id={show_script_id}
                              setShowScriptTitle={setShowScriptTitle}
                              setShowScriptAtrrText={setShowScriptAtrrText}
                              setStb={setStb}
                              setStoryBoard2={setStoryBoard2}
                              setScriptImage={setScriptImage}
                              setEditAction={setEditAction}
                              setScriptTitle={setScriptTitle}
                              setScriptPara={setScriptPara}
                              setShowScriptId={setShowScriptId}
                              setToggleSelect={setToggleSelect}
                              setStoryBoardHeading={setStoryBoardHeading}
                            />
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-7 main_board_content">
                    <main>
                      {/* script container */}
                      <div className="row userTab">
                        <div className="col-12">
                          {dictToggle && (
                            <Dictionary setDictToggle={setDictToggle} />
                          )}
                        </div>

                        <div className="col-lg-12 mt-2">
                          <ul
                            className="nav nav-tabs custom-tab-bar border-0"
                            role="tablist"
                          >
                            <li
                              className="nav-item custom-nav add-sript-mod"
                              onClick={() => {
                                hideScriptHeading();
                                dispatch(editScriptAction(false));
                              }}
                            >
                              <a
                                className="nav-link ad-script active border-0"
                                data-toggle="tab"
                                href="#Script"
                              >
                                Script
                              </a>
                            </li>
                            <li
                              className="nav-item custom-nav add-sript-mod"
                              onClick={() => {
                                showScriptHeading();
                                dispatch(editScriptAction(false));
                              }}
                            >
                              <a
                                className="nav-link ad-script border-0"
                                data-toggle="tab"
                                href="#Story-Board"
                              >
                                Story Board
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* script heading */}
                      <div className="row mt-3">
                        <div className="col-lg-8 col-8">
                          <div className="heading-sciept">
                            <h5>
                              {editAction ? (
                                <input
                                  onClick={() =>
                                    dispatch(editScriptAction(true))
                                  }
                                  className="script_title"
                                  onBlur={updateScript}
                                  type="text"
                                  maxLength="50"
                                  value={scriptTitle}
                                  onChange={(e) => {
                                    setScriptTitle(e.target.value);
                                    if (e.target.value.length >= 50) {
                                      toast.warning(
                                        "Max length of char should be 40"
                                      );
                                    } else if (e.target.value.length <= 0) {
                                      toast.warning("Min length of one char");
                                      setScriptTitle("type here...");
                                    }
                                  }}
                                />
                              ) : (
                                scriptTitle
                              )}
                            </h5>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-4"
                          id="script-heading-toggle"
                        >
                          <div
                            data-toggle="modal"
                            data-target={
                              userMode &&
                              allScript &&
                              allScript.length >=
                                parseInt(settingData.script_count)
                                ? null
                                : "#exampleModalCenter3"
                            }
                            // onClick={() => {
                            //   handlePremium();
                            // }}
                            className="d-flex"
                          >
                            <span className="board_heading">
                              {storyBoardHeading ? "Add Board" : null}
                            </span>
                            <div className="board_img">
                              <img
                                src={(scr_tab && scr_tab.img) || scriptImage}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <article>
                        <div className="row userTab"></div>
                        <div
                          className="row userTab"
                          style={{ marginBottom: "50px" }}
                        >
                          <div className="col-lg-12">
                            <div className="div-section">
                              <div className="tab-content">
                                {/* script description start */}

                                <div
                                  id="Script"
                                  className="container tab-pane active"
                                >
                                  <div className="row">
                                    <div
                                      className="col-lg-12 p-0 script__content"
                                      style={{
                                        textAlign: scrAlignment,
                                      }}
                                    >
                                      <>
                                        <div
                                          id="scriptContentSize"
                                          tabIndex="1"
                                        >
                                          <div
                                            className="main__scripts"
                                            id="main_script"
                                            contentEditable={editAction}
                                            suppressContentEditableWarning={
                                              true
                                            }
                                            onClick={() =>
                                              dispatch(editScriptAction(true))
                                            }
                                            onBlur={updateScript}
                                            onInput={(e) => {
                                              {
                                                setScriptPara(
                                                  e.currentTarget.textContent
                                                );
                                              }
                                            }}
                                          ></div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                                {/* script description end */}

                                <div
                                  id="Story-Board"
                                  className="container tab-pane fade"
                                >
                                  <br />
                                  {/* ++++++++++ edit story board ======= */}

                                  <DragNew
                                    scrId={show_script_id && show_script_id}
                                    stb={isActive ? stb : story_board2}
                                    setStb={setStb}
                                    setStoryBoard2={setStoryBoard2}
                                    setLoader={setLoader}
                                    stroyBoardArr={stroyBoardArr}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* // Add New Scripts */}
          <AddNewScript
            setScrAlignment={setScrAlignment}
            setShowScriptUuid={setShowScriptUuid}
            setEditAction={setEditAction}
            attributedText={attributedText}
            setNewScriptId={setNewScriptId}
            setIsActive={setIsActive}
            addScript={addScript}
            title={title}
            description={description}
            allScript={allScr}
            setLoader={setLoader}
            setScriptImage={setScriptImage}
            setStoryBoardHeading={setStoryBoardHeading}
            setScriptView={setScriptView}
            setShowScriptTitle={setShowScriptTitle}
            setShowScriptAtrrText={setShowScriptAtrrText}
            setAllScript={setAllScr}
            setShowScriptId={setShowScriptId}
            setStoryBoard2={setStoryBoard2}
            showAllScript={s}
            setTitle={setTitle}
            setScriptTitle={setScriptTitle}
            setDescription={setDescription}
            setScriptPara={setScriptPara}
            underFolderAction={underFolderAction}
            setEmailAndPrintAction={setEmailAndPrintAction}
            setScrEditTextSize={setScrEditTextSize}
            setScrColor={setScrColor}
            setToggleSelect={setToggleSelect}
          />
          {/* Add Folder */}

          {/* // Add Story Borad */}
          <div
            className="modal fade "
            id="exampleModalCenter3"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered "
              role="document"
              style={{ maxWidth: "700px" }}
            >
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Add Story Board
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label htmlFor="desTitle">Title</label>

                        <input
                          type="text"
                          id="desTitle"
                          name="desTitle"
                          className="form-control modal-input"
                          placeholder="Enter story board name"
                          onChange={(e) => setStoryBoardName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 ">
                      <div className="form-group">
                        <label htmlFor="boardDescription">Description</label>
                        <textarea
                          className="form-control text-area-modal"
                          id="boardDescription"
                          rows="3"
                          onChange={(e) => setStoryBoardText(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer border-0">
                    {boardAction ? (
                      <button
                        type="button"
                        onClick={() =>
                          addCurrentNewStoryBoardController(
                            boardData,
                            storyboardFun
                          )
                        }
                        data-dismiss="modal"
                        className="btn btn-primary sub-btn"
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          addNewStoryBoardController(boardData, storyboardFun)
                        }
                        data-dismiss="modal"
                        className="btn btn-primary sub-btn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit story board */}
        </div>
        {/* </Layout> */}
        <div className="footer">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <UserTab
                scrollTab={scrollTab}
                scrptContainer={scrptContainer}
                updateScript={updateScript}
                setLoader={setLoader}
                setScrEditTextSize={setScrEditTextSize}
                scrEditTextSize={scrEditTextSize}
                setScrAlignment={setScrAlignment}
                emailAndPrintAction={emailAndPrintAction}
                show_scirpt_attr_text={show_scirpt_attr_text}
                setToggleSelect={setToggleSelect}
                goToPromtPageAction={goToPromtPageAction}
                recentScrList={recentScrList}
              />
            </div>
          </div>
        </div>

        <div className="d-none">
          <p id="sub-amount-total" className="d-none"></p>
          <SubscriptionModal foreverFreeAction={subScriptionModal} />
        </div>

        <ToastContainer className="alert-msg" autoClose={3000} />
      </LoadingOverlay>
    </div>
  );
}

export default Dashboard;
export { attributedText };
