import {
  subscriptionConstants,
  subscriptionModalConstants,
} from "../actions/constants";
const initialState = {
  subscriptionStatus: null,
  loading: false,
  subscriptionModalAction: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case subscriptionConstants.SUBSCRIPTION_REQUEST:
      state = {
        ...state,
      };
      break;
    case subscriptionConstants.SUBSCRIPTION_STATUS:
      state = {
        ...state,
        subscriptionStatus: action.payload.subscriptionStatus,

        loading: action.payload.loading,
      };
      break;
    case subscriptionConstants.SUBSCRIPTION_FAIL:
      state = {
        ...state,
        subscriptionStatus: action.payload.subscriptionStatus,

        loading: action.payload.loading,
      };
      break;

    case subscriptionModalConstants.SUBSCRIPTION_MODAL_SUCCESS:
      state = {
        ...state,
        subscriptionModalAction: action.payload,
      };
      break;

    default:
      state = {
        ...state,

        loading: false,
      };
  }

  return state;
};
